import React, { useEffect, useState } from "react";
import "../../Style/LandingPage/Explore.css";
import { Link } from "react-router-dom";
import axios from "axios";
import PromotionBanner from "./PromotionBanner";

const Explore = () => {
  const [top5HomeCategory, setTop5HomeCategory] = useState(null);
  const [allCategory, setAllCategory] = useState([]);
  // get All Selected Home Page Category
  const getSelectedHomeCategory = () => {
    axios.get(`api/homecategory/get-home-category`).then((res) => {
      setTop5HomeCategory(res.data.data[0]?.curatedCategory);
    });
  };
  // get all category
  const getAllCategory = async () => {
    await axios
      .get(`api/category/get-all-category`)
      .then((res) => {
        if (res.data.data) {
          setAllCategory(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSelectedHomeCategory();
    getAllCategory();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="explore-container Mon_font">
      <h2 className="text-center">
        Embark on an Adventure to Discover the Coolest Destinations
      </h2>
      <div className="hr-line"></div>
      <div className="explore__container Mon_font">
        {/* <PromotionBanner /> */}
        {top5HomeCategory?.map((category) => {
          const data = allCategory?.find((obj) => obj?.name === category);
          return (
            <div key={category} className="explore__feature">
              <Link to={`/category/${category}`}>
                <img src={data?.url} alt="Loading" />
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Explore;
