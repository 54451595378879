import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import TalkToExpert from "../components/LandingPage/TalkToExpert";
import Testmonials from "../components/LandingPage/Testmonials";
import OtherLayout from "../components/Layout/OtherLayout";
import axios from "axios";
import noresult from "../images/noresult.png";
import "../Style/CategoryListPage/Category.css";
import blogBG from "../images/blogs/blog.jpg";

const CategoryBlogPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState(null);
  const [singleBlog, setSingleBlog] = useState(null);

  async function getAllBlogs() {
    try {
      const res = await axios.get("/api/blog/get-all-blog");
      if (res.data.success) {
        setBlogs(res.data.data.reverse());
        singleBlogFilter(res.data.data);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  function singleBlogFilter(blogs) {
    if (blogs) {
      const sblog = blogs?.filter(
        (item) => item.title === params.category.replace(/-/g, " ")
      );
      setSingleBlog(sblog[0]);
    }
  }

  useEffect(() => {
    getAllBlogs();
  }, []);

  const calculateReadingTime = (text) => {
    const wordsPerMinute = 200; // Adjust this value based on the average reading speed
    const wordCount = text.trim().split(/\s+/).length;
    const readingTimeMinutes = Math.ceil(wordCount / wordsPerMinute);
    return readingTimeMinutes;
  };

  function handleNavigate(title) {
    navigate(`/blog/${title.replace(/\s+/g, "-")}`);
  }

  useEffect(() => {
    singleBlogFilter(blogs);
  }, [params.category]);

  return (
    <OtherLayout
      background={`/blogImages/${singleBlog?.image}`}
      title={singleBlog?.title}
      extraSubTitle={singleBlog?.category}
    >
      <div className="blog-display-container Mon_font">
        <div className="blog-display">
          <h4>{singleBlog?.title}</h4>
          <p>{singleBlog?.description}</p>
          <p className="mb-0">
            <b>Written by - {singleBlog?.author}</b>
          </p>
          <p className="m-0">
            {new Date(singleBlog?.createdAt).toLocaleString("default", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </p>
        </div>
        <div className="more-blogs">
          <h4>Latest Blogs</h4>
          <hr className="mt-0" />
          {blogs &&
            blogs.map((item, index) => {
              return (
                <div
                  className="recent-blog"
                  onClick={() => handleNavigate(item?.title)}
                >
                  <div className="recent-blog-img">
                    <img src={`/blogImages/${item?.image}`} alt="" />
                  </div>
                  <div className="recent-blog-content">
                    <h6>{item?.title}</h6>
                    <p className="m-0">
                      <span>
                        <small>
                          {new Date(item?.createdAt).toLocaleString("default", {
                            day: "numeric",
                            month: "long",
                          })}
                        </small>
                      </span>
                      {" | "}
                      <span>
                        <small>
                          {calculateReadingTime(item.description)} min Read
                        </small>
                      </span>
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <TalkToExpert />
    </OtherLayout>
  );
};

export default CategoryBlogPage;
