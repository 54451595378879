import React, { useEffect, useState } from "react";
import "./SiteReviews.css";
import Slider from "react-slick";
import axios from "axios";
import StarPurple500SharpIcon from "@mui/icons-material/StarPurple500Sharp";
import { useLocation } from "react-router-dom";

const SiteReviews = () => {
  const location = useLocation();
  const [reviews, setReviews] = useState(null);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: 0,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  async function getAllReviews() {
    try {
      const res = await axios.get("/api/review/get-all-reviews");
      if (res.data.success) {
        setReviews(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllReviews();
  }, []);

  return (
    <div
      className={`site-reviews-container text-center ${
        location.pathname !== "/" && "bg-white mt-5 px-0"
      }`}
    >
      <h2 className="fw-bold">What Travellers Say About Us..</h2>
      <div className="hr-line mb-3"></div>
      <Slider {...settings}>
        {reviews &&
          reviews?.map((item, index) => {
            return (
              <div className="site-review">
                <div className="site_revieww">
                  <img
                    src={`https://highmountains.in/reviewImages/${item?.image}`}
                    alt=""
                  />
                  <div className="site-reviews-content">
                    <div className="review">
                      <h6>{item?.name}</h6>
                      <div className="stars">
                        <StarPurple500SharpIcon className="icon" />
                        <StarPurple500SharpIcon className="icon" />
                        <StarPurple500SharpIcon className="icon" />
                        <StarPurple500SharpIcon className="icon" />
                        <StarPurple500SharpIcon className="icon" />
                      </div>
                      <div className="review-text">
                        <span className={`text-dark`}>{item?.review}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default SiteReviews;
