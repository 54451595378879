import React from "react";
import "./WhyHighMountains.css";
import IMAGES from "../../images/LandingPage";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import HikingIcon from "@mui/icons-material/Hiking";
import SportsMotorsportsIcon from "@mui/icons-material/SportsMotorsports";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import SettingsSuggestSharpIcon from "@mui/icons-material/SettingsSuggestSharp";
import ChatSharpIcon from "@mui/icons-material/ChatSharp";
import { useLocation } from "react-router-dom";

const WhyHighMountains = () => {
  const location = useLocation();
  return (
    <div
      className={`why-high-mountains-container ${
        location.pathname === "/about" && "bg-light"
      }`}
    >
      <div className="why-image-section">
        <div className="why-left-img-section">
          <img src={IMAGES.why3} alt="" />
          <img src={IMAGES.why4} alt="" />
        </div>
        <div className="why-right-img-section">
          <img src={IMAGES.why1} alt="" />
          <img src={IMAGES.why2} alt="" />
          <img src={IMAGES.why5} alt="" />
        </div>
      </div>
      <div className="why-content-section Mon_font">
        <span>Why Choose Us</span>
        <h2>Why High Mountains</h2>
        <i>"Embark on Your Dream Adventure with High Mountains"</i>
        <br />
        <p className="mt-3">
          At High Mountains, we're all about turning your travel dreams into
          unforgettable experiences. Founded by Aman Singh Gulati, who started
          his journey at just 15 years old, we've been passionate about
          exploration from the very beginning.
        </p>
        <br />
        <p>
          With over 15 years of travel know-how and 8 years of bringing
          communities together on trips, we're here to offer more than just
          vacations—we provide experiences that stick with you long after you've
          returned home. We've helped over 25,000 travelers satisfy their
          wanderlust with 2000+ successful trips to over 20 destinations. Our
          goal is simple: to make sure every journey with us is filled with
          moments that leave you in awe. At High Mountains, we know it's not
          just about getting from point A to point B. It's about the thrill of
          discovery, the joy of new friendships, and the memories that last a
          lifetime. So, join our community and let's make your travel dreams a
          reality together. Choose High Mountains and let the adventure begin.
        </p>
        <div className="mt-5 why-features-container">
          <div className="why-features">
            <TravelExploreIcon className="icon" />
            <p className="mt-2 m-0">
              <b>25000+</b>
            </p>
            <span>
              <small>A Big HM family</small>
            </span>
          </div>
          <div className="why-features">
            <HikingIcon className="icon" />
            <p className="mt-2 m-0">
              <b>20+</b>
            </p>
            <span>
              <small>Destinations</small>
            </span>
          </div>
          <div className="why-features">
            <SportsMotorsportsIcon className="icon" />
            <p className="mt-2 m-0">
              <b>300+</b>
            </p>
            <span>
              <small>Road Trips</small>
            </span>
          </div>
          <div className="why-features">
            <Diversity1Icon className="icon" />
            <p className="mt-2 m-0">
              <b>500+</b>
            </p>
            <span>
              <small>Customized Trips Planned</small>
            </span>
          </div>
          <div className="why-features">
            <SettingsSuggestSharpIcon className="icon" />
            <p className="mt-2 m-0">
              <b>100+</b>
            </p>
            <span>
              <small>Itineraries Curated</small>
            </span>
          </div>
          <div className="why-features">
            <ChatSharpIcon className="icon" />
            <p className="mt-2 m-0">
              <b>1000+</b>
            </p>
            <span>
              <small>Reviews</small>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyHighMountains;
