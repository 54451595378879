import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../Style/Contact/Contact.css";
import axios from "axios";
import { message } from "antd";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import IMAGES from "../images/LandingPage";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import GroupsIcon from "@mui/icons-material/Groups";
import HikingIcon from "@mui/icons-material/Hiking";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";

const Contact = ({ setExpertForm }) => {
  document.title = `High Mountains - ${"Contact Us"}`;
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [form, setForm] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "POST",
      data: {
        name: form.name,
        phone: form.phone,
        email: form.email,
        message: form.message,
      },
      withCredentials: true,
      url: `api/inquiry/add-inquiry`,
    })
      .then((res) => {
        setLoading(false);
        setMsg(true);
        setForm({
          name: "",
          phone: "",
          email: "",
          message: "",
        });
        setTimeout(() => {
          setMsg(false);
        }, 2000);
        if (res.data.success) {
          message.success(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Header />
      {/* CONTACT HERO SECTION */}
      <div
        className="contact-container"
        style={{ background: `url(${IMAGES.contact})` }}
      >
        <div className="contact-bckd">
          {/* <h2>Contact Us</h2>
          <span>
            In six years, we've crafted 4,000+ unforgettable journeys. Join our
            #wravelerforlife community to explore destinations as homes away
            from home, where strangers become family. It's not just a trip; it's
            a lifelong adventure.
          </span> */}
        </div>
      </div>
      {/* FEATURE  */}
      <div className="contact-feature">
        <div className="row">
          <div className="col-4 col-sm-4 col-md-4 col-lg-4 cf">
            <ReceiptLongIcon className="icon" />
            <h6>For Customize Package</h6>
          </div>
          <div className="col-4 col-sm-4 col-md-4 col-lg-4 cf">
            <GroupsIcon className="icon" />
            <h6>For Corporate Tours</h6>
          </div>
          <div className="col-4 col-sm-4 col-md-4 col-lg-4 cf">
            <HikingIcon className="icon" />
            <h6>For Trek Related Queries</h6>
          </div>
        </div>
      </div>
      {/* <Form /> */}
      <div className="contact__form contact-form container rounded my-lg-3 my-3">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 Mon_font p-4 pt-2 text-center">
            <div
              className="c-details"
              style={{ background: `url(${IMAGES?.contact2})` }}
            >
              <div className="c-details-bckd">
                <div className="cntc">
                  <h5>Connect with Us</h5>
                  <div className="gap-3 mb-3 d-flex align-items-center">
                    <span>
                      <FmdGoodIcon />
                    </span>
                    <div className="text-start">
                      <h6 className="m-0">Address</h6>
                      <span>
                        <small>
                          High Mountains New Adarsh Market, Near Mall Road,
                          Manali, Himachal Pradesh 175131
                        </small>
                      </span>
                    </div>
                  </div>
                  <div className="gap-3 mb-3 d-flex align-items-center">
                    <span>
                      <EmailIcon />
                    </span>
                    <div className="text-start">
                      <h6 className="m-0">Email</h6>
                      <span>
                        <small>contact@highmountains.in</small>
                      </span>
                    </div>
                  </div>
                  <div className="gap-3 mb-3 d-flex align-items-center">
                    <span>
                      <LocalPhoneIcon />
                    </span>
                    <div className="text-start">
                      <h6 className="m-0">Phone</h6>
                      <span>
                        <small>+91 9888823721</small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="social__media__links">
              <Link target="_blank" to="https://instagram.com/highmountainsin">
                <InstagramIcon className="icon" />
              </Link>
              <Link target="_blank" to="https://wa.me/919888823721">
                <WhatsAppIcon className="icon" />
              </Link>
              <Link
                target="_blank"
                to="https://in.linkedin.com/company/highmountains"
              >
                <LinkedInIcon className="icon" />
              </Link>
              <Link
                target="_blank"
                to="https://www.youtube.com/@highmountainsin"
              >
                <YouTubeIcon className="icon" />
              </Link>
              <Link
                target="_blank"
                to="https://www.facebook.com/Highmountains.in/"
              >
                <FacebookIcon className="icon" />
              </Link>
            </div>
          </div>
          <div className={`Mon_font col-12 col-sm-12 col-md-8 col-lg-8`}>
            <h3 className={`my-3`}>
              HAVE A QUERY? WRITE TO US..
              <br />
              WE'LL GIVE YOU A <strong>CALL BACK</strong>
            </h3>

            <form
              className="row Mon_font mt-5"
              style={{ backgroundColor: "#fff" }}
              onSubmit={submitHandler}
            >
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-1 mb-lg-3 contact-form-input">
                <label
                  htmlFor="contact-first-name"
                  className="form-label contact-form-lable"
                >
                  Name
                </label>
                <input
                  className="w-100 my-2"
                  value={form.name}
                  required
                  name="name"
                  onChange={handleChange}
                  type="text"
                  id="contact-first-name-email"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-1 mb-lg-3 contact-form-input">
                <label
                  htmlFor="contact-phone"
                  className="form-label contact-form-lable"
                >
                  Phone
                </label>
                <input
                  className="w-100 my-2"
                  value={form.phone}
                  required
                  name="phone"
                  onChange={handleChange}
                  type="text"
                  id="contact-phone"
                  aria-describedby="emailHelp"
                  minLength={10}
                  maxLength={10}
                />
              </div>
              <div className="col-12 mb-1 mb-lg-3 contact-form-input">
                <label
                  htmlFor="contact-email"
                  className="form-label contact-form-lable"
                >
                  Email
                </label>
                <input
                  className="w-100 my-2"
                  value={form.email}
                  required
                  name="email"
                  onChange={handleChange}
                  type="email"
                  id="contact-email"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="col-12 mb-1 mb-lg-3 contact-form-input">
                <label
                  htmlFor="contact-message"
                  className="form-label contact-form-lable"
                >
                  Destination
                </label>
                <input
                  className="w-100 my-2"
                  value={form.message}
                  required
                  name="message"
                  onChange={handleChange}
                  type="text"
                  id="contact-email"
                  aria-describedby="emailHelp"
                />
              </div>
              {msg && (
                <span className="text-success">
                  <small>Form Submitted Successfully</small>
                </span>
              )}
              <div className={`my-2 ${windowWidth < 576 && "text-center"}`}>
                <button
                  to="#"
                  type="submit"
                  className="border-0 shd px-4 py-2 contact-submit-button rounded-pill text-warning"
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* <div>
          <div className="Mon_font text-center my-5">
            <p className="fw-bold fs-6">
              CONTACT NUMBER: +91 8383-0808-60, <br /> +91 798-232-8247
            </p>
            <span className="fw-bold fs-6">ADDRESS: </span>{" "}
            <span className="fs-6">
              Tripgix Ventures L-53 Basement Kalkaji, New delhi 110019
            </span>
          </div>
        </div> */}
      </div>

      <div className="google-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3374.4866972952495!2d77.18710777509929!3d32.24498491098599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390487fe716173c3%3A0x74662448616a7713!2sHigh%20Mountains!5e0!3m2!1sen!2sin!4v1707387244557!5m2!1sen!2sin"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      {/* <OfferAndUpdates /> */}
      <Footer />
    </>
  );
};

export default Contact;
