import React, { useEffect, useState } from "react";
import Layout from "../../components/Admin/AdminLayout";
import AdminHeader from "./component/AdminHeader";
import { message } from "antd";
import axios from "axios";

const Banner = () => {
  const [loading, setLoading] = useState(false);
  const [inputFields, setInputFields] = useState({
    location: "",
    position: "",
  });
  const [banners, setBanners] = useState(null);
  const [category, setAllCategory] = useState(null);
  const [image, setImage] = useState(null);

  // get all category
  const getAllCategory = async () => {
    await axios
      .get(`api/category/get-all-category`)
      .then((res) => {
        let categories = [];
        if (res.data.data) {
          res.data?.data.forEach((category) => {
            categories.push(category.name);
          });
        }
        setAllCategory(categories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function handleDelete(img) {
    try {
      const res = await axios.post("/api/banner/delete-banner", { image: img });
      if (res.data.success) {
        message.success(res.data.message);
        getAllBanner();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllCategory();
    getAllBanner();
    // eslint-disable-next-line
  }, []);

  // on form value change
  const handleChange = (event, value) => {
    setInputFields({ ...inputFields, [event.target.name]: event.target.value });
  };

  //on form submit
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("location", inputFields.location);
      formData.append("position", inputFields.position);
      formData.append("image", image);
      setLoading(true); // set loading to true during the request

      const res = await axios.post("api/banner/add-banner", formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        setImage(null);
        setLoading(false);
        getAllBanner();
        setInputFields({ location: "", position: "" });
        message.success(res.data.message);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false); // set loading to false in case of an error
    }
  };

  // on banner delete
  const deleteBanner = (id) => {
    axios({
      method: "POST",
      data: {
        id: id,
      },
      withCredentials: true,
      url: `api/banner/delete-banner`,
    })
      .then((res) => {
        getAllBanner();
        message.success(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllBanner = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url: `api/banner/get-all-banner`,
    })
      .then((res) => {
        setBanners(res.data.data.banners);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout>
      <AdminHeader
        title="Promotional Banner"
        redirect="/admin-dashboard"
        btnName="Add City Image"
        btnUrl="/add-city-image"
        btnName2="Add Coupon Code"
        btnUrl2="/add-coupon-code"
      />
      <div className="container Mon_font px-4">
        <form
          className=""
          style={{ backgroundColor: "#fff" }}
          onSubmit={submitHandler}
        >
          <div className="row">
            <div className="col-3 my-3">
              <label htmlFor="location" className="form-label">
                Select Location
              </label>
              <select
                className="form-select"
                value={inputFields?.location}
                required
                name="location"
                onChange={(event) => {
                  handleChange(event);
                }}
              >
                <option value="">Select</option>
                {category &&
                  category.map((category) => {
                    return <option value={category}>{category}</option>;
                  })}
              </select>
            </div>
            <div className="col-3 my-3">
              <label htmlFor="location" className="form-label">
                Select Banner Position
              </label>
              <select
                className="form-select"
                value={inputFields?.position}
                required
                name="position"
                onChange={(event) => {
                  handleChange(event);
                }}
              >
                <option value="">Select</option>
                <option value="firstBanner">First Banner</option>
                <option value="secondBanner">Second Banner</option>
                {/* <option value="thirdBanner">Third Banner</option> */}
                {/* <option value="fourthBanner">Fourth Banner</option> */}
              </select>
            </div>
            <div className="col-3 my-3">
              <label htmlFor="tripImage" className="form-label">
                Add Banner
              </label>
              <input
                required
                type="file"
                accept="image/*"
                className="form-control"
                name="image"
                onChange={(e) => setImage(e.target.files[0])}
              />
              <span className="text-secondary">
                <small>Banner size - 1500x400 pixels</small>
              </span>
              {inputFields?.image && (
                <div className="col-4 my-2">
                  <img width={340} src={inputFields.image} alt="Loading" />
                </div>
              )}
            </div>
          </div>
          <div className="my-2">
            <button
              disabled={loading === true}
              type="submit"
              className="btn btn-warning w-100 "
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </form>
      </div>

      {/* all banners */}
      <div className="container Mon_font px-4 pb-4">
        <table className="table table-light table-bordered w-100 m-0 mt-4">
          <thead className="p-2 py-3 table-secondary">
            <tr>
              <th scope="col" className="py-2">
                Banner
              </th>
              <th scope="col" className="py-2">
                Location
              </th>
              <th scope="col" className="py-2">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {banners?.firstBannerArray?.map((item, index) => {
              return (
                <tr>
                  <td>
                    <img
                      width="300px"
                      src={`/bannerImages/${item?.image}`}
                      alt=""
                    />
                  </td>
                  <td>{item?.location}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(item?.image)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tbody>
            {banners?.secondBannerArray?.map((item, index) => {
              return (
                <tr>
                  <td>
                    <img
                      width="300px"
                      src={`/bannerImages/${item?.image}`}
                      alt=""
                    />
                  </td>
                  <td>{item?.location}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(item?.image)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default Banner;
