import React, { useState } from "react";
import IMAGES from "../../images/LandingPage/index";
import { useNavigate } from "react-router-dom";
import "../../Style/LandingPage/TalkToExpert.css";

const TalkToExpert = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="Mon_font talk__to__bg mt-2"
        style={{ background: `url(${IMAGES.tte2})` }}
      >
        <div className="ttbg-shade-bg">
          <h2>
            <b>Didn't find what you are looking for?</b>
          </h2>
          <p>Connect with our experts! Get the best itineraries and offers!</p>
          <button className="ttbg-btn" onClick={() => navigate("/contact")}>
            Connect Now
          </button>
        </div>
      </div>
    </>
  );
};

export default TalkToExpert;
