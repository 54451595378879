import React, { useEffect, useState } from "react";
import { message } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../../../components/Admin/AdminLayout";
import AdminHeader from "./AdminHeader";
import DeleteIcon from "@mui/icons-material/Delete";

// import noresult from "../../images/noresult.png";

const AdminAddMembers = () => {
  const [loading, setLoading] = useState(null);
  const [member, setMember] = useState(null);
  const [modalMsg, setModalMsg] = useState(null);
  const [image, setImage] = useState(null);
  const [inputField, setInputField] = useState({
    name: "",
    position: "",
    description: "",
  });

  const getAllMember = async (elm) => {
    await axios
      .get(`api/about/get-all-member`)
      .then((res) => {
        setMember(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (member === null) {
      getAllMember();
    }
    // eslint-disable-next-line
  }, []);

  const submit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", inputField.name);
    formData.append("position", inputField.position);
    formData.append("image", image);
    formData.append("description", inputField.description);
    setLoading(true);
    axios({
      method: "POST",
      data: formData,
      withCredentials: true,
      url: `api/about/add-member`,
    })
      .then((res) => {
        getAllMember();
        setTimeout(() => {
          setLoading(false);
        }, 1500);
        setInputField({
          name: "",
          position: "",
          description: "",
        });
        message.success(res.data.message);
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
        message.error(err.response.data.message);
        console.log(err);
      });
  };

  const deleteBlog = (id) => {
    axios({
      method: "POST",
      data: {
        id: id,
      },
      withCredentials: true,
      url: `api/about/delete-member`,
    })
      .then((res) => {
        getAllMember();
        setTimeout(() => {
          setLoading(false);
        }, 1500);
        message.success(res.data.message);
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
        console.log(err);
      });
  };

  const hanleChange = (event, value) => {
    if (value === "image") {
      return setInputField({
        ...inputField,
        [event.target.name]: event.target.files[0],
      });
    }
    setInputField({ ...inputField, [event.target.name]: event.target.value });
  };

  return (
    <div>
      <Layout>
        <AdminHeader title="About Page Members" redirect="/admin-dashboard" />
        <div className=" Mon_font px-3 m-0 pb-4">
          <form
            className="row g-3 m-0 px-2 py-3 rounded border border-2"
            style={{ backgroundColor: "#f5f5f5" }}
            onSubmit={submit}
          >
            <div className="col-12 m-0">
              <input
                className="form-control"
                value={inputField.title}
                type="text"
                name="name"
                required
                placeholder="Member Name"
                onChange={hanleChange}
                maxLength={200}
              />
            </div>
            <div className="col-12">
              <input
                className="form-control"
                value={inputField.title}
                type="text"
                name="position"
                required
                placeholder="Position"
                onChange={hanleChange}
                maxLength={200}
              />
            </div>
            <div className="col-3 ">
              <input
                aria-label="Select Image"
                className="form-control"
                type="file"
                accept=".jpg, .jpeg, .png"
                name="image"
                required
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>
            <span>
              <small>Upload square shape</small>
            </span>
            <div className="col-12 ">
              <textarea
                rows="6"
                style={{ resize: "none" }}
                className="form-control"
                value={inputField.description}
                type="text"
                name="description"
                required
                placeholder="description"
                onChange={hanleChange}
                maxLength={2000}
              />
            </div>
            <div className="col-2 d-flex justify-content-center align-items-center">
              <button type="submit" className="btn btn-warning w-100">
                {loading ? "Loading..." : "Save"}
              </button>
            </div>
          </form>
          {member?.length === 0 ? (
            <div className="d-flex flex-column justify-content-center align-items-center mt-5">
              {/* <img src={noresult} alt="" width={150} /> */}
              <h3>No Record Found</h3>
            </div>
          ) : (
            <div className="w-100 mt-5 Mon_font overflow-auto">
              <div className="d-flex justify-content-end m-0">
                <p className="m-0 mb-3">Total Member: {member?.length}</p>
              </div>
              <table className="table border">
                <thead>
                  <tr>
                    <th scope="col" className="py-2 px-3 border" style={style}>
                      #
                    </th>
                    <th scope="col" className="py-2 border" style={style}>
                      Name
                    </th>
                    <th scope="col" className="py-2 px-3 border" style={style}>
                      Position
                    </th>
                    <th
                      scope="col"
                      className="py-2 text-center border"
                      style={style}
                    >
                      image
                    </th>
                    <th
                      scope="col"
                      className="py-2 text-center border"
                      style={style}
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="py-2 text-center border"
                      style={style}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="table-group-divider">
                  {member &&
                    member.map((mem, index) => {
                      console.log(mem?.image);
                      return (
                        <>
                          <tr>
                            <th
                              scope="row"
                              className="py-2 px-3 border"
                              style={style}
                            >
                              {index + 1}
                            </th>
                            <td className="py-2 px-3 border" style={style}>
                              {mem?.name}
                            </td>
                            <td className="py-2 px-3 border" style={style}>
                              {mem?.position}
                            </td>
                            <td
                              className="py-2 border text-center"
                              style={style}
                            >
                              <img
                                width={90}
                                src={`/AboutMemberImg/${mem?.image}`}
                                alt=""
                              />
                            </td>
                            <td className="text-center">
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() => {
                                  setModalMsg(mem?.description);
                                }}
                              >
                                View
                              </Link>
                              <div
                                className="modal"
                                tabIndex="-1"
                                id="exampleModal"
                              >
                                <div className="modal-dialog">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5 className="modal-title">
                                        Full Description
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => {
                                          setModalMsg(mem?.description);
                                        }}
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <p
                                        className="text-start txt-sm"
                                        style={{
                                          overflowWrap: "break-word",
                                          wordWrap: "break-word",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        {modalMsg}
                                      </p>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                          setModalMsg(null);
                                        }}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="text-center border">
                              <DeleteIcon
                                className="text-danger"
                                onClick={() => {
                                  deleteBlog(mem?._id);
                                }}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
          <div></div>
        </div>
      </Layout>
    </div>
  );
};

const style = {
  fontSize: "13px",
};

export default AdminAddMembers;
