import { message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../components/Admin/AdminLayout";
import { Multiselect } from "multiselect-react-dropdown";
import "react-datepicker/dist/react-datepicker.css";
import AdminHeader from "./component/AdminHeader";

const AddTrip = () => {
  const navigate = useNavigate();

  const [costing, setCosting] = useState([
    {
      heading: "",
      package: [{ name: "", price: "" }],
    },
  ]);

  const handleCostChange = (index, field, subField, value, packageIndex) => {
    setCosting((prevCosting) => {
      const newCosting = [...prevCosting];
      if (!newCosting[index]) {
        newCosting[index] = { heading: "", package: [{ name: "", price: "" }] };
      }
      if (field === "package") {
        newCosting[index].package = newCosting[index].package || [];
        if (!newCosting[index].package[packageIndex]) {
          newCosting[index].package[packageIndex] = { name: "", price: "" };
        }
        newCosting[index].package[packageIndex][subField] = value;
      } else if (field === "heading") {
        newCosting[index][field] = value; // Fix here
      } else {
        newCosting[index][field] = value;
      }
      return newCosting;
    });
  };

  const addCostingFields = (index) => {
    const newCosting = [...costing];
    newCosting[index].package = newCosting[index].package || [];
    const newPackage = { name: "", price: "" };
    newCosting[index].package.push(newPackage);
    setCosting(newCosting);
  };

  const removeCostingFields = (index, packageIndex) => {
    const newCosting = [...costing];
    newCosting[index].package = newCosting[index].package || [];
    newCosting[index].package.splice(packageIndex, 1);
    setCosting(newCosting);
  };

  const addNewCostingObject = () => {
    setCosting([
      ...costing,
      { heading: "", package: [{ name: "", price: "" }] },
    ]);
  };

  const removeCostingObject = (index) => {
    const newCosting = [...costing];
    newCosting.splice(index, 1);
    setCosting(newCosting);
  };

  //! COSTING ENDS
  //! HM COSTING STARTS
  const [hmCosting, setHmCosting] = useState([
    {
      heading: "",
      package: [{ name: "", pax: "", price: "" }],
    },
  ]);

  const handleHmCostChange = (index, field, subField, value, packageIndex) => {
    setHmCosting((prevHmCosting) => {
      const newHmCosting = [...prevHmCosting];
      if (!newHmCosting[index]) {
        newHmCosting[index] = {
          heading: "",
          package: [{ name: "", pax: "", price: "" }],
        };
      }
      if (field === "package") {
        newHmCosting[index].package = newHmCosting[index].package || [];
        if (!newHmCosting[index].package[packageIndex]) {
          newHmCosting[index].package[packageIndex] = {
            name: "",
            pax: "",
            price: "",
          };
        }
        newHmCosting[index].package[packageIndex][subField] = value;
      } else if (field === "heading") {
        newHmCosting[index][field] = value;
      } else {
        newHmCosting[index][field] = value;
      }
      return newHmCosting;
    });
  };

  const addHmCostingFields = (index) => {
    const newHmCosting = [...hmCosting];
    newHmCosting[index].package = newHmCosting[index].package || [];
    const newPackage = { name: "", pax: "", price: "" };
    newHmCosting[index].package.push(newPackage);
    setHmCosting(newHmCosting);
  };

  const removeHmCostingFields = (index, packageIndex) => {
    const newHmCosting = [...hmCosting];
    newHmCosting[index].package = newHmCosting[index].package || [];
    newHmCosting[index].package.splice(packageIndex, 1);
    setHmCosting(newHmCosting);
  };

  const addNewHmCostingObject = () => {
    setHmCosting([
      ...hmCosting,
      { heading: "", package: [{ name: "", pax: "", price: "" }] },
    ]);
  };

  const removeHmCostingObject = (index) => {
    const newHmCosting = [...hmCosting];
    newHmCosting.splice(index, 1);
    setHmCosting(newHmCosting);
  };

  //! HM COSTING ENDS

  const [loading, setLoading] = useState(false);
  const [itinerary, setItinerary] = useState([
    { heading: null, description: null },
  ]);
  const [notes, setNotes] = useState([{ name: null }]);
  const [inclusion, setInclusion] = useState([{ name: null }]);
  const [thingsToCarry, setThingsToCarry] = useState([
    { name: null, description: null },
  ]);
  const [mustCarry, setMustCarry] = useState([{ name: null }]);
  const [tnc, setTnc] = useState([{ name: null }]);
  const [testimonials, setTestimonials] = useState([
    { heading: null, date: null, description: null, userimage: null },
  ]);
  const [exclusion, setExclusion] = useState([{ name: null }]);
  const [videos, setVideos] = useState([{ url: null }]);
  const [category, setCategory] = useState(null);
  const [selectedDates, setSelectedDates] = useState([{ date: null }]);
  const [dateStatus, setDateStatus] = useState([{ status: null, date: null }]);
  const [inputFields, setInputFields] = useState({
    customised: "false",
    bestTime: "Jan",
  });
  const [allCategory, setAllCategory] = useState([]);

  const [imageFile, setImageFile] = useState(null);
  const [galleryFiles, setGalleryFiles] = useState([]);
  const [accomodationFiles, setAccomodationFiles] = useState([]);

  const handleImageChange = (event) => {
    setImageFile(event.target.files[0]);
  };

  const handleGalleryChange = (event) => {
    setGalleryFiles(event.target.files);
  };

  const handleAccomodationChange = (event) => {
    setAccomodationFiles(event.target.files);
  };

  // get all category
  const getAllCategory = async () => {
    await axios
      .get(`api/category/get-all-category`)
      .then((res) => {
        let categories = [];
        if (res.data.data) {
          res.data?.data.forEach((category) => {
            categories.push(category.name);
          });
        }
        setAllCategory(categories);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllCategory();
    // eslint-disable-next-line
  }, []);

  //on submit form
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", imageFile);

    if (galleryFiles.length > 0) {
      for (let i = 0; i < galleryFiles.length; i++) {
        formData.append("gallery", galleryFiles[i]);
      }
    }

    if (accomodationFiles.length > 0) {
      for (let i = 0; i < accomodationFiles.length; i++) {
        formData.append("accomodation", accomodationFiles[i]);
      }
    }

    formData.append("pdfFile", JSON.stringify(inputFields.pdfFile));
    formData.append("heading", JSON.stringify(inputFields.heading));
    formData.append("duration", JSON.stringify(inputFields.duration));
    formData.append("departure", JSON.stringify(inputFields.departure));
    formData.append("drop", JSON.stringify(inputFields.drop));
    formData.append("reviews", JSON.stringify(inputFields.reviews));
    formData.append("thoughts", JSON.stringify(inputFields.thoughts));
    formData.append("about", JSON.stringify(inputFields.about));
    formData.append("location", JSON.stringify(inputFields.location));
    formData.append("costing", JSON.stringify(costing));
    formData.append("hmCosting", JSON.stringify(hmCosting));
    formData.append("itinerary", JSON.stringify(itinerary));
    formData.append("thingstocarry", JSON.stringify(thingsToCarry));
    formData.append("tnc", JSON.stringify(tnc));
    formData.append("inclusion", JSON.stringify(inclusion));
    formData.append("exclusion", JSON.stringify(exclusion));
    formData.append("videos", JSON.stringify(videos));
    formData.append("dates", JSON.stringify(selectedDates));
    formData.append("dateStatus", JSON.stringify(dateStatus));
    formData.append("category", JSON.stringify(category));
    formData.append("customised", JSON.stringify(inputFields.customised));
    formData.append("bestTime", JSON.stringify(inputFields.bestTime));
    // formData.append("notes", JSON.stringify(notes));
    // formData.append("testimonials", JSON.stringify(testimonials));
    // formData.append("mustcarry", JSON.stringify(mustCarry));
    // formData.append("totalSeat", JSON.stringify(inputFields.totalSeat));
    // formData.append("season", JSON.stringify(inputFields.season));
    // formData.append("highlighted", JSON.stringify(inputFields.highlighted));
    // formData.append("trending", JSON.stringify(inputFields.trending));

    setLoading(true);
    try {
      const response = await axios.post("api/trips/create-trip", formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        navigate("/all-trips");
        setTimeout(() => {
          setLoading(false);
        }, 1500);

        message.success(response.data.message);
      }
    } catch (error) {
      // Handle errors
      setTimeout(() => {
        setLoading(false);
      }, 1500);
      console.error(error);
    }
  };

  // onChange normal form
  const handleOnChange = async (event, elm) => {
    if (elm === "highlighted") {
      let value = false;
      if (typeof event.currentTarget.value === "string") {
        event.target.value === "true" ? (value = true) : (value = false);
      }
      setInputFields({ ...inputFields, highlighted: value });
    }
    if (elm === "trending") {
      let value = false;
      if (typeof event.currentTarget.value === "string") {
        event.target.value === "true" ? (value = true) : (value = false);
      }
      setInputFields({ ...inputFields, trending: value });
    }
    if (elm === "customised") {
      let value = false;
      if (typeof event.currentTarget.value === "string") {
        event.target.value === "true" ? (value = true) : (value = false);
      }
      setInputFields({ ...inputFields, customised: value });
    }
    setInputFields({ ...inputFields, [event.target.name]: event.target.value });
  };

  // onChange Dynamic form
  const handleFormChanges = (event, index, value, value2) => {
    if (value === "costing") {
      let data = [...costing];
      data[index][event.target.name] = event.target.value;
      setCosting(data);
    }
    if (value === "itinerary") {
      let data = [...itinerary];
      data[index][event.target.name] = event.target.value;
      setItinerary(data);
    }
    if (value === "thingsToCarry") {
      let data = [...thingsToCarry];
      data[index][event.target.name] = event.target.value;
      setThingsToCarry(data);
    }
    if (value === "mustCarry") {
      let data = [...mustCarry];
      data[index][event.target.name] = event.target.value;
      setMustCarry(data);
    }
    if (value === "testimonials") {
      if (value2 === "userimage") {
        const image = event.target.files[0];
        if (
          image.type === "image/jpeg" ||
          image.type === "image/png" ||
          image.type === "image/jpg"
        ) {
          const data = new FormData();
          data.append("file", image);
          data.append("upload_preset", "onlinelecture");
          data.append("cloud_name", "Krantikaari");
          fetch("https://api.cloudinary.com/v1_1/dn5oapayl/image/upload/", {
            method: "post",
            body: data,
          })
            .then((res) => res.json())
            .then((data) => {
              let tesimonialsData = [...testimonials];
              tesimonialsData[index][event.target.name] = data.secure_url;
              setTestimonials(tesimonialsData);
            })
            .catch((err) => {
              message.error(err);
            });
        } else {
          return message.error("Please Select an image");
        }
      }

      let data = [...testimonials];
      data[index][event.target.name] = event.target.value;
      setTestimonials(data);
    }
    if (value === "tnc") {
      let data = [...tnc];
      data[index][event.target.name] = event.target.value;
      setTnc(data);
    }
    if (value === "notes") {
      let data = [...notes];
      data[index][event.target.name] = event.target.value;
      setNotes(data);
    }
    if (value === "inclusion") {
      let data = [...inclusion];
      data[index][event.target.name] = event.target.value;
      setInclusion(data);
    }
    if (value === "exclusion") {
      let data = [...exclusion];
      data[index][event.target.name] = event.target.value;
      setExclusion(data);
    }
    if (value === "youtubeVideos") {
      let data = [...videos];
      data[index][event.target.name] = event.target.value;
      setVideos(data);
    }
    if (value === "date") {
      let data = [...selectedDates];
      data[index][event.target.name] = event.target.value;
      setSelectedDates(data);
    }
    if (value === "dateStatus") {
      let data = [...dateStatus];
      data[index][event.target.name] = event.target.value;
      setDateStatus(data);
    }
  };

  // add fields
  const addFields = (value, index) => {
    if (value === "costing") {
      const newFields = [...costing];
      const object = { ...newFields[index], name: null, price: null };
      newFields[index] = object;
      setCosting(newFields);
    }
    if (value === "itinerary") {
      const object = {
        heading: null,
        description: null,
      };
      setItinerary([...itinerary, object]);
    }
    if (value === "testimonials") {
      const object = {
        heading: null,
        date: null,
        description: null,
        userimage: null,
      };
      setTestimonials([...testimonials, object]);
    }
    if (value === "thingsToCarry") {
      const object = {
        name: null,
        description: null,
      };
      setThingsToCarry([...thingsToCarry, object]);
    }
    if (value === "mustCarry") {
      const object = {
        name: null,
      };
      setMustCarry([...mustCarry, object]);
    }
    if (value === "tnc") {
      const object = {
        name: null,
      };
      setTnc([...tnc, object]);
    }
    if (value === "notes") {
      const object = {
        name: null,
      };
      setNotes([...notes, object]);
    }
    if (value === "inclusion") {
      const object = {
        name: null,
      };
      setInclusion([...inclusion, object]);
    }
    if (value === "exclusion") {
      const object = {
        name: null,
      };
      setExclusion([...exclusion, object]);
    }
    if (value === "youtubeVideos") {
      const object = {
        url: null,
      };
      setVideos([...videos, object]);
    }
    if (value === "date") {
      const object = {
        date: null,
      };
      setSelectedDates([...selectedDates, object]);
    }
    if (value === "dateStatus") {
      const object = {
        status: null,
        date: null,
      };
      setDateStatus([...dateStatus, object]);
    }
  };
  // remove fields
  const removeFields = (index, value) => {
    if (value === "costing") {
      const data = [...costing];
      data.splice(index, 1);
      setCosting(data);
    }
    if (value === "itinerary") {
      const data = [...itinerary];
      data.splice(index, 1);
      setItinerary(data);
    }
    if (value === "testimonials") {
      const data = [...testimonials];
      data.splice(index, 1);
      setTestimonials(data);
    }
    if (value === "thingsToCarry") {
      const data = [...thingsToCarry];
      data.splice(index, 1);
      setThingsToCarry(data);
    }
    if (value === "mustCarry") {
      const data = [...mustCarry];
      data.splice(index, 1);
      setMustCarry(data);
    }
    if (value === "tnc") {
      const data = [...tnc];
      data.splice(index, 1);
      setTnc(data);
    }
    if (value === "notes") {
      const data = [...notes];
      data.splice(index, 1);
      setNotes(data);
    }
    if (value === "inclusion") {
      const data = [...inclusion];
      data.splice(index, 1);
      setInclusion(data);
    }
    if (value === "exclusion") {
      const data = [...exclusion];
      data.splice(index, 1);
      setExclusion(data);
    }
    if (value === "youtubeVideos") {
      const data = [...videos];
      data.splice(index, 1);
      setVideos(data);
    }
    if (value === "date") {
      const data = [...selectedDates];
      data.splice(index, 1);
      setSelectedDates(data);
    }
    if (value === "dateStatus") {
      const data = [...dateStatus];
      data.splice(index, 1);
      setDateStatus(data);
    }
  };

  return (
    <Layout>
      <AdminHeader title="Add Trips" redirect="/add-category" />
      <div className="container Mon_font px-3 m-0 pb-4">
        <form className="row g-3 m-0 p-0" onSubmit={handleSubmit}>
          <div
            className="row g-3 p-2 m-0 border border-2 rounded pb-4"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            <div className="col-6">
              <label htmlFor="heading" className="form-label">
                Title
              </label>
              <input
                required
                placeholder="title"
                className="form-control"
                id="heading"
                name="heading"
                type="text"
                onChange={(event) => {
                  handleOnChange(event);
                }}
                maxLength={70}
              />
            </div>
            <div className="col-3">
              <label htmlFor="duration" className="form-label">
                Duration
              </label>
              <select
                required
                className="form-select"
                name="duration"
                id="duration"
                onChange={(event) => {
                  handleOnChange(event);
                }}
              >
                <option selected>Select</option>
                <option value="1N-2D">1N-2D</option>
                <option value="2N-3D">2N-3D</option>
                <option value="3N-4D">3N-4D</option>
                <option value="4N-5D">4N-5D</option>
                <option value="5N-6D">5N-6D</option>
                <option value="6N-7D">6N-7D</option>
                <option value="7N-8D">7N-8D</option>
                <option value="8N-9D">8N-9D</option>
                <option value="9N-10D">9N-10D</option>
                <option value="10N-11D">10N-11D</option>
                <option value="11N-12D">11N-12D</option>
                <option value="12N-13D">12N-13D</option>
                <option value="13N-14D">13N-14D</option>
                <option value="14N-15D">14N-15D</option>
                <option value="15N-16D">15N-16D</option>
                <option value="16N-17D">16N-17D</option>
                <option value="17N-18D">17N-18D</option>
                <option value="18N-19D">18N-19D</option>
                <option value="19N-20D">19N-20D</option>
              </select>
            </div>
            <div className="col-3">
              <label htmlFor="departure" className="form-label">
                Pickup
              </label>
              <input
                required
                placeholder="mumbai"
                className="form-control"
                id="departure"
                name="departure"
                type="text"
                onChange={(event) => {
                  handleOnChange(event);
                }}
                maxLength={20}
              />
            </div>
            <div className="col-3">
              <label htmlFor="departure" className="form-label">
                Drop
              </label>
              <input
                required
                placeholder="mumbai"
                className="form-control"
                name="drop"
                type="text"
                onChange={(event) => {
                  handleOnChange(event);
                }}
                maxLength={20}
              />
            </div>
            <div className="col-3">
              <label htmlFor="departure" className="form-label">
                Reviews
              </label>
              <input
                required
                placeholder="numbers only"
                className="form-control"
                name="reviews"
                type="text"
                onChange={(event) => {
                  handleOnChange(event);
                }}
                maxLength={20}
              />
            </div>

            {/* Select Multiple Category */}
            <div className="col-6">
              <label htmlFor="category" className="form-label">
                Catgory
              </label>
              <Multiselect
                id="category"
                className="multiselect-wrapper"
                isObject={false}
                onRemove={(event) => {
                  if (event.length === 0) {
                    setCategory(null);
                  } else {
                    setCategory(event);
                  }
                }}
                onSelect={(event) => {
                  setCategory(event);
                }}
                options={allCategory}
              />
            </div>
            {/* Select Multiple Category */}

            <div className="col-3">
              <label htmlFor="location" className="form-label">
                Location
              </label>
              <input
                required
                placeholder="manali"
                className="form-control"
                id="location"
                name="location"
                type="text"
                onChange={(event) => {
                  handleOnChange(event);
                }}
                maxLength={20}
              />
            </div>
            {/* <div className="col-3">
              <label htmlFor="totalSeat" className="form-label">
                Total Seat
              </label>
              <input
                required
                placeholder="30"
                className="form-control"
                id="totalSeat"
                name="totalSeat"
                type="text"
                onChange={(event) => {
                  handleOnChange(event);
                }}
                maxLength={5}
              />
            </div> */}
            {/* <div className="d-flex justify-content-between">
              <div className="col-4">
                <label htmlFor="season" className="form-label">
                  Season
                </label>
                <select
                  required
                  className="form-select"
                  name="season"
                  id="season"
                  onChange={(event) => {
                    handleOnChange(event);
                  }}
                >
                  <option value="">Choose</option>
                  <option value="spring">Spring</option>
                  <option value="summer">Summer</option>
                  <option value="monsoon">Monsoon</option>
                  <option value="pre-winter">Pre-winter</option>
                  <option value="winter">Winter</option>
                </select>
              </div>
              <div className="d-flex justify-content-between col-3 align-items-center mb-2 mt-4">
                <h5 className="m-0">Highlighted: </h5>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="highlighted"
                    value="true"
                    id="highlightedYes"
                    onChange={(event) => {
                      handleOnChange(event, "highlighted");
                    }}
                  />
                  <label className="form-check-label" htmlFor="highlightedYes">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="highlighted"
                    value="false"
                    id="highlightedNo"
                    onChange={(event) => {
                      handleOnChange(event, "highlighted");
                    }}
                  />
                  <label className="form-check-label" htmlFor="highlightedNo">
                    No
                  </label>
                </div>
              </div>
              <div className="d-flex justify-content-between col-3 align-items-center mb-2 mt-4">
                <h5 className="m-0">Trending: </h5>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="trending"
                    value="true"
                    id="trendingYes"
                    onChange={(event) => {
                      handleOnChange(event, "trending");
                    }}
                  />
                  <label className="form-check-label" htmlFor="trendingYes">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="trending"
                    value="false"
                    id="trendingNo"
                    onChange={(event) => {
                      handleOnChange(event, "trending");
                    }}
                  />
                  <label className="form-check-label" htmlFor="trendingNo">
                    No
                  </label>
                </div>
              </div>
            </div> */}
            <div className="col-8">
              <label htmlFor="thoughts" className="form-label">
                Thoughts
              </label>
              <input
                required
                type="text"
                name="thoughts"
                className="form-control"
                id="thoughts"
                placeholder="Experience the incredible wild life of Bali"
                onChange={(event) => {
                  handleOnChange(event);
                }}
              />
            </div>
            <div className="col-12">
              <label htmlFor="about" className="form-label">
                About
              </label>
              <textarea
                required
                type="text"
                name="about"
                className="form-control"
                id="about"
                placeholder="Experience the incredible wild life of Bali"
                onChange={(event) => {
                  handleOnChange(event);
                }}
              />
            </div>
            <div className="d-flex justify-content-between flex-wrap">
              {/* Single Trip Image */}
              <div className="col-3">
                <label className="form-label">Select image</label>
                {imageFile && (
                  <div className="col-4 ">
                    <img
                      className="border rounded rounded-2 bg-white p-1 mb-2"
                      width={100}
                      src={URL.createObjectURL(imageFile)}
                      alt="Loading"
                    />
                  </div>
                )}
                <input
                  required
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={handleImageChange}
                />
              </div>

              <div className="col-5">
                <label htmlFor="trip-pdf" className="form-label">
                  Pdf File Link
                </label>
                <input
                  required
                  className="form-control"
                  id="trip-pdf"
                  name="pdfFile"
                  placeholder="enter drive link only"
                  type="text"
                  onChange={(event) => handleOnChange(event)}
                />
              </div>
              <div className="d-flex justify-content-between col-3 align-items-center mb-2 mt-4">
                <h5 className="m-0">Customised: </h5>
                <div className="form-check">
                  <label className="form-check-label" htmlFor="customisedYes">
                    Yes
                  </label>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="customised"
                    value="true"
                    id="customisedYes"
                    onChange={(event) => {
                      handleOnChange(event, "customised");
                    }}
                  />
                </div>
                <div className="form-check">
                  <label className="form-check-label" htmlFor="customisedNo">
                    No
                  </label>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="customised"
                    value="false"
                    id="customisedNo"
                    onChange={(event) => {
                      handleOnChange(event, "customised");
                    }}
                  />
                </div>
              </div>
            </div>
            {inputFields.customised === "true" && (
              <div className="col-6">
                <label htmlFor="">Best Time to visit</label>
                <input
                  name="bestTime"
                  type="text"
                  className="form-control"
                  placeholder="Jan, Feb, Dec"
                  value={inputFields?.bestTime}
                  onChange={(event) => {
                    handleOnChange(event);
                  }}
                />
              </div>
            )}
          </div>
          {/* multiple select dates*/}
          {inputFields.customised === "false" && (
            <div className="m-0 p-0">
              <h5 className="mb-1 mt-4 ps-4 py-2 text-white rounded bg-secondary">
                Select Date
              </h5>
              <div
                className="rounded border border-2 p-3 mt-4"
                style={{ backgroundColor: "#f5f5f5" }}
              >
                {selectedDates?.map((form, index) => {
                  return (
                    <div
                      key={index}
                      className="d-flex flex-row gap-3 my-2 align-items-end"
                    >
                      <div className="col-8">
                        <input
                          required
                          name="date"
                          placeholder="name"
                          type="date"
                          className="form-control"
                          id="selectDate"
                          onChange={(event) =>
                            handleFormChanges(event, index, "date")
                          }
                          value={form.date}
                        />
                      </div>
                      <div className="col-2">
                        <Link
                          to="#"
                          className="btn btn-danger btn-sm"
                          onClick={() => removeFields(index, "date")}
                        >
                          Remove
                        </Link>
                      </div>
                    </div>
                  );
                })}
                <Link
                  to="#"
                  className="btn btn-secondary btn-sm"
                  onClick={() => {
                    addFields("date");
                  }}
                >
                  Add More
                </Link>
              </div>
            </div>
          )}
          {/* end of multiple select dates*/}

          {/*add mutiple text in dates*/}
          {inputFields.customised === "false" && (
            <div className="m-0 p-0">
              <h5 className="mb-1 mt-4 ps-4 py-2 text-white rounded bg-secondary">
                Set Date Status
              </h5>
              <div
                className="rounded border border-2 p-3 mt-4"
                style={{ backgroundColor: "#f5f5f5" }}
              >
                {dateStatus?.map((form, index) => {
                  return (
                    <div
                      key={index}
                      className="d-flex flex-row gap-3 my-2 align-items-end"
                    >
                      <div className="col-4">
                        <input
                          required
                          name="date"
                          placeholder="name"
                          type="date"
                          className="form-control"
                          onChange={(event) =>
                            handleFormChanges(event, index, "dateStatus")
                          }
                        />
                      </div>
                      <div className="col-6">
                        <input
                          required
                          name="status"
                          placeholder="name"
                          type="text"
                          className="form-control"
                          onChange={(event) =>
                            handleFormChanges(event, index, "dateStatus")
                          }
                        />
                      </div>
                      <div className="col-2">
                        <Link
                          to="#"
                          className="btn btn-danger btn-sm"
                          onClick={() => removeFields(index, "dateStatus")}
                        >
                          Remove
                        </Link>
                      </div>
                    </div>
                  );
                })}
                <Link
                  to="#"
                  className="btn btn-secondary btn-sm"
                  onClick={() => {
                    addFields("dateStatus");
                  }}
                >
                  Add More
                </Link>
              </div>
            </div>
          )}
          {/*add mutiple text in dates*/}

          {/* costing dynamic form field */}
          <h5 className="mb-1 mt-4 ps-4 py-2 text-white bg-secondary rounded">
            Costing
          </h5>
          {inputFields.customised === "true" ? (
            <div
              className="rounded border border-2 p-3 mt-4"
              style={{ backgroundColor: "#f5f5f5" }}
            >
              {hmCosting?.map((form, index) => (
                <div
                  key={index}
                  className="rounded border border-2 p-3 mt-4"
                  style={{ backgroundColor: "#f5f5f5" }}
                >
                  <div className="d-flex row flex-row gap-3 my-2 align-items-end">
                    <div className="d-flex col-8">
                      <input
                        required
                        name="heading"
                        placeholder="heading"
                        type="text"
                        className="form-control"
                        value={form.heading}
                        onChange={(e) =>
                          handleHmCostChange(
                            index,
                            "heading",
                            "",
                            e.target.value
                          )
                        }
                      />
                      <div className="col-2">
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => removeHmCostingObject(index)}
                        >
                          Remove Object
                        </button>
                      </div>
                    </div>
                  </div>
                  {(form.package || []).map((packageItem, packageIndex) => (
                    <div
                      key={packageIndex}
                      className="d-flex row flex-row gap-3 my-2 align-items-end"
                    >
                      <div className="col-3">
                        <input
                          required
                          name="name"
                          placeholder="name"
                          type="text"
                          className="form-control"
                          value={packageItem.name}
                          onChange={(e) =>
                            handleHmCostChange(
                              index,
                              "package",
                              "name",
                              e.target.value,
                              packageIndex
                            )
                          }
                        />
                      </div>
                      <div className="col-3">
                        <input
                          required
                          name="pax"
                          placeholder="pax"
                          type="text"
                          className="form-control"
                          value={packageItem.pax}
                          onChange={(e) =>
                            handleHmCostChange(
                              index,
                              "package",
                              "pax",
                              e.target.value,
                              packageIndex
                            )
                          }
                        />
                      </div>
                      <div className="col-3">
                        <input
                          required
                          name="price"
                          placeholder="price"
                          type="text"
                          className="form-control"
                          value={packageItem.price}
                          onChange={(e) =>
                            handleHmCostChange(
                              index,
                              "package",
                              "price",
                              e.target.value,
                              packageIndex
                            )
                          }
                        />
                      </div>
                      <div className="col-2">
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() =>
                            removeHmCostingFields(index, packageIndex)
                          }
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                  <div className="col-2">
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => addHmCostingFields(index)}
                    >
                      Add More
                    </button>
                  </div>
                </div>
              ))}
              <div className="col-2 mt-3">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={addNewHmCostingObject}
                >
                  Add New Costing Object
                </button>
              </div>
            </div>
          ) : (
            <div
              className="rounded border border-2 p-3 mt-4"
              style={{ backgroundColor: "#f5f5f5" }}
            >
              {costing?.map((form, index) => (
                <div
                  key={index}
                  className="rounded border border-2 p-3 mt-4"
                  style={{ backgroundColor: "#f5f5f5" }}
                >
                  <div className="d-flex row flex-row gap-3 my-2 align-items-end">
                    <div className="d-flex col-8">
                      <input
                        required
                        name="heading"
                        placeholder="heading"
                        type="text"
                        className="form-control"
                        value={form.heading}
                        onChange={(e) =>
                          handleCostChange(index, "heading", "", e.target.value)
                        }
                      />
                      <div className="col-2">
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => removeCostingObject(index)}
                        >
                          Remove Object
                        </button>
                      </div>
                    </div>
                  </div>
                  {(form.package || []).map((packageItem, packageIndex) => (
                    <div
                      key={packageIndex}
                      className="d-flex row flex-row gap-3 my-2 align-items-end"
                    >
                      <div className="col-4">
                        <input
                          required
                          name="name"
                          placeholder="name"
                          type="text"
                          className="form-control"
                          value={packageItem.name}
                          onChange={(e) =>
                            handleCostChange(
                              index,
                              "package",
                              "name",
                              e.target.value,
                              packageIndex
                            )
                          }
                        />
                      </div>
                      <div className="col-2">
                        <input
                          required
                          name="price"
                          placeholder="price"
                          type="text"
                          className="form-control"
                          value={packageItem.price}
                          onChange={(e) =>
                            handleCostChange(
                              index,
                              "package",
                              "price",
                              e.target.value,
                              packageIndex
                            )
                          }
                        />
                      </div>
                      <div className="col-2">
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() =>
                            removeCostingFields(index, packageIndex)
                          }
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                  <div className="col-2">
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => addCostingFields(index)}
                    >
                      Add More
                    </button>
                  </div>
                </div>
              ))}
              <div className="col-2 mt-3">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={addNewCostingObject}
                >
                  Add New Costing Object
                </button>
              </div>
            </div>
          )}
          {/* end of costing dynamic form field */}

          {/* itinerary dynamic form field */}
          <h5 className="mb-1 mt-4 ps-4 py-2 text-white bg-secondary rounded">
            Itinerary
          </h5>
          <div
            className="rounded border border-2 p-3 mt-4"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            {itinerary.map((form, index) => {
              return (
                <div
                  key={index}
                  className="d-flex flex-column gap-3 my-2 align-items-start"
                >
                  <div className="col-md-12">
                    <input
                      required
                      name="heading"
                      placeholder="name"
                      type="text"
                      className="form-control"
                      id="itineraryHeading"
                      onChange={(event) =>
                        handleFormChanges(event, index, "itinerary")
                      }
                      value={form.heading}
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      required
                      name="description"
                      placeholder="description"
                      type="text"
                      className="form-control"
                      id="itineraryDescription"
                      onChange={(event) =>
                        handleFormChanges(event, index, "itinerary")
                      }
                      value={form.description}
                    />
                  </div>
                  <div className="col-md-2">
                    <Link
                      to="#"
                      className="btn btn-danger btn-sm"
                      onClick={() => removeFields(index, "itinerary")}
                    >
                      Remove
                    </Link>
                  </div>
                </div>
              );
            })}
            <Link
              to="#"
              className="btn btn-secondary btn-sm mt-2"
              onClick={() => addFields("itinerary")}
            >
              Add More
            </Link>
          </div>
          {/* end of itinerary dynamic form field */}

          {/* things to carry dynamic form field */}
          <h5 className="mb-1 mt-4 ps-4 py-2 text-white bg-secondary rounded">
            Things to carry
          </h5>
          <div
            className="rounded border border-2 p-3 mt-4"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            {thingsToCarry.map((form, index) => {
              return (
                <div
                  key={index}
                  className="d-flex flex-column gap-3 my-2 align-items-start"
                >
                  <div className="col-md-12">
                    <input
                      required
                      name="name"
                      placeholder="name"
                      type="text"
                      className="form-control"
                      onChange={(event) =>
                        handleFormChanges(event, index, "thingsToCarry")
                      }
                      value={form.heading}
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      required
                      name="description"
                      placeholder="description"
                      type="text"
                      className="form-control"
                      onChange={(event) =>
                        handleFormChanges(event, index, "thingsToCarry")
                      }
                      value={form.description}
                    />
                  </div>
                  <div className="col-md-2">
                    <Link
                      to="#"
                      className="btn btn-danger btn-sm"
                      onClick={() => removeFields(index, "thingsToCarry")}
                    >
                      Remove
                    </Link>
                  </div>
                </div>
              );
            })}
            <Link
              to="#"
              className="btn btn-secondary btn-sm mt-2"
              onClick={() => addFields("thingsToCarry")}
            >
              Add More
            </Link>
          </div>
          {/* end of things to carry  dynamic form field */}

          {/* Testimonials dynamic form field */}
          {/* <h5 className="mb-1 mt-4 ps-4 py-2 text-white bg-secondary rounded">
            Testimonials
          </h5>
          <div
            className="rounded border border-2 p-3 mt-4"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            {testimonials.map((form, index) => {
              return (
                <div
                  key={index}
                  className="d-flex flex-column gap-3 my-2 align-items-start"
                >
                  <div className="col-md-6">
                    <input
                      required
                      name="heading"
                      placeholder="name"
                      type="text"
                      className="form-control"
                      onChange={(event) =>
                        handleFormChanges(event, index, "testimonials")
                      }
                      value={form.heading}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      required
                      name="date"
                      placeholder="date"
                      type="date"
                      className="form-control"
                      onChange={(event) =>
                        handleFormChanges(event, index, "testimonials")
                      }
                      value={form.date}
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      name="description"
                      placeholder="description"
                      type="text"
                      className="form-control"
                      onChange={(event) =>
                        handleFormChanges(event, index, "testimonials")
                      }
                      value={form.description}
                    />
                  </div>
                  <div className="col-md-12">
                    {form.userimage && (
                      <div className="col-4 ">
                        <img
                          className="border rounded rounded-2 bg-white p-1 mb-2"
                          width={100}
                          src={form.userimage}
                          alt="Loading"
                        />
                      </div>
                    )}
                    <input
                      required
                      type="file"
                      name="userimage"
                      accept="image/*"
                      className="form-control"
                      onChange={(event) =>
                        handleFormChanges(
                          event,
                          index,
                          "testimonials",
                          "userimage"
                        )
                      }
                    />
                  </div>
                  <div className="col-md-2">
                    <Link
                      to="#"
                      className="btn btn-danger btn-sm"
                      onClick={() => removeFields(index, "testimonials")}
                    >
                      Remove
                    </Link>
                  </div>
                </div>
              );
            })}
            <Link
              to="#"
              className="btn btn-secondary btn-sm mt-2"
              onClick={() => addFields("testimonials")}
            >
              Add More
            </Link>
          </div> */}
          {/* end of testimonials dynamic form field */}

          {/* notes dynamic form field */}
          {/* <h5 className="mb-1 mt-4 ps-4 py-2 text-white bg-secondary rounded">
            Notes
          </h5>
          <div
            className="rounded border border-2 p-3 mt-4"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            {notes.map((form, index) => {
              return (
                <div key={index} className="d-flex flex-row gap-3 my-2">
                  <div className="col-10">
                    <textarea
                      required
                      name="name"
                      type="text"
                      className="form-control"
                      id="notes"
                      onChange={(event) =>
                        handleFormChanges(event, index, "notes")
                      }
                      value={form.name}
                    />
                  </div>
                  <div className="col-md-2">
                    <Link
                      to="#"
                      className="btn btn-danger btn-sm"
                      onClick={() => removeFields(index, "notes")}
                    >
                      Remove
                    </Link>
                  </div>
                </div>
              );
            })}
            <Link
              to="#"
              className="btn btn-secondary btn-sm mt-2"
              onClick={() => addFields("notes")}
            >
              Add More
            </Link>
          </div> */}
          {/* end of notes dynamic form field */}

          {/* Inclusion dynamic form field */}
          <h5 className="mb-1 mt-4 ps-4 py-2 text-white bg-secondary rounded">
            Inclusion
          </h5>
          <div
            className="rounded border border-2 p-3 mt-4"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            {inclusion.map((form, index) => {
              return (
                <div key={index} className="d-flex flex-row gap-3 my-2">
                  <div className="col-md-10">
                    <textarea
                      required
                      name="name"
                      type="text"
                      className="form-control"
                      id="inclusion"
                      onChange={(event) =>
                        handleFormChanges(event, index, "inclusion")
                      }
                      value={form.name}
                    />
                  </div>
                  <div className="col-md-2">
                    <Link
                      to="#"
                      className="btn btn-danger btn-sm"
                      onClick={() => removeFields(index, "inclusion")}
                    >
                      Remove
                    </Link>
                  </div>
                </div>
              );
            })}
            <Link
              to="#"
              className="btn btn-secondary btn-sm mt-2"
              onClick={() => addFields("inclusion")}
            >
              Add More
            </Link>
          </div>
          {/* end of Inclusion dynamic form field */}

          {/* Exclusion dynamic form field */}
          <h5 className="mb-1 mt-4 ps-4 py-2 text-white bg-secondary rounded">
            Exclusion
          </h5>
          <div
            className="rounded border border-2 p-3 mt-4"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            {exclusion.map((form, index) => {
              return (
                <div key={index} className="d-flex flex-row gap-3 my-2">
                  <div className="col-md-10">
                    <textarea
                      required
                      name="name"
                      type="text"
                      className="form-control"
                      id="exclusion"
                      onChange={(event) =>
                        handleFormChanges(event, index, "exclusion")
                      }
                      value={form.name}
                    />
                  </div>
                  <div className="col-md-2">
                    <Link
                      to="#"
                      className="btn btn-danger btn-sm"
                      onClick={() => removeFields(index, "exclusion")}
                    >
                      Remove
                    </Link>
                  </div>
                </div>
              );
            })}
            <Link
              to="#"
              className="btn btn-secondary btn-sm mt-2"
              onClick={() => addFields("exclusion")}
            >
              Add More
            </Link>
          </div>
          {/* end of Exclusion dynamic form field */}

          {/* must carry dynamic form field */}
          {/* <h5 className="mb-1 mt-4 ps-4 py-2 text-white bg-secondary rounded">
            Must carry
          </h5>
          <div
            className="rounded border border-2 p-3 mt-4"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            {mustCarry.map((form, index) => {
              return (
                <div key={index} className="d-flex flex-row gap-3 my-2">
                  <div className="col-md-10">
                    <textarea
                      required
                      name="name"
                      type="text"
                      className="form-control"
                      onChange={(event) =>
                        handleFormChanges(event, index, "mustCarry")
                      }
                      value={form.name}
                    />
                  </div>
                  <div className="col-md-2">
                    <Link
                      to="#"
                      className="btn btn-danger btn-sm"
                      onClick={() => removeFields(index, "mustCarry")}
                    >
                      Remove
                    </Link>
                  </div>
                </div>
              );
            })}
            <Link
              to="#"
              className="btn btn-secondary btn-sm mt-2"
              onClick={() => addFields("mustCarry")}
            >
              Add More
            </Link>
          </div> */}
          {/* must carry dynamic form field */}

          {/* terms and conditions dynamic form field */}
          <h5 className="mb-1 mt-4 ps-4 py-2 text-white bg-secondary rounded">
            Terms and conditions
          </h5>
          <div
            className="rounded border border-2 p-3 mt-4"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            {tnc.map((form, index) => {
              return (
                <div key={index} className="d-flex flex-row gap-3 my-2">
                  <div className="col-md-10">
                    <textarea
                      required
                      name="name"
                      type="text"
                      className="form-control"
                      onChange={(event) =>
                        handleFormChanges(event, index, "tnc")
                      }
                      value={form.name}
                    />
                  </div>
                  <div className="col-md-2">
                    <Link
                      to="#"
                      className="btn btn-danger btn-sm"
                      onClick={() => removeFields(index, "tnc")}
                    >
                      Remove
                    </Link>
                  </div>
                </div>
              );
            })}
            <Link
              to="#"
              className="btn btn-secondary btn-sm mt-2"
              onClick={() => addFields("tnc")}
            >
              Add More
            </Link>
          </div>
          {/* terms and conditions dynamic form field */}

          {/* Gallery form field */}
          <h5 className="mb-1 mt-4 ps-4 py-2 text-white bg-secondary rounded">
            Gallery
          </h5>
          <div
            className="d-flex align-items-center gap-3 rounded border border-2 p-3 mt-4"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            <div className="col-6 ">
              <input
                required
                type="file"
                className="form-control"
                multiple={true}
                accept="image/*"
                onChange={handleGalleryChange}
              />
            </div>
          </div>
          {/* {galleryFiles.length > 0 && <div className='d-flex flex-wrap border p-2'>
                        {
                            galleryFiles?.map(file => {
                                return (
                                    <div key={file?.name} className='p-2'>
                                        <img width={300} src={URL.createObjectURL(file)} alt="Loading" />
                                    </div>
                                )
                            })
                        }
                    </div>} */}
          {/* end of Gallery form field */}

          {/* Accomodation gallary form field */}
          <h5 className="mb-1 mt-4 ps-4 py-2 text-white bg-secondary rounded">
            Accomodation
          </h5>
          <div
            className="d-flex align-items-center gap-3 rounded border border-2 p-3 mt-4"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            <div className="col-6 ">
              <input
                required
                type="file"
                className="form-control"
                multiple={true}
                accept="image/*"
                onChange={handleAccomodationChange}
              />
            </div>
          </div>
          {/* {accomodationImage?.length > 0 && <div className='d-flex flex-wrap border p-2'>
                        {
                            accomodationImage?.map(file => {
                                return (
                                    <div key={file?.nme} className='p-2'>
                                        <img width={300} src={URL.createObjectURL(file)} alt="Loading" />
                                    </div>
                                )
                            })
                        }
                    </div>} */}
          {/* end of Accomodation gallary form field */}

          {/* youtube videos dynamic form field */}
          <h5 className="mb-1 mt-4 ps-4 py-2 text-white bg-secondary rounded">
            Youtube Videos
          </h5>
          <div
            className="rounded border border-2 p-3 mt-4"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            {videos.map((form, index) => {
              return (
                <div key={index} className="d-flex flex-row gap-3 my-2">
                  <div className="col-10">
                    <input
                      required
                      name="url"
                      type="text"
                      placeholder="add video url here"
                      className="form-control"
                      id="youtubeVideos"
                      onChange={(event) =>
                        handleFormChanges(event, index, "youtubeVideos")
                      }
                      value={form.name}
                    />
                  </div>
                  <div className="col-md-2">
                    <Link
                      to="#"
                      className="btn btn-danger btn-sm"
                      onClick={() => removeFields(index, "youtubeVideos")}
                    >
                      Remove
                    </Link>
                  </div>
                </div>
              );
            })}
            <Link
              to="#"
              className="btn btn-secondary btn-sm mt-2"
              onClick={() => addFields("youtubeVideos")}
            >
              Add More
            </Link>
          </div>
          {videos[0]?.url !== null && (
            <div className="d-flex flex-wrap gap-2 col-12 border p-2">
              {videos[0]?.url !== null &&
                videos?.map((video) => {
                  return (
                    <div className="ratio ratio-16x9" style={{ width: "32%" }}>
                      <iframe
                        src={video.url}
                        title="YouTube video"
                        allowfullscreen
                      ></iframe>
                    </div>
                  );
                })}
            </div>
          )}
          {/* end of youtube videos dynamic form field */}

          <div className="col-12">
            <button type="submit" className="btn mt-3 btn-warning w-100">
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddTrip;
