import React from 'react'
import Layout from '../../components/Admin/AdminLayout'
import AdminHeader from './component/AdminHeader'

const Support = () => {
  return (
    <Layout>
        <AdminHeader title='Customer Support' redirect='/admin-dashboard'/>
    </Layout>
  )
}

export default Support
