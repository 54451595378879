import React, { useEffect, useState, useRef } from "react";
import OtherLayout from "../components/Layout/OtherLayout";
import "../Style/About/About.css";
import about from "../images/About/about.jpg";
import instagram from "../images/About/instagram.png";
import linkedin from "../images/About/linkedin.png";
import OfferAndUpdates from "../components/LandingPage/OfferAndUpdates";
import Slider from "react-slick";
import axios from "axios";
import WeAreHighMountains from "../components/LandingPage/WeAreHighMountains";
import WhyHighMountains from "../components/LandingPage/WhyHighMountains";
import IMAGES from "../images/LandingPage";
// import '../Style/Slider/Slider.css'

const About = () => {
  const arrowRef = useRef();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [teamMembers, setTeamMembers] = useState(null);

  const getAllMember = async (elm) => {
    await axios
      .get(`api/about/get-all-member`)
      .then((res) => {
        setTeamMembers(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    getAllMember();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    rows: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.8,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <OtherLayout background={about}>
        <WhyHighMountains />
        <div className="our-mission">
          <div className="row p-0">
            <div className="col-12 col-sm12 col-md-6 col-lg-6 p-0">
              <img src={IMAGES.about2} alt="" />
            </div>
            <div className="col-12 col-sm12 col-md-6 col-lg-6 jcc">
              <h3 className="fw-bold">Our Mission</h3>
              <p>
                <small>
                  At High Mountains, our mission is to curate unforgettable
                  adventures, especially tailored for group departures. We
                  meticulously hand-pick the finest accommodations and culinary
                  offerings, ensuring every aspect of your journey is
                  exceptional. Drawing from the invaluable lessons and
                  experiences the mountains have bestowed upon us, we are
                  dedicated to sharing these remarkable insights with our
                  clients. Whatever the occasion, be it a group excursion or
                  expedition, High Mountains is committed to crafting memorable
                  experiences that will stay with you long after the journey
                  ends.
                </small>
              </p>
            </div>
          </div>
        </div>
        <div className="our-mission">
          <div className="row p-0">
            <div className="col-12 col-sm12 col-md-6 col-lg-6 jcc">
              <h3 className="fw-bold">Our Vision</h3>
              <p>
                <small>
                  Our vision at High Mountains is to become India's top travel
                  community, offering everything travelers need in one place. We
                  aim to be the go-to platform for all travel-related needs,
                  serving as a trusted resource for adventurers nationwide.
                  We're committed to building a community of responsible
                  travelers who appreciate nature and culture. With High
                  Mountains, we want to make every traveler's journey seamless
                  and fulfilling, providing comprehensive support for trips
                  around the world.
                </small>
              </p>
            </div>
            <div className="col-12 col-sm12 col-md-6 col-lg-6 p-0">
              <img src={IMAGES.about3} alt="" />
            </div>
          </div>
        </div>
        {teamMembers?.length > 0 && (
          <div className="team__member">
            <h3
              className={`fw-bold text-center ${
                windowWidth < 500 ? "fs-5" : "fs-3"
              }`}
            >
              Introducing Our Extraordinary <br /> Dream Team.
            </h3>
            <div className="hr-line mb-4"></div>
            <Slider ref={arrowRef} {...settings}>
              {teamMembers &&
                teamMembers?.map((item, index) => {
                  return (
                    <div className="team-cont">
                      <div className="team mt-5">
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            className="shd p-0"
                            src={`/AboutMemberImg/${item.image}`}
                            alt="loading"
                          />
                        </div>
                        <p>{item.name}</p>
                        <p className="m-0 text-secondary text-center about-small-text fs-5">
                          {item.position}
                        </p>
                        <div className="team-desc">
                          <span>
                            <small>{item?.description}</small>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>
        )}
        <WeAreHighMountains windowWidth={windowWidth} />
      </OtherLayout>
    </>
  );
};

export default About;
