import React from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

const Pagination = ({
  totalInquiry,
  inquiryPerPage,
  setCurrentPage,
  currentPage,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalInquiry / inquiryPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <nav aria-label="Page navigation" className="p-3 border-1">
          <ul className="pagination">
            <button
              className="page-item border-0 bg-transparent me-4"
              disabled={currentPage === 1}
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
            >
              <ArrowCircleLeftIcon />
            </button>
            {pageNumbers?.map((number) => {
              return (
                <div>
                  <button
                    key={number}
                    className="rounded-pill border-0 bg-transparent text-black px-2 m-2 page-item"
                    onClick={() => {
                      setCurrentPage(number);
                    }}
                  >
                    {number}
                  </button>
                </div>
              );
            })}

            <button
              className="page-item border-0 bg-transparent ms-4"
              disabled={
                currentPage === Math.ceil(totalInquiry / inquiryPerPage)
              }
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            >
              <ArrowCircleRightIcon />
            </button>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Pagination;
