import React from "react";

const Spinner = () => {
  return (
    <div className="d-flex flex-column text-center pt-4">
      <h4>500 Internal Server Error</h4>
      <hr className="m-0" />
      <span>nginx</span>
    </div>
  );
};

export default Spinner;
