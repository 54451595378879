import React, { useEffect, useRef, useState } from "react";
import "../../Style/Slider/TripSlider.css";
import Slider from "react-slick";
import { useLocation, useNavigate } from "react-router-dom";
import PlaceSharpIcon from "@mui/icons-material/PlaceSharp";
import CalendarMonthSharpIcon from "@mui/icons-material/CalendarMonthSharp";
import AccessTimeSharpIcon from "@mui/icons-material/AccessTimeSharp";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

const TripSlider = ({ trips }) => {
  const navigate = useNavigate();
  const arrowRef = useRef();

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4.2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 3,
          dots: true,
          infinite: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          className: "center",
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          nextArrow: false,
          prevArrow: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="slider__trip Mon_font">
      <Slider ref={arrowRef} {...settings}>
        {trips?.map((trip, index) => {
          return (
            <div
              key={index}
              className="trip-container"
              onClick={() =>
                navigate(`/trip/${trip?.heading.replace(/\s+/g, "-")}`)
              }
            >
              <div className="trip">
                {trip?.customised === "true" && (
                  <div className="customise-label">Customised</div>
                )}
                <div className="trip-price">
                  <small>Starts From </small>
                  <b>
                    ₹{" "}
                    {trip?.customised === "true"
                      ? parseInt(
                          trip?.hmCosting?.reduce((min, obj) => {
                            const lowestPrice = obj.package?.reduce(
                              (minPackage, packageObj) =>
                                parseInt(packageObj?.price) < minPackage
                                  ? parseInt(packageObj?.price)
                                  : minPackage,
                              Infinity
                            );
                            return lowestPrice < min ? lowestPrice : min;
                          }, Infinity)
                        ).toLocaleString("en-US")
                      : parseInt(
                          trip?.costing?.reduce((min, obj) => {
                            const lowestPrice = obj.package?.reduce(
                              (minPackage, packageObj) =>
                                parseInt(packageObj?.price) < minPackage
                                  ? parseInt(packageObj?.price)
                                  : minPackage,
                              Infinity
                            );
                            return lowestPrice < min ? lowestPrice : min;
                          }, Infinity)
                        ).toLocaleString("en-US")}
                  </b>
                </div>
                <img
                  src={`https://highmountains.in/tripimage/${trip?.image}`}
                  alt=""
                />
                <h5 className="mb-3">{trip?.heading}</h5>
                <div className="trip-det">
                  <p className="">
                    <PlaceSharpIcon className="icon me-1" />
                    {trip?.departure}
                  </p>
                  <p className="justify-content-end">
                    <AccessTimeSharpIcon className="icon me-1" />
                    {trip?.duration}
                  </p>
                  <div className="w-100 d-flex mb-3">
                    {trip?.customised === "false" && (
                      <CalendarMonthSharpIcon className="icon me-1" />
                    )}
                    {trip?.customised === "false" ? (
                      trip?.dates.slice(0, 3).map((item, index, array) => (
                        <span
                          key={index}
                          className={`${index !== 0 && "ms-2"}`}
                        >
                          <small>
                            {new Date(item.date).toLocaleString("default", {
                              day: "numeric",
                              month: "short",
                            })}
                          </small>
                          {index < array.length - 1 && <span>,</span>}
                        </span>
                      ))
                    ) : (
                      <div className="">
                        <CalendarMonthSharpIcon className="icon me-1" />
                        <small>Best Time - {trip?.bestTime}</small>
                      </div>
                    )}
                  </div>
                </div>
                <button className="w-100">View Details</button>
              </div>
            </div>
          );
        })}
      </Slider>
      <div className="slider-btns">
        <div onClick={() => arrowRef.current.slickPrev()}>
          <ArrowBackIosNewSharpIcon className="icon slider-left" />
        </div>
        <div onClick={() => arrowRef.current.slickNext()}>
          <ArrowForwardIosSharpIcon className="slider-right" />
        </div>
      </div>
    </div>
  );
};

export default TripSlider;
