import React, { useEffect, useState } from "react";
import Layout from "../../components/Admin/AdminLayout";
import "../../Style/Admin/AdminDashboard.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import CategoryIcon from "@mui/icons-material/Category";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import BookIcon from "@mui/icons-material/Book";
import CallMadeIcon from "@mui/icons-material/CallMade";

const AdminDashboard = () => {
  const navigate = useNavigate();
  // ===================== DASHBOARD STATES =====================
  const [sales, setSales] = useState(null);
  const [trips, setTrips] = useState(null);
  const [category, setCategory] = useState(null);
  const [inquiry, setInquiry] = useState(null);
  const [bookings, setBookings] = useState(null);

  const AllTrips = async () => {
    await axios
      .get(`api/trips/get-all-trips`)
      .then((res) => {
        setTrips(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCategory = async () => {
    await axios
      .get(`api/category/get-all-category`)
      .then((res) => {
        setCategory(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllInquiry = () => {
    axios({
      method: "get",
      withCredentials: true,
      url: `api/inquiry/get-all-inquiry`,
    })
      .then((res) => {
        let arr = res.data.data;
        setInquiry(arr.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllBookings = async () => {
    try {
      const res = await axios.get("api/booking/get-all-booking");
      if (res.data.success) {
        setBookings(res.data.data.reverse());
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ==================== TOTAL SALES ====================
  const totalSales = bookings?.reduce(
    (acc, obj) => acc + parseInt(obj.advance),
    0
  );
  const [totalSale, setTotalSale] = useState(totalSales);
  useEffect(() => {
    if (trips === null) {
      AllTrips();
    }
    if (category === null) {
      getAllCategory();
    }
    if (inquiry === null) {
      getAllInquiry();
    }
    if (bookings === null) {
      getAllBookings();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (bookings && bookings.length) {
      setTotalSale(
        bookings?.reduce((acc, obj) => acc + parseInt(obj.advance), 0)
      );
    }
  }, [bookings]);

  return (
    <Layout>
      <div className="container-fluid m-0 p-0 Mon_font dashboard__container">
        <h2 className="p-3 bg-dark text-start text-white">Dashboard</h2>
        <div className="row p-3">
          <div className="col-lg-12">
            <div className="cards">
              <div
                className="card"
                onClick={() => {
                  navigate("/booking-list");
                }}
              >
                <div className="text-end">
                  <AttachMoneyIcon className="icon" />
                </div>
                <div className="amount">₹{totalSale || 0}</div>
                <div className="card__name">Total Sales</div>
              </div>
              <div
                className="card"
                onClick={() => {
                  navigate("/all-trips");
                }}
              >
                <div className="text-end">
                  <FlightTakeoffIcon className="icon" />
                </div>
                <div className="amount">{trips?.length}</div>
                <div className="card__name">Total Trips</div>
              </div>
              <div
                className="card"
                onClick={() => {
                  navigate("/add-category");
                }}
              >
                <div className="text-end">
                  <CategoryIcon className="icon" />
                </div>
                <div className="amount">{category?.length}</div>
                <div className="card__name">Total Category</div>
              </div>
              <div
                className="card"
                onClick={() => {
                  navigate("/customer-inquiry");
                }}
              >
                <div className="text-end">
                  <QuestionMarkIcon className="icon" />
                </div>
                <div className="amount">{inquiry?.length}</div>
                <div className="card__name">Total Inquiry</div>
              </div>
              <div
                className="card"
                onClick={() => {
                  navigate("/booking-list");
                }}
              >
                <div className="text-end">
                  <BookIcon className="icon" />
                </div>
                <div className="amount">{bookings?.length || 0}</div>
                <div className="card__name">Total Bookings</div>
              </div>
            </div>
          </div>
        </div>
        <div className="booking-container">
          <div className="bg-light border-bottom border-1 p-3 d-flex justify-content-between align-items-center">
            <h6>
              <b>Recent Orders</b>
            </h6>
            <button
              className="btn btn-sm btn-warning"
              onClick={() => navigate("/booking-list")}
            >
              View All
            </button>
          </div>
          {bookings?.length === 0 ? (
            <div className="text-center">
              <p className="mt-5">No Record Found</p>
            </div>
          ) : (
            <div className="container">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Username</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Trip Name</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {bookings
                  ?.map((item, index) => {
                    return (
                      <tbody>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.username}</td>
                          <td>{item?.email}</td>
                          <td>{item?.mobile}</td>
                          <td>{item?.tripName}</td>
                          <td>
                            <CallMadeIcon
                              onClick={() => {
                                navigate(`/booking-trip/${item?._id}`);
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })
                  .slice(0, 10)}
              </table>
            </div>
          )}
        </div>

        <div className="inquiry-container my-5">
          <div className="bg-light border-bottom border-1 p-3 d-flex justify-content-between align-items-center">
            <h6>
              <b>Recent Inquiries</b>
            </h6>
            <button
              className="btn btn-sm btn-warning"
              onClick={() => navigate("/customer-inquiry")}
            >
              View All
            </button>
          </div>
          {inquiry?.length === 0 ? (
            <div className="text-center">
              <p className="mt-5">No Record Found</p>
            </div>
          ) : (
            <div className="container">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Username</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Trip Name</th>
                  </tr>
                </thead>
                {inquiry
                  ?.map((item, index) => {
                    return (
                      <tbody>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.name}</td>
                          <td>{item?.email}</td>
                          <td>{item?.phone}</td>
                          <td>{item?.message}</td>
                        </tr>
                      </tbody>
                    );
                  })
                  .slice(0, 10)}
              </table>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AdminDashboard;
