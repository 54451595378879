import { message } from "antd";
import axios from "axios";
import { useState } from "react";

const SearchBookingList = ({ setAllBookings, getAllData }) => {
  const [email, setEmail] = useState("");

  // get booking list by sequence id
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("api/booking/get-booking-by-email", {
        email: email,
      });
      if (res.data.success) {
        const button = document.getElementById("close-search-trip-bar");
        message.success(res.data.message);
        setAllBookings(res.data.data.reverse());
      } else {
        message.error(res.data.message);
        const button = document.getElementById("close-search-trip-bar");
        button.click();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="Mon_font">
      <button
        className="py-2 px-3 btn btn-warning btn-sm"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
      >
        <i className="me-2 fa-solid fa-magnifying-glass"></i>Search Booking
      </button>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header bg-warning">
          <h5 id="offcanvasRightLabel">Search Booking By Email</h5>
          <button
            type="button"
            id="close-search-trip-bar"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <hr className="m-0 p-0" />
        <div className="offcanvas-body" style={{ backgroundColor: "#f5f5f5" }}>
          <form className="row gap-3 p-3" onSubmit={onSubmit}>
            <input
              required
              placeholder="booking email"
              className="form-control"
              type="text"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <div className="col-12 text-center">
              <button
                type="submit"
                className="btn btn-warning text-center mt-3 w-50"
              >
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SearchBookingList;
