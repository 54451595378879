import React, { useEffect, useState } from "react";
import "./HomeBlog.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const HomeBlogs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [blogs, setBlogs] = useState(null);

  async function getAllBlogs() {
    try {
      const res = await axios.get("/api/blog/get-all-blog");
      if (res.data.success) {
        setBlogs(res.data.data.reverse());
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllBlogs();
  }, []);

  const calculateReadingTime = (text) => {
    const wordsPerMinute = 200; // Adjust this value based on the average reading speed
    const wordCount = text.trim().split(/\s+/).length;
    const readingTimeMinutes = Math.ceil(wordCount / wordsPerMinute);
    return readingTimeMinutes;
  };

  function handleNavigate(title) {
    navigate(`/blog/${title.replace(/\s+/g, "-")}`);
  }

  return (
    <div
      className={`home-blog-container Mon_font ${
        location.pathname !== "/" && "bg-white px-0"
      }`}
    >
      <h2 className="text-center">Our Blogs</h2>
      <p className="text-center">
        "Discover these enlightening reads to elevate your travel experiences!"
      </p>
      <div className="hr-line"></div>
      <div className="blogss">
        {blogs &&
          blogs
            .map((item, index) => {
              return (
                <div
                  className="recent-blog home-blog"
                  onClick={() => handleNavigate(item?.title)}
                >
                  <div className="recent-blog-img">
                    <img
                      src={`https://highmountains.in/blogImages/${item?.image}`}
                      alt=""
                    />
                  </div>
                  <div className="recent-blog-content">
                    <h6>{item?.title}</h6>
                    <p className="blog-desc">
                      <small>{item?.description}</small>
                    </p>
                    <p className="m-0">
                      <span>
                        {new Date(item?.createdAt).toLocaleString("default", {
                          day: "numeric",
                          month: "long",
                        })}
                      </span>
                      {" | "}
                      <span>
                        {calculateReadingTime(item.description)} min Read
                      </span>
                    </p>
                  </div>
                </div>
              );
            })
            .splice(0, 4)}
        <div className="home-blog-btn">
          <button onClick={() => navigate("/blog")}>View All Blogs</button>
        </div>
      </div>
    </div>
  );
};

export default HomeBlogs;
