import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../Style/Footer/Footer.css";
import axios from "axios";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import PinterestIcon from "@mui/icons-material/Pinterest";

const Footer = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState(null);
  const [destinations, setDestinations] = useState(null);
  const [homeSliders, setHomeSliders] = useState(null);
  const [trips, setTrips] = useState(null);

  // get All Selected Home Page Category
  const getSelectedHomeCategory = () => {
    axios.get(`api/homecategory/get-home-category`).then((res) => {
      setDestinations(res.data.data[0]?.curatedCategory);
      setHomeSliders(res.data.data[0]?.top5HomeCategory);
    });
  };

  async function getAllBlogs() {
    try {
      const res = await axios.get("/api/blog/get-all-blog");
      if (res.data.success) {
        setBlogs(res.data.data.reverse());
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAllTrips() {
    try {
      const res = await axios.get("/api/trips/get-all-trips");
      if (res.data.success) {
        setTrips(res.data.data);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllBlogs();
    getAllTrips();
    getSelectedHomeCategory();
  }, []);

  return (
    <>
      <div className="footer text-white text-center Mon_font">
        <div className="sticky-wa-icon">
          <Link target="_blank" to="https://wa.me/919888823721">
            <WhatsAppIcon className="icon" />
          </Link>
        </div>
        <div className="row">
          <div className="text-start col-12 col-sm-12 col-md-3 col-lg-4 px-4 mb-4">
            <div className="col-12">
              <h6>About Us</h6>
              <p>
                High Mountains is a modern travel community that provides
                customized end-to-end travel packages for destinations in India
                and abroad. Our expertly-designed itineraries encourage group
                travel for like-minded people and include road trips, trekking
                expeditions, corporate trips, and tailored tour packages. We
                prioritize hassle-free traveling and offer top-notch
                accommodation and guided sightseeing at affordable prices. Give
                us your travel dates and get ready for an unforgettable travel
                experience.
              </p>
            </div>
            <div className="col-12">
              <h6>Contact Us</h6>
              <p>
                ADDRESS: High Mountains New Adarsh Market, Near Mall Road,
                Manali, Himachal Pradesh 175131
              </p>
            </div>
            <div className="col-12">
              <h6>Social Media Links</h6>
              <div className="social__media__links">
                <Link
                  target="_blank"
                  to="https://instagram.com/highmountainsin"
                >
                  <InstagramIcon className="icon" />
                </Link>
                <Link target="_blank" to="https://wa.me/919888823721">
                  <WhatsAppIcon className="icon" />
                </Link>
                <Link
                  target="_blank"
                  to="https://in.linkedin.com/company/highmountains"
                >
                  <LinkedInIcon className="icon" />
                </Link>
                <Link
                  target="_blank"
                  to="https://www.youtube.com/@highmountainsin"
                >
                  <YouTubeIcon className="icon" />
                </Link>
                <Link
                  target="_blank"
                  to="https://www.facebook.com/Highmountains.in/"
                >
                  <FacebookIcon className="icon" />
                </Link>
                <Link target="_blank" to="">
                  <PinterestIcon className="icon" />
                </Link>
              </div>
            </div>
          </div>
          <div className="left__border text-start col-12 col-sm-12 col-md-3 col-lg-2 px-4 mb-4">
            <div className="row">
              <div className="col-6 col-sm-6 col-md-12 col-lg-12">
                <h6>{homeSliders && homeSliders[0]}</h6>
                {homeSliders &&
                  trips
                    ?.filter((item) => {
                      return item.category.includes(homeSliders[0]);
                    })
                    .reverse()
                    .map((item, index) => {
                      return (
                        <p
                          onClick={() =>
                            navigate(
                              `/trip/${item?.heading.replace(/\s+/g, "-")}`
                            )
                          }
                          key={index}
                          className="mb-1 footer-blog"
                        >
                          {item?.heading}
                        </p>
                      );
                    })
                    .splice(0, 3)}
              </div>
              <div className="col-6 col-sm-6 col-md-12 col-lg-12">
                <h6>{homeSliders && homeSliders[1]}</h6>
                {homeSliders &&
                  trips
                    ?.filter((item) => {
                      return item.category.includes(homeSliders[1]);
                    })
                    .reverse()
                    .map((item, index) => {
                      return (
                        <p
                          onClick={() =>
                            navigate(
                              `/trip/${item?.heading.replace(/\s+/g, "-")}`
                            )
                          }
                          key={index}
                          className="mb-1 footer-blog"
                        >
                          {item?.heading}
                        </p>
                      );
                    })
                    .splice(0, 3)}
              </div>
              <div className="col-6 col-sm-6 col-md-12 col-lg-12">
                <h6>{homeSliders && homeSliders[2]}</h6>
                {homeSliders &&
                  trips
                    ?.filter((item) => {
                      return item.category.includes(homeSliders[2]);
                    })
                    .reverse()
                    .map((item, index) => {
                      return (
                        <p
                          onClick={() =>
                            navigate(
                              `/trip/${item?.heading.replace(/\s+/g, "-")}`
                            )
                          }
                          key={index}
                          className="mb-1 footer-blog"
                        >
                          {item?.heading}
                        </p>
                      );
                    })
                    .splice(0, 3)}
              </div>{" "}
              <div className="col-6 col-sm-6 col-md-12 col-lg-12">
                <h6>{homeSliders && homeSliders[3]}</h6>
                {homeSliders &&
                  trips
                    ?.filter((item) => {
                      return item.category.includes(homeSliders[3]);
                    })
                    .reverse()
                    .map((item, index) => {
                      return (
                        <p
                          onClick={() =>
                            navigate(
                              `/trip/${item?.heading.replace(/\s+/g, "-")}`
                            )
                          }
                          key={index}
                          className="mb-1 footer-blog"
                        >
                          {item?.heading}
                        </p>
                      );
                    })
                    .splice(0, 3)}
              </div>
            </div>
          </div>
          <div className="left__border text-start col-12 col-sm-12 col-md-3 col-lg-2 px-4 mb-4">
            <div className="row">
              <div className="col-6 col-sm-6 col-md-12 col-lg-12">
                <h6>Destinations</h6>
                {destinations?.map((item, index) => {
                  return (
                    <p
                      onClick={() => navigate(`/category/${item}`)}
                      key={index}
                      className="mb-2 footer-blog"
                    >
                      {item}
                    </p>
                  );
                })}
              </div>
              <div className="col-6 col-sm-6 col-md-12 col-lg-12">
                <h6>Our Blogs</h6>
                {blogs
                  ?.map((item, index) => {
                    return (
                      <p
                        key={index}
                        onClick={() => navigate(`/blog/${item?.title}`)}
                        className="footer-blog mb-2"
                      >
                        {item?.title}
                      </p>
                    );
                  })
                  .slice(0, 4)}
              </div>
            </div>
          </div>
          <div className="left__border quick__links text-start col-12 col-sm-12 col-md-3 col-lg-4 px-4 mb-4">
            <h4>Quick Links</h4>
            <Link to="/about">
              <p>About Us</p>
            </Link>
            <Link to="/contact">
              <p>Contact Us</p>
            </Link>
            <Link to="/blog">
              <p>Blogs</p>
            </Link>
            <Link to="/career">
              <p>Career</p>
            </Link>
            <Link to="/privacy-policy">
              <p>Privacy Policy</p>
            </Link>
            <Link to="/terms-and-conditions">
              <p>Terms & Conditions</p>
            </Link>
            <Link to="/refund-policy">
              <p>Cancellation Policy</p>
            </Link>
          </div>
        </div>
      </div>
      <div className="copyright__text text-center">
        <span>
          <small>
            © Copyright by High Mountains | Designed and Developed by{" "}
            <Link
              target="_blank"
              className="ffca00"
              to="https://aashirdigital.com"
            >
              ~@aashirdigital
            </Link>
          </small>
        </span>
      </div>
    </>
  );
};

export default Footer;
