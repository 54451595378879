import Layout from "../../components/Admin/AdminLayout";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import AdminHeader from "./component/AdminHeader";
import "../../Style/Admin/AllAdminTrips.css";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "./component/Pagination";
import { message } from "antd";
import SearchTrip from "./component/SearchTrip";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

const AllAdminTrips = () => {
  const navigate = useNavigate();
  const [trip, setTrip] = useState(null);
  const [category, setCategory] = useState(null);
  const [uniqueCategories, setUniqueCategories] = useState(null);

  //pagination part
  const [currentPage, setCurrentPage] = useState(1);
  const [tripPerPage, setTripPerPage] = useState(10);
  const indexOfLastTrip = currentPage * tripPerPage;
  const indexOfFirstTrip = indexOfLastTrip - tripPerPage;
  const currentTrips = trip?.slice(indexOfFirstTrip, indexOfLastTrip);
  const totalTrips = trip?.length;

  // to uper case function
  const toUpperCase = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };

  //change category
  const onHandleChangeCategory = (category) => {
    setCategory(category);
  };

  //delete trip function
  const deleteTrip = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      // If the user confirms the deletion
      if (result.isConfirmed) {
        // Call your delete function to remove the data
        axios({
          method: "POST",
          data: {
            id: id,
          },
          withCredentials: true,
          url: `api/trips/delete-trip`,
        })
          .then((res) => {
            AllTrips();
            message.success(res.data.message);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  // get all trips
  const AllTrips = async (elm) => {
    await axios
      .get(`api/trips/get-all-trips`)
      .then((res) => {
        //elm is category
        if (elm) {
          setTrip(
            res.data.data?.filter((trip) => trip?.category?.includes(elm))
          );
        } else {
          setCategory(null);
          let arr = res.data.data;
          setTrip(arr.reverse());
        }

        //set unique category for dropdown category
        let categories = [];
        if (res.data.data) {
          res.data?.data.forEach((trip) => {
            trip.category?.forEach((category) => {
              categories.push(category);
            });
          });
        }
        setUniqueCategories([...new Set(categories)]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (trip === null || category === "All") {
      AllTrips();
    } else {
      AllTrips(category);
    }
    // eslint-disable-next-line
  }, [category]);

  return (
    <Layout>
      <AdminHeader
        title="All Trips"
        redirect="/admin-dashboard"
        btnName="Add Trip"
        btnUrl="/add-trip"
      />
      <div className="container Mon_font px-4 pb-4 Mon_font">
        <div className="py-3 d-flex justify-content-between align-items-center">
          <div>
            <SearchTrip setProduct={setTrip} />
          </div>
          <div className="d-flex gap-2">
            <div className="btn-group d-flex justify-content-center align-items-center gap-2">
              <p className="m-0 px-3 py-2 bg-warning rounded">Total Trips:</p>
              <p className="bg-info m-0 px-3 py-2 rounded">
                {trip ? trip?.length : "0"}
              </p>
            </div>
            <div className="btn-group bg-warning border-0">
              <button
                className="btn btn-secondary dropdown-toggle bg-transparent text-black px-4 py-2 border-0"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {category ? toUpperCase(category) : "All"} Category
              </button>
              <ul className="dropdown-menu">
                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={() => {
                      AllTrips();
                    }}
                  >
                    All Trip
                  </Link>
                </li>
                {uniqueCategories &&
                  uniqueCategories.map((category) => {
                    return (
                      <li>
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={() => {
                            onHandleChangeCategory(category);
                          }}
                        >
                          {category}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className="">
          <div>
            {currentTrips &&
              currentTrips?.map((trip, index) => {
                return (
                  <div key={trip._id} className="position-relative">
                    {
                      <div className="">
                        <div
                          id="all-booking-list"
                          className="rounded border my-4 p-3 d-flex justify-content-between align-items-center"
                          onClick={() => {
                            window.open(
                              `/trip/${(trip?.heading).replace(/\s+/g, "-")}`,
                              "_blank"
                            );
                          }}
                        >
                          <div className="d-flex gap-4">
                            <div className="">
                              <img
                                style={{ border: "8px solid white" }}
                                className="rounded rounded-20"
                                src={`all-trips/tripimage/${trip?.image}`}
                                width={200}
                                alt=""
                              />
                            </div>
                            <div>
                              <h4 className="m-0">
                                {toUpperCase(trip?.heading)}
                              </h4>
                              <hr className="my-2 mx-0" />
                              <p className="m-0 d-flex align-items-center">
                                <FmdGoodIcon className="text-warning" />
                                <p className="m-0">
                                  {toUpperCase(trip?.location)}
                                </p>
                              </p>
                              <p className="m-0 d-flex">
                                <strong className="pe-2"></strong>
                                <p className="m-0">
                                  {toUpperCase(trip?.duration)}
                                </p>
                              </p>
                              {trip?.trending && (
                                <p className="m-0 d-flex">
                                  <strong className="pe-2"></strong>
                                  <p className="m-0 text-danger">
                                    {trip?.trending && "Trending"}
                                  </p>
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="btn-group bg-dark border-0 ">
                            <button
                              className="btn btn-secondary text-white btn-sm dropdown-toggle bg-transparent text-black px-4 py-2 border-0"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                            >
                              Action
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="#"
                                  onClick={(event) => {
                                    deleteTrip(trip._id);
                                    event.stopPropagation();
                                  }}
                                >
                                  Delete
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={`/edit-trip/${trip._id}`}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                >
                                  Edit
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <span className="position-absolute top-0 start-25 translate-middle badge rounded-pill bg-info text-dark">
                          {index + 1}
                        </span>
                      </div>
                    }
                  </div>
                );
              })}
          </div>
        </div>
        {trip && (
          <Pagination
            totalInquiry={totalTrips}
            setCurrentPage={setCurrentPage}
            inquiryPerPage={tripPerPage}
            currentPage={currentPage}
          />
        )}
      </div>
    </Layout>
  );
};

export default AllAdminTrips;
