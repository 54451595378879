import React, { useEffect, useState } from "react";
import "../Style/LandingPage/LandingPage.css";
import Explore from "../components/LandingPage/Explore";
import TripDetails from "../components/TripDetails/TripDetails";
import Layout from "../components/Layout/HomeLayout";
import Testmonials from "../components/LandingPage/Testmonials";
import TalkToExpert from "../components/LandingPage/TalkToExpert";
import PromotionBanner from "../components/LandingPage/PromotionBanner";
import PopularDestination from "../components/LandingPage/PopularDestination";
import axios from "axios";
import SecretSauce from "../components/LandingPage/SecretSauce";
import Faq from "../components/Faq";
import WhyHighMountains from "../components/LandingPage/WhyHighMountains";
import HomeBlogs from "../components/LandingPage/HomeBlogs";
import Hero from "../components/LandingPage/Hero";
import SiteReviews from "../components/SiteReviews";

const LandingPage = () => {
  const [trip, setTrip] = useState(null);
  const [sliders, setSliders] = useState(null);
  const [formLoad, setFormLoad] = useState(false);

  const getHomePageSliders = () => {
    axios.get(`api/homecategory/get-home-category`).then((res) => {
      setSliders(res.data?.data[0]?.top5HomeCategory);
    });
  };
  // get All trips
  const AllTrips = async () => {
    await axios
      .get(`api/trips/get-all-trips`)
      .then((res) => {
        let currentDate = new Date().toISOString().slice(0, 10);
        const currentTrips = res.data.data?.filter((trip) => {
          return trip.dates?.some((date) => date?.date >= currentDate);
        });
        setTrip(currentTrips);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBeforeUnload = () => {
    localStorage.removeItem("isVisited");
  };

  useEffect(() => {
    AllTrips();
    getHomePageSliders();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   const isVisited = localStorage.getItem("isVisited");
  //   if (!isVisited) {
  //     setTimeout(() => {
  //       setFormLoad(true);
  //     }, 15000);
  //     localStorage.setItem("isVisited", "true");
  //   }
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  return (
    <Layout
      trip={trip}
      title="Escape the"
      subTitle="Create beautiful stories with Us.."
    >
      <Hero />
      <Explore />
      {sliders
        ?.map((item, index) => {
          const data = trip?.filter((trip) => trip?.category?.includes(item));
          return (
            data?.length > 0 && (
              <div key={index} className="py-3 py-lg-5">
                <TripDetails data={data} title={item} category={item} />
              </div>
            )
          );
        })
        .slice(0, 1)}
      <PromotionBanner position={"firstBannerArray"} />
      {sliders
        ?.map((item, index) => {
          const data = trip?.filter((trip) => trip?.category?.includes(item));
          return (
            data?.length > 0 && (
              <div key={index} className="py-3 py-lg-5">
                <TripDetails data={data} title={item} category={item} />
              </div>
            )
          );
        })
        .slice(1, 2)}
      <PromotionBanner position={"secondBannerArray"} />
      {sliders
        ?.map((item, index) => {
          const data = trip?.filter((trip) => trip?.category?.includes(item));
          return (
            data?.length > 0 && (
              <div key={index} className="py-3 py-lg-5">
                <TripDetails data={data} title={item} category={item} />
              </div>
            )
          );
        })
        .slice(2, 10)}
      <PopularDestination />
      <TalkToExpert />
      <SiteReviews />
      <Testmonials />
      <WhyHighMountains />
      <SecretSauce />
      <HomeBlogs />
      <Faq />

      {/* COMMENTED OUT  */}
      {/* <WhyTrustUS /> */}
      {/* <div className="p-lg-5 pt-lg-0 mb-4 ">
        <TrendingLocation />
      </div> */}
      {/* <OfferAndUpdates /> */}
      {/* <PopupForm /> */}
      {/* <DemoSlider /> */}
      {/* <div className="container">
        {formLoad && (
          <TimerForm formLoad={formLoad} setFormLoad={setFormLoad} />
        )}
      </div> */}
      {/* <TravelNowPayLater /> */}
    </Layout>
  );
};

export default LandingPage;
