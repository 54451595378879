import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import "../../Style/LandingPage/PromotionBanner.css";
import { Link, useNavigate } from "react-router-dom";

const PromotionBanner = ({ position }) => {
  const [banners, setBanners] = useState(null);
  const navigate = useNavigate();

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  async function getBanner() {
    try {
      const res = await axios.get("/api/banner/get-all-banner");
      if (res.data.success) {
        setBanners(res.data.data.banners);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getBanner();
  }, []);

  const bannerArray = position;

  return (
    <div className={`${banners && "container-fluid promotional__banner"}`}>
      {banners?.[position]?.length > 0 ? (
        <Slider {...settings}>
          {banners[position].map((item, index) => {
            return (
              <Link key={index} to={`category/${item.location}`}>
                <img
                  src={`https://highmountains.in/bannerImages/${item?.image}`}
                  alt="banner"
                />
              </Link>
            );
          })}
        </Slider>
      ) : (
        ""
      )}
    </div>
  );
};

export default PromotionBanner;
