import React from "react";
import "./Faq.css";

const Faq = () => {
  return (
    <React.Fragment>
      <div className="faq-container Mon_font">
        <h2 className="faq">Frequently Asked Question</h2>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          {/* ONE  */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                What is High Mountain?
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                High Mountains is a company all about exciting outdoor
                adventures in high places. We offer lots of different activities
                like hiking, bike trips, SUV rides, 4x4 Winter Expeditions,
                staying in igloos, camping, and more. And it's not just
                mountains - we organise trips all over the world! Whether you
                love adventure or just want a relaxing time with your loved
                ones, we have something for everyone.
              </div>
            </div>
          </div>
          {/* TWO  */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                What makes High Mountains Special?
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                High Mountains stands out for its commitment to providing
                unforgettable adventures in some of the most breathtaking
                landscapes on Earth. Our experienced guides, dedication to
                safety, and focus on delivering exhilarating experiences make us
                the top choice for adventure enthusiasts.
              </div>
            </div>
          </div>
          {/* THREEE  */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                What safety measures do you have in place?
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Safety is our top priority. We adhere to strict safety standards
                and regulations, and all our guides are certified professionals
                with extensive experience in their respective fields. We
                regularly inspect and maintain our equipment, conduct thorough
                risk assessments, and provide comprehensive safety briefings
                before each adventure activity. We have Basic First Aid facility
                in all the group departures and Oxygen cylinders in case of a
                High Altitude Trip.
              </div>
            </div>
          </div>
          {/* FOUR  */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
              >
                How do I book through High Mountains?
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Booking with High Mountains is easy! You can book directly
                through our website or get in touch with our friendly
                reservations team at +919888823721. Simply select your desired
                adventure, choose your preferred dates, and follow the prompts
                to secure your spot. We require a deposit at the time of
                booking, with the remaining balance due before the tour date.
              </div>
            </div>
          </div>
          {/* FIVE  */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingFive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive"
                aria-expanded="false"
                aria-controls="flush-collapseFive"
              >
                What is the minimum age requirement for your tours?
              </button>
            </h2>
            <div
              id="flush-collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingFive"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                The minimum age requirement varies depending on the Trips. Group
                departures are recommended to an age group old 22 years old to
                38 years old However customised trips have no barriers.
              </div>
            </div>
          </div>
          {/* SIX  */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingSix">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSix"
                aria-expanded="false"
                aria-controls="flush-collapseSix"
              >
                What is High Mountains Group Departures?
              </button>
            </h2>
            <div
              id="flush-collapseSix"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingSix"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                High Mountains Group Departures are community trips tailored for
                individuals aged 22 to 38 years old. These trips offer a unique
                opportunity to socialise with like-minded travelers, explore
                stunning landscapes, and immerse yourself in thrilling
                adventures. With a different level of experience, you'll meet
                new friends and enjoy lively evenings filled with camaraderie
                and fun.
              </div>
            </div>
          </div>
          {/* SEVEN  */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingSeven">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSeven"
                aria-expanded="false"
                aria-controls="flush-collapseSeven"
              >
                Do you offer private or customised tours?
              </button>
            </h2>
            <div
              id="flush-collapseSeven"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingSeven"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Yes, we offer private and customised tours for individuals,
                families, and groups. Whether you're celebrating a special
                occasion or have specific preferences, we can tailor an
                adventure experience to meet your needs. Contact us to discuss
                your requirements, and we'll create a personalised itinerary
                just for you.
              </div>
            </div>
          </div>
          {/* EIGHT  */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingEight">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseEight"
                aria-expanded="false"
                aria-controls="flush-collapseEight"
              >
                What is the booking and cancellation policy?
              </button>
            </h2>
            <div
              id="flush-collapseEight"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingEight"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                To book a tour, simply visit our website or contact our
                reservations team. We require a deposit at the time of booking,
                with the remaining balance due before the tour date. Our
                cancellation policy varies depending on the tour, but we strive
                to accommodate changes whenever possible. Please refer to our
                terms and conditions and cancellation policy for more
                information.
              </div>
            </div>
          </div>
          {/* NINE  */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingNine">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseNine"
                aria-expanded="false"
                aria-controls="flush-collapseNine"
              >
                What happens in case of bad weather?
              </button>
            </h2>
            <div
              id="flush-collapseNine"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingNine"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                While we strive to conduct tours as scheduled, safety is
                paramount. In the event of inclement weather or adverse
                conditions, we may need to postpone or cancel the tour. If this
                occurs, we will do our best to reschedule your adventure for a
                later date or provide a full refund. We appreciate your
                understanding and flexibility in such situations.
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Faq;
