const IMAGES = {
  hero: require("../LandingPage/hero.jpg"),
  herobanner: require("../LandingPage/herosection/herobanner.webp"),
  herobanner2: require("../LandingPage/herosection/herobanner2.jpg"),
  rev1: require("../LandingPage/review-img.jpg"),
  rev2: require("../LandingPage/rev-2.jpeg"),
  rev3: require("../LandingPage/rev-3.jpeg"),
  whatsapp: require("../LandingPage/whatsapp.png"),
  thirdparty: require("../LandingPage/thirdparty.jpg"),
  security: require("../LandingPage/security.png"),
  hasslefree: require("../LandingPage/hasslefree.png"),
  cotravel: require("../LandingPage/cotravel.png"),
  hm01: require("../../images/LandingPage/herosection/hm01.jpg"),
  hm02: require("../../images/LandingPage/herosection/hm02.jpg"),
  hm03: require("../../images/LandingPage/herosection/hm03.jpg"),
  hm04: require("../../images/LandingPage/herosection/hm04.jpg"),
  hm05: require("../../images/LandingPage/herosection/hm05.jpg"),
  hmlogo: require("../../images/hmlogo.png"),
  hmlogo2: require("../../images/hmlogo2.png"),
  tte2: require("../../images/LandingPage/tte2.jpeg"),
  why1: require("../../images/LandingPage/1.png"),
  why2: require("../../images/LandingPage/2.png"),
  why3: require("../../images/LandingPage/3.png"),
  why4: require("../../images/LandingPage/4.png"),
  why5: require("../../images/LandingPage/5.png"),
  contact: require("../../images/Contact/contact.webp"),
  contact2: require("../../images/Contact/contact2.webp"),
  hmlogo: require("../../images/LandingPage/logo/hmlogo.png"),
  aboutimg: require("../../images/About/aboutimg.jpg"),
  about2: require("../../images/LandingPage/herosection/herobanner2.jpg"),
  about3: require("../../images/About/about3.webp"),

  t1: require("../../images/LandingPage/t1.png"),
  t2: require("../../images/LandingPage/t2.png"),
  t3: require("../../images/LandingPage/t3.png"),
  t4: require("../../images/LandingPage/t4.png"),
  t5: require("../../images/LandingPage/t5.png"),
  t6: require("../../images/LandingPage/t6.png"),
};

export default IMAGES;
