import React, { useEffect, useState } from "react";
import Layout from "../../components/Admin/AdminLayout";
import AdminHeader from "./component/AdminHeader";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import Pagination from "./component/Pagination";
import SearchBookingList from "./component/SearchBookingList";
import noresult from "../../images/noresult.png";

const BookingList = () => {
  const navigate = useNavigate();
  const [allBookings, setAllBookings] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const bookingPerPage = 20;
  const indexOfLastBooking = currentPage * bookingPerPage;
  const indexOfFirstBooking = indexOfLastBooking - bookingPerPage;
  const currentBookingList = allBookings?.slice(
    indexOfFirstBooking,
    indexOfLastBooking
  );

  const Month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  async function getAllBookings() {
    try {
      const res = await axios.get("/api/booking/get-all-booking");
      if (res.data.success) {
        setAllBookings(res.data.data.reverse());
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDelete() {
    try {
      const res = await axios.get("/api/booking/get-all-booking");
      if (res.data.success) {
        setAllBookings(res.data.data.reverse());
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllBookings();
  }, []);

  return (
    <Layout>
      <AdminHeader title="Trip Booking List" redirect="/admin-dashboard" />
      <div className="container Mon_font px-4 pb-4 Mon_font">
        <div className="py-3 d-flex justify-content-between align-items-center">
          <div>
            <SearchBookingList setAllBookings={setAllBookings} />
          </div>
          <div className="d-flex">
            <div className="btn-group d-flex justify-content-center align-items-center">
              <p className="m-0 px-3 py-2 bg-warning rounded me-2">
                Total Booking:
              </p>
              <p className="bg-info m-0 px-3 py-2 rounded me-2">
                {allBookings ? allBookings?.length : "0"}
              </p>
            </div>
          </div>
        </div>
        <hr />
        {allBookings?.length === 0 ? (
          <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <img src={noresult} alt="" width={150} />
            <h3>No Record Found</h3>
          </div>
        ) : (
          <div className="">
            <div>
              {currentBookingList &&
                currentBookingList?.map((booking, index) => {
                  const date = new Date(booking?.createdAt);
                  const options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  };
                  const formattedDate = date.toLocaleDateString(
                    "en-US",
                    options
                  );
                  return (
                    <div key={booking._id} className="position-relative">
                      {
                        <div>
                          <div
                            id="all-booking-list"
                            className="rounded border my-4 p-3 d-flex justify-content-between align-items-center"
                            onClick={() => {
                              navigate(`/booking-trip/${booking?._id}`);
                            }}
                          >
                            <div>
                              <h5 className="m-0" id="all-booking-list-item">
                                <strong>
                                  Booking ID:{" "}
                                  <mark className="bg-warning p-0 m-0 px-2">
                                    {booking?.bookingId}
                                  </mark>
                                </strong>
                                <strong className="ms-5">
                                  {booking?.tripName}
                                </strong>
                                <strong className="ms-5">
                                  Trip Date: {booking?.tripDate}
                                </strong>
                              </h5>
                              <hr />
                              <div className="" id="all-booking-list-item">
                                <h5 className="m-0 mb-2">
                                  <strong>{booking?.username}</strong>
                                </h5>
                                <p className="m-0">from {booking?.city}</p>
                                <p className="m-0">
                                  <strong>Phone</strong> {booking?.mobile}
                                </p>
                                <p className="m-0">
                                  <strong>Booking Date: </strong>{" "}
                                  {formattedDate}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex gap-3">
                              {/* <button
                                className="btn btn-sm btn-danger"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleDelete();
                                }}
                              >
                                Delete
                              </button> */}
                            </div>
                          </div>
                          <span className="position-absolute top-0 start-25 translate-middle badge rounded-pill bg-info text-dark">
                            {index + 1}
                          </span>
                        </div>
                      }
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        {allBookings?.length === 0 ? (
          ""
        ) : (
          <Pagination
            totalInquiry={allBookings && allBookings?.length}
            setCurrentPage={setCurrentPage}
            inquiryPerPage={bookingPerPage}
            currentPage={currentPage}
          />
        )}
      </div>
    </Layout>
  );
};

export default BookingList;
