import React from "react";
import Slider from "react-slick";
import IMAGES from "../../images/LandingPage";
import "../../Style/LandingPage/SecretSauce.css";

const SecretSauce = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipeToSlide: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="px-5 Mon_font text-center my-5 secret-sauce-container">
      <h2 className="fw-bold">Unveiling Our Special Blend</h2>
      <div className="hr-line mb-4"></div>
      <Slider {...settings}>
        <div className="p-3 secret-sauce">
          <div className="secret">
            <img
              width={120}
              src={IMAGES.thirdparty}
              alt="img"
              className="d-block m-auto my-2"
            />
            <h4>No Third Party Involvement</h4>
            <p>
              HighMountains manage all trips in-house, so there are no
              third-party vendors or false claims. This allows us to take full
              responsibility for your travel plan and give our customers peace
              of mind.
            </p>
          </div>
        </div>
        <div className="p-3 secret-sauce">
          <div className="secret">
            <img
              width={120}
              src={IMAGES.security}
              alt="img"
              className="d-block m-auto my-2"
            />
            <h4>Transparency & Security</h4>
            <p>
              A group of individuals work together as a ground team to monitor
              the condition of each route in real-time. This provides
              transparency and strengthens the security measures in place for
              all travelers who use our itineraries.
            </p>
          </div>
        </div>
        <div className="p-3 secret-sauce">
          <div className="secret">
            <img
              width={120}
              src={IMAGES.cotravel}
              alt="img"
              className="d-block m-auto my-2"
            />
            <h4>Co-travellers Filtering</h4>
            <p>
              We know group trips are more fun with like-minded individuals.
              That's why we have a thorough filtering process in marketing,
              sales, and operations. Carefully selecting travelers who share
              similar interests and memorable experience.
            </p>
          </div>
        </div>
        <div className="p-3 secret-sauce">
          <div className="secret">
            <img
              width={120}
              src={IMAGES.hasslefree}
              alt="img"
              className="d-block m-auto my-2"
            />
            <h4>Hassle free experience</h4>
            <p>
              We have a dedicated team that puts in rigorous efforts to ensure
              your travel experience is top-notch. Our friendly drivers,
              hospitable ground staff, and awesome accommodations aim to create
              an unforgettable memory for you.
            </p>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default SecretSauce;
