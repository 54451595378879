import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "../Style/BookingPage/Booking.css";
import Header from "../components/Header/Header";
import PersonIcon from "@mui/icons-material/Person";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import IMAGES from "../images/LandingPage";
import { message } from "antd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const BookingPage = () => {
  const navigate = useNavigate();
  const [trip, setTrip] = useState(null);
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(null);
  const [allCouponCode, setAllCouponCode] = useState("");
  const [isCouponValid, setIsCouponValid] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [activeTrip, setActiveTrip] = useState(null);
  const [totalAdvancePayment, setTotalAdvancePayment] = useState(0);
  const params = useParams();
  const arrowRef = useRef();

  // =============================== BOOKING DETAILS ===============================
  const [form, setForm] = useState({
    name: "",
    email: "",
    mobile: "",
    city: "",
  });
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [person, setPerson] = useState([]);
  const [user, setUser] = useState({});
  const tripId = params.id;
  const GSTPercent = 5;
  let totalPrice = person.reduce((sum, item) => sum + parseInt(item.price), 0);
  const GST = (totalPrice * GSTPercent) / 100;
  const totalAmount = Math.ceil(totalPrice + GST - discount);
  const advancePayment = Math.ceil((totalPrice * 25) / 100);
  const remains = Math.ceil(totalAmount - advancePayment);

  // =============================== BOOKING DETAILS END ===============================

  const [count, setCount] = useState(0);
  const [orderId, setOrderId] = useState(0);

  const generateOrderId = () => {
    const numbers = "0123456789"; // 10 numbers
    const randomNumbers = Array.from({ length: 7 }, () =>
      numbers.charAt(Math.floor(Math.random() * numbers.length))
    );
    const orderId = "HM" + randomNumbers.join("");
    setOrderId(orderId);
  };

  // Room Sharing
  const removeCart = (item, heading) => {
    setPerson((prevPerson) =>
      prevPerson.filter(
        (cartItem) =>
          cartItem.name !== item.name ||
          cartItem.price !== item.price ||
          cartItem.heading !== heading
      )
    );
  };

  const addCart = (item, heading) => {
    setPerson((prevPerson) => [...prevPerson, { ...item, heading }]);
  };

  const orderData = {
    amount: advancePayment,
    name: form?.name,
    email: form?.email,
    mobile: form?.mobile,
    product: person,
    address: form?.city,
    callback_url: "/api/payment/payment-callback",
    cancel: "/api/payment/payment-cancel",
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    if (
      form?.name === "" ||
      form?.email === "" ||
      form?.mobile === "" ||
      form?.city === "" ||
      person?.length === 0 ||
      date === null
    ) {
      return setError(true);
    }
    setLoading(true);
    const response = await fetch("/api/payment/order", {
      method: "POST",
      body: JSON.stringify(orderData),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const order = await response.json();
    var options = {
      key: "rzp_live_4EwnsjZjWv6uer",
      amount: advancePayment,
      currency: order.currency,
      name: "High Mountains",
      description: activeTrip?.heading,
      image: `${IMAGES.hm01}`,
      order_id: order.id,
      handler: async function (response) {
        const body = {
          ...response,
        };
        const validateRes = await fetch("api/payment/validate", {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const jsonRes = await validateRes.json();
        if (jsonRes.success) {
          try {
            const bookingObject = {
              bookingId: orderId,
              tripName: activeTrip?.heading,
              tripDate: date,
              occupancy: person,
              username: form?.name,
              email: form?.email,
              mobile: form?.mobile,
              city: form?.city,
              totalAmount: totalPrice,
              advance: advancePayment,
              remainingAmount: remains,
              couponDiscount: discount,
            };
            const res = await axios.post(
              "/api/booking/add-booking",
              bookingObject
            );
            if (res.data.success) {
              const paymentObject = {
                orderId: orderId,
                name: form?.name,
                email: form?.email,
                mobile: form?.mobile,
                totalAmount: totalPrice,
                advance: advancePayment,
                remainingAmount: remains,
                tripName: activeTrip?.heading,
              };
              const payment = await axios.post(
                "/api/payment/save-payment",
                paymentObject
              );
              if (payment.data.success) {
                setBookingSuccess(true);
                setLoading(false);
              }
            } else {
              message.error(res.data.message);
            }
          } catch (error) {
            console.log(error);
          }
        }
      },
      prefill: {
        name: `${form?.name}`,
        email: `${form?.email}`,
        contact: `${form?.mobile}`,
      },
      notes: {
        address: "MOBI HAVEN",
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert("Payment Failed");
    });
    rzp1.open();
  };

  const AllTrips = async () => {
    await axios({
      method: "POST",
      data: {
        id: params.id,
      },
      withCredentials: true,
      url: `api/trips/get-trip`,
    })
      .then((res) => {
        setTrip(res.data.data);
        setActiveTrip(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (trip === null) {
      AllTrips();
    }
    generateOrderId();
    // eslint-disable-next-line
  }, []);

  // --------------------- set coupon code -----------------------------
  const setCouponCode = (e) => {
    const data = e.target.value;
    const upperCaseData = data?.toUpperCase();
    setCoupon(upperCaseData);
  };

  const getAllCouponCode = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url: `api/couponCode/get-all-coupon-code`,
    })
      .then((res) => {
        setAllCouponCode(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCouponApply = () => {
    const data = allCouponCode.find((data) => {
      return data.code === coupon;
    });
    if (data) {
      setIsCouponValid(true);
      setDiscount(data.price);
    } else {
      setIsCouponValid(false);
      setDiscount(null);
    }
  };

  useEffect(() => {
    getAllCouponCode();
    // eslint-disable-next-line
  }, []);

  //!==================== NEW CODE ===================
  //! DATES
  const [month, setMonth] = useState(null);
  const [allMonth, setAllMonth] = useState(true);
  const [monthIndex, setMonthIndex] = useState(-1);
  const Dates = () => {
    const filteredDates = allMonth
      ? activeTrip?.dates
      : activeTrip?.dates?.filter(
          (item) =>
            new Date(item?.date).toLocaleString("default", {
              month: "short",
            }) === month
        );
    return (
      <div className="single-short-details dates">
        <div className="batches">
          <h5 className="m-0">Batches</h5>
          <div className="batch-month">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setAllMonth(!allMonth);
                setMonthIndex(-1);
              }}
              className={`${allMonth && "text-decoration-underline"}`}
            >
              All
            </span>
            {[
              ...new Set(
                activeTrip?.dates?.map((item) =>
                  new Date(item?.date).toLocaleString("default", {
                    month: "short",
                  })
                )
              ),
            ]
              .slice(0, 4)
              .map((month, index) => (
                <span
                  style={{ cursor: "pointer" }}
                  className={`${
                    index === monthIndex && "text-decoration-underline"
                  }`}
                  onClick={() => {
                    setMonth(month);
                    setAllMonth(false);
                    setMonthIndex(index);
                  }}
                  key={index}
                >
                  {month}
                </span>
              ))}
          </div>
        </div>
        <hr />
        <div className="batch-date">
          {filteredDates?.map((item, index) => {
            const addDay = activeTrip?.itinerary?.length;
            const startDate = new Date(item?.date);
            const endDate = new Date(startDate);
            const formatDate = startDate.toLocaleString("default", {
              day: "numeric",
              month: "long",
              year: "numeric",
            });
            endDate.setDate(endDate.getDate() + addDay);
            return (
              <p
                style={{ cursor: "pointer" }}
                key={index}
                onClick={() => setDate(formatDate)}
                className={`${date === formatDate && "seldate"}`}
              >
                {startDate.toLocaleString("default", {
                  day: "numeric",
                  month: "short",
                })}{" "}
                to{" "}
                {endDate.toLocaleString("default", {
                  day: "numeric",
                  month: "short",
                })}{" "}
                - Available
              </p>
            );
          })}
        </div>
        <span className="text-danger">
          <small>{error && date === null && "Select Dates"}</small>
        </span>
      </div>
    );
  };

  //! BOOK FORM
  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }
  const [num, setNum] = useState(0);

  return (
    <>
      {loading ? (
        <div className="loading-container">
          <svg class="pl" width="240" height="240" viewBox="0 0 240 240">
            <circle
              class="pl__ring pl__ring--a"
              cx="120"
              cy="120"
              r="105"
              fill="none"
              stroke="#000"
              stroke-width="20"
              stroke-dasharray="0 660"
              stroke-dashoffset="-330"
              stroke-linecap="round"
            ></circle>
            <circle
              class="pl__ring pl__ring--b"
              cx="120"
              cy="120"
              r="35"
              fill="none"
              stroke="#000"
              stroke-width="20"
              stroke-dasharray="0 220"
              stroke-dashoffset="-110"
              stroke-linecap="round"
            ></circle>
            <circle
              class="pl__ring pl__ring--c"
              cx="85"
              cy="120"
              r="70"
              fill="none"
              stroke="#000"
              stroke-width="20"
              stroke-dasharray="0 440"
              stroke-linecap="round"
            ></circle>
            <circle
              class="pl__ring pl__ring--d"
              cx="155"
              cy="120"
              r="70"
              fill="none"
              stroke="#000"
              stroke-width="20"
              stroke-dasharray="0 440"
              stroke-linecap="round"
            ></circle>
          </svg>
        </div>
      ) : bookingSuccess ? (
        <div className="booking-success text-dark">
          <CheckCircleIcon className="icon" />
          <h4 className="text-dark">Booking Successful</h4>
          <p>
            Our team member will confirm the booking and contact you shortly.
            <br /> If you have any query you can message us on{" "}
            <Link target="_blank" to="https://wa.me/919888823721">
              WhatsApp
            </Link>
          </p>
          <button
            className="back-to-home-btn"
            onClick={() => {
              navigate("/");
              setBookingSuccess(false);
            }}
          >
            Back to home
          </button>
        </div>
      ) : (
        <div className="booking-page-container">
          <div className="booking-batch-occupancy">
            <div className="single-short-details book-form">
              <h5>Details</h5>
              <hr />
              <div className="row">
                <div className="mb-3 col-12 col-sm-12 col-md-6 col-lg-6">
                  <input
                    onChange={handleChange}
                    name="name"
                    style={{ borderRadius: "0px" }}
                    placeholder="Name"
                    type="text"
                    className="form-control"
                  />
                  <span className="text-danger">
                    <small>{error && form?.name === "" && "Enter name"}</small>
                  </span>
                </div>
                <div className="mb-3 col-12 col-sm-12 col-md-6 col-lg-6">
                  <input
                    onChange={handleChange}
                    name="mobile"
                    style={{ borderRadius: "0px" }}
                    placeholder="Mobile Number"
                    type="text"
                    className="form-control"
                  />
                  <span className="text-danger">
                    <small>
                      {error && form?.mobile === "" && "Enter mobile"}
                    </small>
                  </span>
                </div>
                <div className="mb-3 col-12 col-sm-12 col-md-6 col-lg-6">
                  <input
                    onChange={handleChange}
                    name="email"
                    style={{ borderRadius: "0px" }}
                    placeholder="Email"
                    type="email"
                    className="form-control"
                  />
                  <span className="text-danger">
                    <small>
                      {error && form?.email === "" && "Enter email"}
                    </small>
                  </span>
                </div>
                <div className="mb-3 col-12 col-sm-12 col-md-6 col-lg-6">
                  <input
                    onChange={handleChange}
                    name="city"
                    style={{ borderRadius: "0px" }}
                    placeholder="Your City"
                    type="text"
                    className="form-control"
                  />

                  <span className="text-danger">
                    <small>{error && form?.city === "" && "Enter city"}</small>
                  </span>
                </div>
              </div>
            </div>

            {/* DATES  */}

            <Dates />

            {/* OCCUPANCY  */}
            <>
              <div className="single-short-details">
                <h5 className="m-0">Occupancy</h5>
                <span>
                  <small>
                    <small>(Room arrangement or room sharing)</small>
                  </small>
                </span>
                <hr />
                {activeTrip?.costing?.map((item, index) => {
                  return (
                    <button
                      className={`btn ${
                        index === num && "text-decoration-underline"
                      }`}
                      onClick={() => setNum(index)}
                      key={index}
                    >
                      {item?.heading}
                    </button>
                  );
                })}
                <hr />
                <div className="d-flex justify-content-center">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="book_th" scope="col">
                          Type
                        </th>
                        <th className="book_th" scope="col">
                          Price (per person)
                        </th>
                        <th
                          className="book_th edit-col text-center"
                          scope="col"
                        >
                          <PersonIcon className="icon" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {activeTrip?.costing[num]?.package?.map((item) => {
                        return (
                          <tr>
                            <th className="book_th" scope="row t_data">
                              {item.name}
                            </th>
                            <td>
                              <b>₹{item.price}</b>
                            </td>
                            <td>
                              <button
                                style={{ border: "0" }}
                                onClick={() => {
                                  removeCart(
                                    item,
                                    activeTrip?.costing[num]?.heading
                                  );
                                }}
                                className={`btn ${
                                  person.filter(
                                    (cartItem) =>
                                      cartItem.name === item.name &&
                                      cartItem.price === item.price &&
                                      cartItem.heading ===
                                        activeTrip?.costing[num]?.heading
                                  ).length === 0
                                    ? "disabled"
                                    : ""
                                } m-0 p-0`}
                              >
                                <RemoveIcon />
                              </button>
                              <span className="mx-2">
                                {
                                  person.filter(
                                    (cartItem) =>
                                      cartItem.name === item.name &&
                                      cartItem.price === item.price
                                  ).length
                                }
                              </span>
                              <button
                                style={{
                                  border: "0",
                                  background: "transparent",
                                }}
                                onClick={() => {
                                  addCart(
                                    item,
                                    activeTrip?.costing[num]?.heading
                                  );
                                }}
                              >
                                <AddIcon />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <span className="text-danger">
                  <small>
                    {error && person.length === 0 && "Select Package"}
                  </small>
                </span>
              </div>
            </>
          </div>
          <div className="price-and-book">
            {/* PRICE */}
            <div className="single-short-details price">
              <h5 className="m-0">
                Amount to Pay <b>₹{totalAmount}</b>
              </h5>
              <div
                className="rounded mt-3 p-3"
                style={{ backgroundColor: "#eee" }}
              >
                <div className="mb-2 d-flex justify-content-between align-items-center">
                  <span>
                    <small>Amount</small>
                  </span>
                  <span>
                    <small>₹{totalPrice}</small>
                  </span>
                </div>
                <div className="mb-2 d-flex justify-content-between align-items-center">
                  <span>
                    <small>GST(5%)</small>
                  </span>
                  <span>
                    <small>+ ₹{GST}</small>
                  </span>
                </div>
                {isCouponValid === true && (
                  <div className="d-flex justify-content-between align-items-center">
                    <span>
                      <small>Coupon</small>
                    </span>
                    <span>
                      <small>- ₹{discount}</small>
                    </span>
                  </div>
                )}
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    <small>
                      <b>Subtotal</b>
                    </small>
                  </span>
                  <span>
                    <small>
                      <b>₹{totalAmount}</b>
                    </small>
                  </span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    <small>
                      <b>Advance</b>
                    </small>
                  </span>
                  <span>
                    <small>
                      <b>₹{advancePayment}</b>
                    </small>
                  </span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    <small>
                      <b>Remaining Payment</b>
                    </small>
                  </span>
                  <span>
                    <small>
                      <b>₹{remains}</b>
                    </small>
                  </span>
                </div>
              </div>
            </div>

            {/* OFFER  */}
            <div className="single-short-details offer">
              <h5>Offers</h5>
              <hr />
              <div className="offer-input-container">
                <input
                  type="text"
                  onChange={setCouponCode}
                  value={coupon}
                  className="form-control"
                  placeholder="Coupon Code"
                  name="coupon"
                />
                <button onClick={onCouponApply}>Apply</button>
              </div>
              {isCouponValid === true && (
                <span className="text-success">
                  <small>Coupon Applied Successful</small>
                </span>
              )}
              {isCouponValid === false && (
                <span className="text-danger">
                  <small>Coupon Not Valid</small>
                </span>
              )}
              <button onClick={handlePayment} className="payment-btn w-100">
                Proceed to Payment
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BookingPage;
