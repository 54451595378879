import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../Style/Header/Header.css";
import BackDrop from "../SideMenu/BackDrop";
import SideMenu from "../SideMenu/SideMenu";
import hmlogo from "../../images/LandingPage/logo/hmlogo.png";
import axios from "axios";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallSharpIcon from "@mui/icons-material/CallSharp";
import MenuIcon from "@mui/icons-material/Menu";
import IMAGES from "../../images/LandingPage";

const Header = () => {
  const navigate = useNavigate();
  const [navbar, setNavbar] = useState(false);
  const [sideMenu, setSidemenu] = useState(false);
  const [domain, setDomain] = useState(false);
  const [allCategory, setAllCategory] = useState(false);
  const [trip, setTrip] = useState(null);
  const [search, setSearch] = useState(null);
  const [headerMenu, setHeaderMenu] = useState(null);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const searchTrip = trip?.filter((obj) =>
    obj.heading.toLowerCase().includes(search?.toLowerCase())
  );

  //get all menu
  const getSelectedHomeCategory = () => {
    axios.get(`api/homecategory/get-home-category`).then((res) => {
      setHeaderMenu(res.data.data[0]?.headerMenu);
    });
  };

  const NavbarBgChange = () => {
    if (window.scrollY >= 60) {
      setNavbar(true);
      setDomain(true);
    } else {
      setNavbar(false);
      setDomain(false);
    }
  };
  window.addEventListener("scroll", NavbarBgChange);

  const toggleMenu = () => {
    setSidemenu(!sideMenu);
  };

  const getAllTrips = async () => {
    const res = await axios.get("api/trips/get-all-trips");
    if (res.data.success) {
      setTrip(res.data.data);
    }
  };

  useEffect(() => {
    getAllTrips();
    getSelectedHomeCategory();
  }, []);

  return (
    <div className="header-main">
      <BackDrop sideMenu={sideMenu} closeSideMenu={toggleMenu} />
      <SideMenu sideMenu={sideMenu} setSidemenu={setSidemenu} />
      <nav className={`navbar active fixed-top`}>
        <div className="logo d-flex align-items-center">
          <div className="hmlogo">
            <img
              onClick={() => navigate("/")}
              width="80px"
              src={IMAGES.hmlogo2}
              alt=""
            />
          </div>
        </div>
        <div className="menu_list d-none d-lg-block">
          <ul className="d-flex">
            {headerMenu &&
              headerMenu?.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={`/category/${item}`}>{item}</Link>
                  </li>
                );
              })}
            <li>
              <Link to={`/about`}>About</Link>
            </li>
            <li>
              <Link to={`/contact`}>Contact</Link>
            </li>
            <li>
              <Link to={`/blog`}>Blog</Link>
            </li>
          </ul>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <Link
            className="wa-icon"
            target="_blank"
            to="https://wa.me/919888823721"
          >
            <span>
              <WhatsAppIcon className="icon text-success fs-1 ms-5" />
            </span>
          </Link>
          <Link className="wa-icon call-icon" to="tel:919888823721">
            <span className="d-flex justify-content-center align-items-center">
              <CallSharpIcon className="icon fs-1 ms-2" />
              <span className="ms-2 text-dark d-none d-md-none d-lg-block">
                <p className="m-0">
                  <small>
                    <small>Call Us</small>
                  </small>
                </p>
                <p className="m-0 mb-2">
                  <b>+91 9888823721</b>
                </p>
              </span>
            </span>
          </Link>
        </div>
        {/* SIDE MENU  */}
        <div className="nav_toggle_button d-lg-none" onClick={toggleMenu}>
          <MenuIcon />
        </div>
      </nav>
    </div>
  );
};

export default Header;
// WHATSAPP BUTTON AND SEARCH BAR
