import React, { useEffect, useState } from "react";
import "../../Style/LandingPage/PopularDestination.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const PopularDestination = () => {
  const navigate = useNavigate();
  const [popularDestination, setPopularDestination] = useState(null);
  const [allCategory, setAllCategory] = useState([]);

  // get All Selected Home Page Category
  const getSelectedHomeCategory = () => {
    axios.get(`api/homecategory/get-home-category`).then((res) => {
      setPopularDestination(res.data.data[0]?.popularCategory);
    });
  };

  // get all category
  const getAllCategory = async () => {
    await axios
      .get(`api/category/get-all-category`)
      .then((res) => {
        if (res.data.data) {
          setAllCategory(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSelectedHomeCategory();
    getAllCategory();
    // eslint-disable-next-line
  }, []);

  return (
    popularDestination?.length &&
    popularDestination?.length > 0 && (
      <div className="Mon_font customised-container d-block m-auto text-center">
        <h2 className="fw-bolder m-0">Customize Your Trip</h2>
        <div className="hr-line mb-3"></div>
        <p>Your Dates + Your Group + Your Budget</p>
        <div className="customised-trips-container">
          {popularDestination &&
            popularDestination.map((item, index) => {
              const category = allCategory.find(
                (obj) => obj.name === popularDestination[index]
              );
              return (
                <div
                  onClick={() =>
                    navigate(`/category/${popularDestination[index]}`)
                  }
                  className="customised-trips"
                  style={{ background: `url(${category?.url})` }}
                >
                  <div className="bckd">
                    <h4>{popularDestination && popularDestination[index]}</h4>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    )
  );
};

export default PopularDestination;
