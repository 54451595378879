import React, { useEffect, useState } from 'react'
import Layout from '../../components/Admin/AdminLayout'
import AdminHeader from './component/AdminHeader'
import { message } from 'antd';
import axios from 'axios';

const AddCouponCode = () => {
    const [loading, setLoading] = useState(false);
    const [inputFields, setInputFields] = useState(null);
    const [allCouponCode, setAllCouponCode] = useState(null);

    // on form value change
    const handleChange = (event)=>{
        setInputFields({ ...inputFields, [event.target.name]: event.target.value })
    }

    //on form submit
    const submitHandler = (e)=>{
        e.preventDefault();
        setLoading(true)
        axios({
            method: "POST",
            data: {
                name: inputFields.name,
                code: inputFields.code,
                price: inputFields.price,
            },
            withCredentials: true,
            url: `api/couponCode/add-coupon-code`,
        }).then((res) => {
            getAllCouponCode()
            setTimeout(() => {
                setLoading(false)
            }, 700);
            message.success(res.data.message)
        }).catch((err) => {
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            console.log(err);
        });
    }

    // on banner delete
    const deleteCouponCode = (id)=>{
        axios({
            method: "POST",
            data: {
                id: id,
            },
            withCredentials: true,
            url: `api/couponCode/delete-coupon-code`,
        }).then((res) => {
            getAllCouponCode()
            message.success(res.data.message)
        }).catch((err) => {
            console.log(err);
        });
    }

    const getAllCouponCode = ()=>{
        axios({
            method: "GET",
            withCredentials: true,
            url: `api/couponCode/get-all-coupon-code`,
        }).then((res) => {
            setAllCouponCode(res.data.data)
            console.log(res.data.data)
        }).catch((err) => {
            console.log(err);
        });
    }
    useEffect(()=>{
        getAllCouponCode()
        // eslint-disable-next-line
    }, [])
  return (
    <Layout>
        <AdminHeader title='Add Coupon Code' redirect='/admin-dashboard' btnName='Add Banner' btnUrl='/promotional-banner' btnName2='Add City Image' btnUrl2='/add-city-image'/>
        <div className="container Mon_font px-4 my-4">
            <form className="d-flex align-items-end justify-content-ceter" style={{ backgroundColor: "#fff" }} onSubmit={submitHandler}>
                <div className="col-4 pe-3">
                    <label htmlFor="coupon-code-name" className="form-label">Add Coupon Name</label>
                    <input placeholder='COUPON NAME' name='name' required type="text" className="form-control" id="coupon-code-name" onChange={(event) => { handleChange(event) }} />
                </div>
                <div className="col-4 pe-3">
                    <label htmlFor="coupon-code-code" className="form-label">Add Coupon Code</label>
                    <input placeholder='COUPON CODE' name='code' required type="text" className="form-control" id="coupon-code-code" onChange={(event) => { handleChange(event) }} />
                </div>
                <div className="col-2 pe-3">
                    <label htmlFor="coupon-code-price" className="form-label">Add Coupon Price</label>
                    <input placeholder='COUPON PRICE' name='price' required type="number" className="form-control" id="coupon-code-price" onChange={(event) => { handleChange(event) }} />
                </div>
                <div className='px3'>
                    <button disabled={loading === true} type='submit' className='btn btn-warning w-100'>{loading? 'Loading...' : 'Save'}</button>
                </div>
            </form>
        </div>
        <hr />

        {/* all banners */}
        <div className="container Mon_font px-4 pb-4">
            <table className='table table-light table-bordered w-100 m-0 mt-4'>
                <thead className='p-2 py-3 table-secondary'>
                    <tr>
                        <th scope="col" className='py-2'>#</th>
                        <th scope="col" className='py-2'>Date</th>
                        <th scope="col" className='py-2'>Name</th>
                        <th scope="col" className='py-2'>Code</th>
                        <th scope="col" className='py-2'>Price</th>
                        <th scope="col" className='py-2'>Action</th>
                    </tr>
                </thead>
                <tbody>
                        {allCouponCode?.map((couponCode, index)=>{
                            const createdAt = couponCode.createdAt;
                            const date = new Date(createdAt);
                            const formattedDate = date.toLocaleString('default', { day: 'numeric', month: 'long' });
                        return <tr className='m-2' key={couponCode._id}>
                                <td className=''>
                                    <p className='px-2 py-3 border-1 m-0 border-dark'><strong>{index + 1}</strong></p>
                                </td>
                                <td className='text-dark'><p className='px-2 py-3 border-1 m-0 border-dark'>{(formattedDate)}</p></td>
                                <td className='text-dark'><p className='px-2 py-3 border-1 m-0 border-dark'>{(couponCode.name)}</p></td>
                                <td className='text-dark bg-warning d-flex justify-content-center align-items-center'><p className='px-2 py-3 border-1 m-0 border-dark'><strong>{(couponCode.code).toUpperCase()}</strong></p></td>
                                <td className='text-danger'><p className='px-2 py-3 border-1 m-0 border-dark'>{(couponCode.price)}</p></td>
                                <td className='ps-4'>
                                    <div className="flex gap-2 ">
                                        <i className="fa-sharp fa-solid fa-trash cursor-pointer px-2 py-3 border-1 m-0 border-dark"  onClick={() => deleteCouponCode(couponCode._id)}></i>
                                    </div>
                                </td>
                        </tr>
                        })
                        }
                </tbody>
            </table>
        </div>
    </Layout>
  )
}

export default AddCouponCode
