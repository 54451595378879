import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TalkToExpert from "../components/LandingPage/TalkToExpert";
import Testmonials from "../components/LandingPage/Testmonials";
import OtherLayout from "../components/Layout/OtherLayout";
import axios from "axios";
import noresult from "../images/noresult.png";
import "../Style/CategoryListPage/Category.css";

const CustomisedListPage = () => {
  const [category, setCategory] = useState(null);
  const [categoryBackground, setCategoryBackground] = useState(null);
  const [month, setMonth] = useState(null);
  const [duration, setDuration] = useState(null);
  const [trending, setTrending] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const paramscategory = (params?.category).replace(/-/g, " ");
  document.title = `High Mountains - ${paramscategory}`;

  // get all category
  const getAllCategory = async () => {
    await axios
      .get(`api/category/get-all-category`)
      .then((res) => {
        const image = res.data.data?.filter((category) => {
          let data;
          if (category.name === paramscategory) {
            data = category;
          }
          return data;
        });
        setCategoryBackground(image[0]);
        console.log(categoryBackground);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios({
      method: "get",
      withCredentials: true,
      url: `api/cityImages/get-all-city-image`,
    })
      .then((res) => {
        let arr = res.data.data;
        const data = arr.find((item) => item.city === paramscategory);
        setTrending(data.image);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, [paramscategory]);

  useEffect(() => {
    if (paramscategory) {
      getAllCategory();
    }
    // eslint-disable-next-line
  }, [paramscategory]);

  // ==================== UNIQUE MONTH AND THEN FILTERING WITH STATE MONTH
  const allDates = [];
  const box = category?.map((obj) =>
    obj.dates.map((dateObj) => allDates.push(dateObj))
  );
  const uniqueMonths = Array.from(
    new Set(
      allDates.map((obj) =>
        new Date(obj.date).toLocaleString("default", { month: "long" })
      )
    )
  ).sort(
    (a, b) =>
      new Date(Date.parse("01 " + a + " 2000")) -
      new Date(Date.parse("01 " + b + " 2000"))
  );
  const filterMonthData = category?.filter((obj) => {
    return obj.dates.some((item) => {
      const dateObj = new Date(item.date);
      return dateObj.toLocaleString("default", { month: "long" }) === month;
    });
  });
  // ==================== UNIQUE MONTH AND THEN FILTERING WITH STATE MONTH

  // ==================== FILTERING WITH STATE DURATION
  const filterTrip = category?.filter((item) =>
    item.duration?.includes(duration)
  );
  // ==================== FILTERING WITH STATE DURATION

  // get All trips
  const AllTrips = async () => {
    await axios
      .get(`api/trips/get-all-trips`)
      .then((res) => {
        setCategory(
          res.data.data?.filter((trip) => {
            return (
              trip.location?.includes(paramscategory) ||
              trip.category?.includes(paramscategory)
            );
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    AllTrips();
    // eslint-disable-next-line
  }, [paramscategory]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [monthFilter, setMonthFilter] = useState("all");
  const [durationFilter, setDurationFilter] = useState("all");
  // Map the month options based on the unique month values in the category state data
  const monthOptions = [
    ...new Set(
      category?.flatMap((item) =>
        item.dates.map((date) => date.date.slice(5, 7))
      )
    ),
  ].map((month) => <option value={month}>{monthNames[month - 1]}</option>);

  // Map the duration options based on the unique duration values in the category satte data
  const durationOptions = [...new Set(category?.map((item) => item.duration))]
    .sort((a, b) => {
      const [nightsA, daysA] = a.split("N-");
      const [nightsB, daysB] = b.split("N-");

      if (parseInt(nightsA) !== parseInt(nightsB)) {
        return parseInt(nightsA) - parseInt(nightsB);
      } else {
        return parseInt(daysA) - parseInt(daysB);
      }
    })
    .map((duration) => <option value={duration}>{duration}</option>);

  function filterData() {
    return category?.filter((item) => {
      if (monthFilter !== "all") {
        // Check if any date in the item matches the selected month
        if (
          !item.dates.some(
            (dateObj) =>
              new Date(dateObj.date).getMonth() === parseInt(monthFilter) - 1
          )
        ) {
          return false;
        }
      }
      if (durationFilter !== "all" && item.duration !== durationFilter) {
        return false;
      }
      return true;
    });
  }

  const filteredData = filterData();

  // const background = categoryBackground !== null ? categoryBackground.url :

  return (
    <>
      {
        <OtherLayout
          background={categoryBackground?.url || trending}
          title={
            paramscategory.charAt(0).toUpperCase() + paramscategory.slice(1)
          }
        >
          <div className="category__cont container-fluid px-lg-5 px-4 Mon_font my-5">
            {category?.length > 0 && (
              <div>
                <h2 className="text-start category__heading mb-4 fw-bold">
                  Our{" "}
                  {paramscategory.charAt(0).toUpperCase() +
                    paramscategory.slice(1)}{" "}
                  packages
                </h2>
                {/* =============== MONTH FILTER ===============   */}
                <select
                  id="monthFilter"
                  value={monthFilter}
                  onChange={(e) => setMonthFilter(e.target.value)}
                >
                  <option onClick={() => setMonth(null)} value="all">
                    Month
                  </option>
                  {monthOptions}
                </select>

                {/* =============== DURATION FILTER ===============   */}
                <select
                  id="durationFilter"
                  value={durationFilter}
                  onChange={(e) => setDurationFilter(e.target.value)}
                >
                  <option onClick={() => setDuration(null)} value="all">
                    Duration
                  </option>
                  {durationOptions}
                </select>
              </div>
            )}
            <div className="row">
              {filteredData?.length === 0 ? (
                <div className="text-center">
                  <img src={noresult} alt="no-result" />
                  <h5 className="text-secondary text-center Mon_font">
                    No Trips Found!
                  </h5>
                </div>
              ) : (
                filteredData?.map((item) => {
                  return (
                    <>
                      {
                        <div
                          key={item._id}
                          onClick={() =>
                            navigate(
                              `/trip/${(item?.heading).replace(/\s+/g, "-")}`
                            )
                          }
                          className="text-center trip__container col-12 col-sm-12 col-lg-4 col-md-6 mx-lg-4"
                        >
                          <div
                            className="trip__img__container"
                            style={{ background: `url(${item.image}` }}
                          ></div>
                          <div className="trips text-start other__content">
                            <div className="d-flex justify-content-between">
                              <p className="date__time p-0 mt-1">
                                <i className="fa-solid fa-location-dot me-1"></i>
                                {item.departure}
                              </p>
                              <p className="date__time p-0 mt-1">
                                <i className="fa-regular fa-clock me-1"></i>
                                {item.duration}
                              </p>
                            </div>
                            <h5 className="trip__title my-1">{item.heading}</h5>
                            <span className="m-0 text-secondary">
                              <small>Starts at</small>
                              <h5 className="ms-2 d-inline">
                                ₹
                                {item.costing.reduce((min, obj) => {
                                  return obj.price < min ? obj.price : min;
                                }, Infinity)}
                                /-
                              </h5>
                            </span>
                          </div>
                        </div>
                      }
                    </>
                  );
                })
              )}
            </div>
          </div>
          <TalkToExpert />
          <div className="my-5">
            <Testmonials />
          </div>
        </OtherLayout>
      }
    </>
  );
};

export default CustomisedListPage;
