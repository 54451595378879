import { message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Admin/AdminLayout";
import AdminHeader from "./component/AdminHeader";

const AdminEditBlog = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);
  const [blog, setBlog] = useState(null);
  const [image, setImage] = useState(null);
  console.log(image);

  const getBlog = async (elm) => {
    await axios
      .post(`api/blog/get-blog`, { id: params.id })
      .then((res) => {
        setBlog(res.data.data);
        setImage(res.data.data.image);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (blog === null) {
      getBlog();
    }
    // eslint-disable-next-line
  }, []);

  const submit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", params.id);
    formData.append("title", blog?.title);
    formData.append("author", blog?.author);
    formData.append("image", image);
    formData.append("description", blog?.description);
    setLoading(true);
    try {
      const res = await axios.post("/api/blog/edit-blog", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/admin-blog");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    setBlog({ ...blog, [event.target.name]: event.target.value });
  };

  return (
    <div>
      <Layout>
        <AdminHeader title="Blog" redirect="/admin-dashboard" />
        <div className=" Mon_font px-3 m-0 pb-4">
          <form
            className="row g-3 m-0 px-2 py-3 rounded border border-2"
            style={{ backgroundColor: "#f5f5f5" }}
            onSubmit={submit}
          >
            <div className="col-3 mb-3">
              {image && (
                <img
                  width="100px"
                  className="mb-3"
                  src={`blogImages/${image}`}
                  alt=""
                />
              )}
              <input
                aria-label="Select Image"
                className="form-control"
                type="file"
                accept=".jpg, .jpeg, .png"
                name="image"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>
            <div className="col-12 m-0 ">
              <input
                className="form-control"
                value={blog?.title}
                type="text"
                name="title"
                required
                placeholder="blog title"
                onChange={handleChange}
              />
            </div>
            <div className="col-12 ">
              <input
                className="form-control"
                value={blog?.author}
                type="text"
                name="author"
                required
                placeholder="author name"
                onChange={handleChange}
                maxLength={50}
              />
            </div>
            <div className="col-12 ">
              <textarea
                rows="20"
                style={{ resize: "none" }}
                className="form-control"
                value={blog?.description}
                type="text"
                name="description"
                required
                placeholder="description"
                onChange={handleChange}
              />
            </div>
            <div className="col-2 d-flex justify-content-center align-items-center">
              <button type="submit" className="btn btn-warning w-100">
                {loading ? "Loading..." : "Save"}
              </button>
            </div>
          </form>
        </div>
      </Layout>
    </div>
  );
};

const style = {
  fontSize: "13px",
};

export default AdminEditBlog;
