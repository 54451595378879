import React, { useEffect, useRef, useState } from "react";
import "../../Style/LandingPage/Testimonial.css";
import Slider from "react-slick";
import axios from "axios";
import { message } from "antd";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

const Testmonials = () => {
  const arrowRef = useRef();
  const [allTestimonial, setAllTestimonial] = useState(null);

  // All Testimonial
  const getAllTestimonials = async (e) => {
    const res = await axios.get("api/testimonial/get-all-testimonials");
    if (res.data.success) {
      setAllTestimonial(res.data.data);
    } else {
      message.error("Failed to fetched the data at this time");
    }
  };

  useEffect(() => {
    getAllTestimonials();
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    allTestimonial &&
    allTestimonial?.length > 0 && (
      <div className="Mon_font testimonials__cont">
        <div className="text-center ms-2">
          <h2 className="">Travel Memories </h2>
          <span>(HEAR FROM TRAVELERS LIKE YOU)</span>
        </div>
        <div className="testimonials">
          <Slider ref={arrowRef} {...settings}>
            {allTestimonial &&
              allTestimonial?.map((item, ind) => {
                return (
                  <div key={ind} className="testi text-center p-2 p-lg-4">
                    <iframe
                      src={item?.link}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                );
              })}
          </Slider>
          {allTestimonial && allTestimonial?.length > 1 && (
            <div className="testimonial-slider-btns">
              <div onClick={() => arrowRef.current.slickPrev()}>
                <ArrowBackIosNewSharpIcon className="icon test-slider-left" />
              </div>
              <div onClick={() => arrowRef.current.slickNext()}>
                <ArrowForwardIosSharpIcon className="icon test-slider-right" />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default Testmonials;
