import axios from "axios";
import React, { useEffect, useState } from "react";
import OtherLayout from "../components/Layout/OtherLayout";
import PopupForm from "../components/PopupForm";
import { Link, useNavigate, useParams } from "react-router-dom";
import CareerModal from "../components/Career/CareerModal";
import career from "../images/blogs/career2.jpg";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./Careeer.css";

const Career = () => {
  const [expertForm, setExpertForm] = useState(false);
  const [allCareer, setAllCareer] = useState(null);
  const [id, setId] = useState(null);
  const [data, setData] = useState(null);
  const [responsibility, setResponsibility] = useState(null);
  const [requirement, setRequirement] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.title) {
      document.title = `High Mountains - ${params.title}`;
      const singleCareerData = allCareer?.filter((career) => {
        return career._id === id;
      });
      if (singleCareerData) {
        setData(singleCareerData[0]);
        setResponsibility(singleCareerData[0]?.responsibility.split("\n"));
        setRequirement(singleCareerData[0]?.requirement.split("\n"));
      }
    }
    // eslint-disable-next-line
  }, [id]);

  const getAllCareer = async (elm) => {
    await axios
      .get(`api/career/get-all-career`)
      .then((res) => {
        let arr = res.data.data;
        setAllCareer(arr.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (allCareer === null) {
      getAllCareer();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data === null) {
      navigate("/career");
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <OtherLayout background={career}>
      {params.title && data ? (
        <div className="px-3 my-5 Mon_font container career-container">
          <div className=" Mon_font px-3 m-0 py-4 shd border">
            <div className="">
              <h4 className="fw-bold">
                <ArrowBackIcon
                  className="me-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/career");
                  }}
                />
                {data?.title}
              </h4>
              <hr />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="fs-6">{data?.location}</h6>
              <h6 className="fw-bold p-2 bg-warning rounded-4 fs-6 px-3">
                {data?.time}
              </h6>
            </div>
          </div>

          <div className=" Mon_font m-0 py-4 shd border px-0 mt-4">
            <div className="">
              <h5 className="px-3 fw-bold fs-6">
                Role: <span className="fw-normal fs-6">{data?.role}</span>
              </h5>
              <hr />
            </div>
            <div className="">
              <h5 className="px-3 fw-bold fs-6">
                Skills:{" "}
                <span className="fw-normal fs-6" style={{ lineHeight: "2" }}>
                  {data?.skills}
                </span>
              </h5>
              <hr />
            </div>
            <div className="">
              <h5 className="px-3 fw-bold fs-6">
                Address: <span className="fw-normal fs-6">{data?.address}</span>
              </h5>
              <hr />
            </div>
            <div className="">
              <h5 className="px-3 fw-bold fs-6">
                Experience:{" "}
                <span className="fw-normal fs-6">{data?.experience}</span>
              </h5>
              <hr />
            </div>
            <div className="">
              <h5 className="px-3 fw-bold fs-6">
                Salary: <span className="fw-normal fs-6">{data?.salary}</span>
              </h5>
            </div>
          </div>
          <div className=" Mon_font px-0 m-0 py-4 shd border mt-4">
            <div className="">
              <h5 className="px-3 fw-bold fs-6">Responsibilities</h5>
              <hr />
            </div>
            {responsibility?.map((data) => {
              return (
                <>
                  <div key={data}>
                    <h5 className="px-3 fw-bold">
                      <span
                        className="fw-normal"
                        style={{
                          fontSize: `${windowWidth < 554 ? "13px" : "17px"}`,
                        }}
                      >
                        - {data}
                      </span>
                    </h5>
                  </div>
                </>
              );
            })}
          </div>
          <div className=" Mon_font px-0 m-0 py-4 shd border mt-4 mb-4">
            <div className="">
              <h5 className="px-3 fw-bold fs-6">Requirement</h5>
              <hr />
            </div>
            {requirement?.map((data) => {
              return (
                <>
                  <div key={data}>
                    <h5 className="px-3 fw-bold">
                      <span
                        className="fw-normal"
                        style={{
                          fontSize: `${windowWidth < 554 ? "13px" : "17px"}`,
                        }}
                      >
                        - {data}
                      </span>
                    </h5>
                  </div>
                </>
              );
            })}
          </div>
          <div className="career-apply-button text-center">
            <button
              className="btn"
              data-bs-toggle="modal"
              data-bs-target="#career-modal"
            >
              Apply Now
            </button>
          </div>
          <CareerModal careerData={data} />
        </div>
      ) : (
        <div>
          <div className="Mon_font container my-5 why-work-with-us">
            <h3 className={`fw-bold text-center`}>Why work with us?</h3>
            <div
              className={`text-center ${windowWidth < 992 ? "mb-4" : "mb-4"}`}
            >
              <div className="hr-line text-center"></div>
            </div>
            <div
              className={`d-flex gap-4 my-3 ${
                windowWidth < 992 ? "flex-wrap" : null
              } `}
            >
              <div className="d-flex mx-2">
                <div>
                  <TravelExploreIcon className="icon" />
                </div>
                <div className="p-3 pb-0 px-2">
                  <h5
                    className={`fw-bold ${windowWidth < 992 ? "fs-6" : "fs-5"}`}
                  >
                    Global Exposure and Cultural Immersion:
                  </h5>
                  <p className="mt-3 fs-6">
                    <small>
                      Joining our travel agency opens doors to a dynamic and
                      culturally rich work environment. As we cater to a diverse
                      range of destinations, you'll have the opportunity to
                      immerse yourself in various cultures, gaining firsthand
                      knowledge and experiences that go beyond typical office
                      settings. This global exposure not only enhances your
                      understanding of different lifestyles but also enriches
                      your personal and professional growth.
                    </small>
                  </p>
                </div>
              </div>
              <div className="d-flex mx-2">
                <div>
                  <LocalLibraryIcon className="icon" />
                </div>
                <div className="p-3 pb-0 px-2">
                  <h5
                    className={`fw-bold ${windowWidth < 992 ? "fs-6" : "fs-5"}`}
                  >
                    Continuous Learning and Development:
                  </h5>
                  <p className="mt-3 fs-6">
                    <small>
                      We believe in investing in our team members' growth.
                      Working with us means having access to continuous learning
                      and development programs tailored to the travel industry.
                      Whether it's staying updated on the latest travel trends,
                      acquiring new skills in customer service, or expanding
                      your knowledge of international regulations, we provide
                      the resources and support to ensure you stay at the
                      forefront of the ever-evolving travel landscape.
                    </small>
                  </p>
                </div>
              </div>
              <div className="d-flex mx-2">
                <div>
                  <Diversity1Icon className="icon" />
                </div>
                <div className="p-3 pb-0 px-2">
                  <h5
                    className={`fw-bold ${windowWidth < 992 ? "fs-6" : "fs-5"}`}
                  >
                    Passionate and Supportive Team:
                  </h5>
                  <p className="mt-3 fs-6">
                    <small>
                      Our travel agency thrives on the passion of individuals
                      who share a love for exploration and adventure. When you
                      join us, you become part of a supportive team that
                      encourages creativity, collaboration, and a genuine
                      enthusiasm for travel. We foster a positive work culture
                      where your ideas are valued, and your contribution plays a
                      significant role in shaping memorable experiences for our
                      clients. Together, we create a workplace that inspires
                      passion and a sense of fulfillment in helping others
                      embark on unforgettable journeys.
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 my-5 Mon_font container">
            <h3 className={`fw-bold text-center`}>WE ARE HIRING!</h3>
            <div
              className={`text-center ${windowWidth < 992 ? "mb-4" : "mb-4"}`}
            >
              <div className="hr-line text-center"></div>
            </div>
            <h5
              className={`fw-bold ${
                windowWidth < 992 ? "fs-6" : "fs-5"
              } text-center`}
            >{`(Internship Candidates can also apply)`}</h5>
            {allCareer?.length === 0 ? (
              "no record found"
            ) : (
              <div className="row p-4 mt-3">
                {allCareer &&
                  allCareer?.map((career) => {
                    const date = new Date(career?.createdAt);
                    const month = date.toLocaleString("default", {
                      month: "long",
                    });
                    const day = date.toLocaleString("default", {
                      day: "numeric",
                    });
                    return (
                      <>
                        <div
                          key={career?._id}
                          className="mb-3 col-12 col-sm-12 col-md-6 col-lg-4"
                        >
                          <div
                            className="card border-0 career-card"
                            onClick={() => {
                              navigate(`/career/${career.title}`);
                              setId(career._id);
                            }}
                          >
                            <div className="p-3">
                              <p className="p-0 m-0 fw-bold fs-5">
                                {career.title}
                              </p>
                              <p className="p-0 m-0 mt-2 fs-6">
                                {career.location}
                              </p>
                            </div>
                            <div className="card-body d-flex justify-content-between mt-4">
                              <Link to="#" className="fw-bold text-warning">
                                View and Apply
                              </Link>
                              <p
                                className="m-0 bg-warning d-flex align-items-center px-3"
                                style={{
                                  fontSize: "13px",
                                  borderRadius: "12px",
                                  fontWeight: 400,
                                }}
                              >
                                {career.time}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      )}
      {expertForm && (
        <PopupForm expertForm={expertForm} setExpertForm={setExpertForm} />
      )}
    </OtherLayout>
  );
};

export default Career;
