import React from "react";
import BlackHeader from "../components/Header/BlackHeader";
import Footer from "../components/Footer/Footer";
import "../Style/Terms&Policy/Style.css";
import Layout from "../components/Layout/HomeLayout";

const Refund = () => {
  return (
    <Layout>
      <div className="refund__page Mon_font container my-5">
        <h1 className="mb-3 text-uppercase text-start fs-3 fs-sm-3 fs-lg-4">
          Cancellation Policy
        </h1>
        <div className="fs-5 fs-sm-5 fs-lg-4">
          <h4 className="text-uppercase fs-sm-6 fs-6 fs-lg-4">
            NO REFUND SHALL BE MADE WITH RESPECT TO THE INITIAL BOOKING AMOUNT
            FOR ANY CANCELLATIONS. HOWEVER,
          </h4>
        </div>
        <p>
          1. If cancellations are made 30 days before the start date of the
          trip, 50% of the trip cost will be charged as cancellation fees.
        </p>
        <p>
          2. If cancellations are made 15-30 days before the start date of the
          trip, 75% of the trip cost will be charged as cancellation fees.
        </p>
        <p>
          3.If cancellations are made within 0-15 days before the start date of
          the trip, 100% of the trip cost will be charged as cancellation fees.
        </p>
        <p>
          4. In the case of unforeseen weather conditions or government
          restrictions, certain activities may be canceled and in such cases,
          the operator will try his best to provide an alternate feasible
          activity. However, no refund will be provided for the same.
        </p>
      </div>
    </Layout>
  );
};

export default Refund;
