import { message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

const Website = () => {
  const [website, setWebsite] = useState(null);
  const [loading, setLoading] = useState(null);
  const [curatedCategory, setCuratedCategory] = useState(null);
  const [top5HomeCategory, setTop5HomeCategory] = useState(null);
  const [popularCategory, setPopularCategory] = useState(null);
  const [allCategory, setAllCategory] = useState([]);
  const [headerMenu, setHeaderMenu] = useState([]);

  // get all category
  const getAllCategory = async () => {
    await axios
      .get(`api/category/get-all-category`)
      .then((res) => {
        let categories = [];
        if (res.data.data) {
          res.data?.data.forEach((category) => {
            categories.push(category.name);
          });
        }
        setAllCategory(categories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get All Selected Home Page Category
  const getSelectedHomeCategory = () => {
    axios.get(`api/homecategory/get-home-category`).then((res) => {
      setCuratedCategory(res.data.data[0]?.curatedCategory);
      setTop5HomeCategory(res.data.data[0]?.top5HomeCategory);
      setPopularCategory(res.data.data[0]?.popularCategory);
      setHeaderMenu(res.data.data[0]?.headerMenu);
      setWebsite(res.data.data[0]?.customised);
    });
  };

  useEffect(() => {
    getSelectedHomeCategory();
    getAllCategory();
    // eslint-disable-next-line
  }, []);

  const submit = (e, val) => {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "POST",
      data: {
        id: "6441b582018b4dda06e9ca1c",
        curatedCategory: curatedCategory,
        top5HomeCategory: top5HomeCategory,
        popularCategory: popularCategory,
        headerMenu: headerMenu,
        customised: val,
      },
      withCredentials: true,
      url: `api/homecategory/update-home-category`,
    })
      .then((res) => {
        getSelectedHomeCategory();
        setTimeout(() => {
          setLoading(false);
        }, 1500);
        message.success(res.data.message);
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
        console.log(err);
      });
  };

  return (
    <div className="p-5">
      <button
        onClick={(e) => submit(e, !website)}
        className={`btn ${website ? "btn-danger" : "btn-success"}`}
      >
        {website ? "No Live" : "Live"}
      </button>
    </div>
  );
};

export default Website;
