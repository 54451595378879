import React from "react";
import HikingIcon from "@mui/icons-material/Hiking";
import MapIcon from "@mui/icons-material/Map";
import SportsMotorsportsIcon from "@mui/icons-material/SportsMotorsports";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
//
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import HotelIcon from "@mui/icons-material/Hotel";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";

const WeAreHighMountains = ({ windowWidth }) => {
  return (
    <div className="py-5 Mon_font" style={{ backgroundColor: "#dfe6e9" }}>
      <h3 className={`fw-bold text-center mb-5 text-decoration-underline`}>
        We are High Mountains
      </h3>
      <div className="row">
        <div className="mb-4 mb-lg-0 mb-md-0 d-flex flex-column justify-content-center align-items-center col-6 col-sm-6 col-md-4 col-lg-2 ">
          <EmojiPeopleIcon className="icon fs-1" />
          <p className="m-0 mt-2 text-secondary py-1 text-center about-small-text">
            Coolest
            {windowWidth < 500 ? <br /> : null}
            Trip Captains
          </p>
        </div>
        <div className="mb-4 mb-lg-0 mb-md-0 d-flex flex-column justify-content-center align-items-center col-6 col-sm-6 col-md-4 col-lg-2 ">
          <AutoStoriesIcon className="icon fs-1" />
          <p className="m-0 mt-2 text-secondary py-1 text-center about-small-text">
            Specially
            {windowWidth < 500 ? <br /> : null}
            Handcrafted Itineraries
          </p>
        </div>
        <div className="mb-4 mb-lg-0 mb-md-0 d-flex flex-column justify-content-center align-items-center col-6 col-sm-6 col-md-4 col-lg-2 ">
          <DirectionsCarIcon className="icon fs-1" />
          <p className="m-0 mt-2 text-secondary py-1 text-center about-small-text">
            Safe
            {windowWidth < 500 ? <br /> : null}
            Transportation Facilities
          </p>
        </div>
        <div className="mb-4 mb-lg-0 mb-md-0 d-flex flex-column justify-content-center align-items-center col-6 col-sm-6 col-md-4 col-lg-2 ">
          <HotelIcon className="icon fs-1" />
          <p className="m-0 mt-2 text-secondary py-1 text-center about-small-text">
            Comfortable
            {windowWidth < 500 ? <br /> : null}
            and Hygienic Stays
          </p>
        </div>
        <div className="mb-4 mb-lg-0 mb-md-0 d-flex flex-column justify-content-center align-items-center col-6 col-sm-6 col-md-4 col-lg-2 ">
          <Diversity1Icon className="icon fs-1" />
          <p className="m-0 mt-2 text-secondary py-1 text-center about-small-text">
            Own On Ground
            {windowWidth < 500 ? <br /> : null}
            Operations Team
          </p>
        </div>
        <div className="mb-4 mb-lg-0 mb-md-0 d-flex flex-column justify-content-center align-items-center col-6 col-sm-6 col-md-4 col-lg-2 ">
          <NoAccountsIcon className="icon fs-1" />
          <p className="m-0 mt-2 text-secondary py-1 text-center about-small-text">
            No Third
            {windowWidth < 500 ? <br /> : null}
            Party Involvement
          </p>
        </div>
      </div>
    </div>
  );
};

export default WeAreHighMountains;
