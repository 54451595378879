import React, { useEffect, useState } from "react";
import Layout from "../../components/Admin/AdminLayout";
import AdminHeader from "./component/AdminHeader";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import BookIcon from "@mui/icons-material/Book";
import Book from "@mui/icons-material/Book";

const BookingTrip = () => {
  const [bookingTrip, setBookingTrip] = useState(null);
  const { id } = useParams();

  const toUpperCase = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };

  async function getBookingDetails() {
    try {
      const res = await axios.post("/api/booking/get-booking-details", {
        id: id,
      });
      if (res.data.success) {
        setBookingTrip(res.data.data[0]);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getBookingDetails();
  }, []);

  return (
    <Layout>
      <AdminHeader
        title={`Trip Booking List / ${bookingTrip?.username}`}
        redirect="/booking-list"
      />
      <div className="Mon_font">
        <div className="px-4">
          <h4 className="m-0">
            <AccountCircleIcon className="icon me-2" />
            User Details
          </h4>
          <div className="card my-3 p-3">
            <div className="d-flex justify-content-start align-items-center py-2">
              <h6 className="m-0 pe-4">
                <strong>Name:</strong>
              </h6>
              <h6 className="m-0">{bookingTrip?.username}</h6>
            </div>
            <div className="d-flex justify-content-start align-items-center py-2">
              <h6 className="m-0 pe-4">
                <strong>Phone:</strong>
              </h6>
              <h6 className="m-0">{bookingTrip?.mobile}</h6>
            </div>
            <div className="d-flex justify-content-start align-items-center py-2">
              <h6 className="m-0 pe-4">
                <strong>Email:</strong>
              </h6>
              <h6 className="m-0">{bookingTrip?.email}</h6>
            </div>
            <div className="d-flex justify-content-start align-items-center py-2">
              <h6 className="m-0 pe-4">
                <strong>City:</strong>
              </h6>
              <h6 className="m-0">{bookingTrip?.city}</h6>
            </div>
          </div>
        </div>
        <div className="px-4">
          <h4 className="m-0">
            <FlightTakeoffIcon className="icon me-2" />
            Trip details
          </h4>
          <div id="all-booking-list" className="card my-3 p-3 ">
            <div className="d-flex justify-content-start align-items-center py-2">
              <h6 className="m-0 pe-4">
                <strong>Title:</strong>
              </h6>
              <h6 className="m-0">
                <Link
                  to={`/trip/${bookingTrip?.tripName.replace(/\s+/g, "-")}`}
                >
                  {bookingTrip?.tripName}
                </Link>
              </h6>
            </div>
            <div className="d-flex justify-content-start align-items-center py-2">
              <h6 className="m-0 pe-4">
                <strong>Trip Date:</strong>
              </h6>
              <h6 className="m-0">{bookingTrip?.tripDate}</h6>
            </div>
            <div className="d-flex justify-content-start align-items-center py-2">
              <h6 className="m-0 pe-4">
                <strong>Occupancy:</strong>
              </h6>
              <h6 className="m-0">
                {bookingTrip?.occupancy?.map((item, index) => {
                  return (
                    <span className="me-3">
                      {item?.name} - Rs. {item?.price},
                    </span>
                  );
                })}
              </h6>
            </div>
          </div>
        </div>
        <div className="px-4">
          <h4 className="m-0">
            <BookIcon className="icon me-2" />
            Booking details
          </h4>
          <div className="card my-3 p-3 ">
            <div className="d-flex justify-content-start align-items-center py-2">
              <h6 className="m-0 pe-4">
                <strong>Booking Date:</strong>
              </h6>
              <h6 className="m-0">
                {new Date(bookingTrip?.date).toLocaleString("default", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </h6>
            </div>
            <div className="d-flex justify-content-start align-items-center py-2">
              <h6 className="m-0 pe-4">
                <strong>Total Amount:</strong>
              </h6>
              <h6 className="m-0">Rs. {bookingTrip?.totalAmount}</h6>
            </div>
            <div className="d-flex justify-content-start align-items-center py-2">
              <h6 className="m-0 pe-4">
                <strong>Advance:</strong>
              </h6>
              <h6 className="m-0">Rs. {bookingTrip?.advance}</h6>
            </div>
            <div className="d-flex justify-content-start align-items-center py-2">
              <h6 className="m-0 pe-4">
                <strong>Remaining:</strong>
              </h6>
              <h6 className="m-0">{bookingTrip?.remainingAmount}</h6>
            </div>
            {bookingTrip?.couponDiscount && (
              <div className="d-flex justify-content-start align-items-center py-2">
                <h6 className="m-0 pe-4">
                  <strong>Coupon:</strong>
                </h6>
                <h6 className="m-0">{bookingTrip?.couponDiscount}</h6>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BookingTrip;
