import React from "react";
import Header from "../Header/Header";
import HeroSection from "../LandingPage/HeroSection";
import { useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import "../../Style/HomeLayout/HomeLayout.css";
import IMAGES from "../../images/LandingPage/index";

const Layout = ({ children, title, subTitle, trip }) => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  // const isAbout = location.pathname === "/about";

  return (
    <div className="">
      <Header />
      <div className="hero-slider">
        {/* <div
          id="carouselExampleInterval"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="5000">
              <img
                width={100}
                src={IMAGES.hm01}
                class="d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item" data-bs-interval="5000">
              <img
                width={100}
                src={IMAGES.hm02}
                class="d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item" data-bs-interval="5000">
              <img
                width={100}
                src={IMAGES.hm03}
                class="d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item" data-bs-interval="5000">
              <img
                width={100}
                src={IMAGES.hm04}
                class="d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item" data-bs-interval="5000">
              <img
                width={100}
                src={IMAGES.hm05}
                class="d-block w-100"
                alt="..."
              />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div> */}
        <div>{children}</div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
