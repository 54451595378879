import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../Style/LandingPage/TripDetails.css";
import TripSlider from "./TripSlider";

const TripDetails = ({ data, title, category, loading }) => {
  const navigate = useNavigate();
  const arrowRef = useRef();
  const [month, setMonth] = useState("");
  const allDates = [];
  const box = data?.map((obj) =>
    obj.dates.map((dateObj) => allDates.push(dateObj))
  );
  const currentDate = new Date();
  const uniqueMonths = Array.from(
    new Set(
      allDates
        .filter((obj) => new Date(obj.date) >= currentDate)
        .map((obj) =>
          new Date(obj.date).toLocaleString("default", { month: "long" })
        )
    )
  ).sort(
    (a, b) =>
      new Date(Date.parse("01 " + a + " 2000")) -
      new Date(Date.parse("01 " + b + " 2000"))
  );

  const filteredData = data?.filter((obj) => {
    return obj.dates.some((item) => {
      const dateObj = new Date(item.date);
      return dateObj.toLocaleString("default", { month: "long" }) === month;
    });
  });
  const trips = month === "all" || !month ? data : filteredData;

  return (
    <div className="container-fluid upcoming__trips px-lg-5 px-2">
      <div className="heading__date__button Mon_font">
        <div className="d-flex justify-content-center align-items-center">
          <h2 className="text-center fw-bold">{title}</h2>
          <select
            className="custom-select"
            name="month"
            onChange={(event) => {
              setMonth(event.target.value);
            }}
          >
            {uniqueMonths?.map((item) => {
              return <option value={item}>{item.slice(0, 3)}</option>;
            })}
          </select>
        </div>
        <button
          onClick={() => navigate(`category/${category}`)}
          className="btn view_all"
        >
          View All
        </button>
      </div>
      <TripSlider trips={trips} selectedMonth={month} setMonth={setMonth} />
    </div>
  );
};

export default TripDetails;
