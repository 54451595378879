import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineCheckCircle } from 'react-icons/ai';

const Success = () => {
  const params  = useParams();
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10);
  const data = params.id.split('_');
  console.log(data)
  //to upper case function
  const toUpperCase = (string) =>{
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }

  const sendMessageToWhatsApp = ()=>{
      const message = encodeURIComponent(`Hello Tripgix, My name is *${toUpperCase(data[1])}* and I successfully paid for a trip to *${toUpperCase(data[4])}*. My transaction id ${data[0]} and my booking details *${data[15]}*`);
      const phoneNumber = '+917982328247'; // Replace with the phone number you want to send the message to
      const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
      window.open(whatsappLink, '_blank');
  }
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    const redirectTimer = setTimeout(() => {
      clearInterval(timer); 
      navigate("/");
    }, 10000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [navigate]);
  return (
    <div className='d-flex flex-column align-items-center justify-content-center Mon_font mt-2'> 
      {data && 
      <>
      <div className='d-flex flex-column align-items-center justify-content-center'>
          <div className='my-1 pt-2 text-center'>
              <div className="payment-success-icon">
                  <AiOutlineCheckCircle />
              </div>
              <h3 className='m-0 text-success'>Payment successfull!</h3>
              <hr className='m-0 my-2'/>
          </div>
          <div className="w-100 px-3 text-center mb-2 py-2">
            <p className='w-50 txt-lg w-100 m-0 text-success'>Your booking is successfull. We have sent you an email about the trip details. One of our team member will contact you.</p>
          </div>
          <div className="w-100 px-3 mb-2 d-flex flex-wrap justify-content-center align-items-center">
          <table className='w-100 table border mt-4'>
              <tbody>
                  <tr>
                      <td className='txt-lg'><strong>Amount</strong></td>
                      <td className='txt-lg'>{data[5]}</td>
                  </tr>
                  <tr>
                      <td className='txt-lg'><strong>Txn Id</strong></td>
                      <td className='txt-lg'>{data[0]}</td>
                  </tr>
                  <tr>
                      <td className='txt-lg'><strong>Number</strong></td>
                      <td className='txt-lg'>{data[3]}</td>
                  </tr>
                  <tr>
                      <td className='txt-lg'><strong>Paid to</strong></td>
                      <td className='txt-lg'>Tripgix</td>
                  </tr>
                  <tr>
                      <td className='txt-lg'><strong>Paid on</strong></td>
                      <td className='txt-lg'>{data[2]}</td>
                  </tr>
              </tbody>
          </table>
        </div>
        <div className='text-center mb-5'>
            <span className='text-center'>Redirecting to Home Page in</span> <br />
            <h3 className='text-success mt-3 text-large'><strong>{countdown} Seconds</strong></h3>
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center m-0'>
            <button className='btn btn-success m-0' onClick={sendMessageToWhatsApp}><i className="fa-brands fa-whatsapp me-2"></i>Contact Whatsapp</button>
            <p className='m-0 py-2'>or</p>
            <Link className='m-0 txt-sm' to="/">
                <i className="fa-solid fa-arrow-left pe-2"></i>
                Go back to home page
            </Link>
        </div>
      </div>
      </>
      }
    </div>
  )
}

export default Success