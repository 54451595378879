import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../Style/SingleTrip/SingleTrip.css";
import axios from "axios";
import BookingPage from "./BookingPage";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import StarPurple500SharpIcon from "@mui/icons-material/StarPurple500Sharp";
import HailSharpIcon from "@mui/icons-material/HailSharp";
import PinDropSharpIcon from "@mui/icons-material/PinDropSharp";
import AccessTimeSharpIcon from "@mui/icons-material/AccessTimeSharp";
import VerifiedUserSharpIcon from "@mui/icons-material/VerifiedUserSharp";
import EventBusySharpIcon from "@mui/icons-material/EventBusySharp";
import PersonPinSharpIcon from "@mui/icons-material/PersonPinSharp";
import SupportAgentSharpIcon from "@mui/icons-material/SupportAgentSharp";
import Slider from "react-slick";
import ArrowRightAltSharpIcon from "@mui/icons-material/ArrowRightAltSharp";
import SiteReviews from "../components/SiteReviews";
import HomeBlogs from "../components/LandingPage/HomeBlogs";
import CancelIcon from "@mui/icons-material/Cancel";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { message } from "antd";
import IMAGES from "../images/LandingPage";

const SingleTrip = () => {
  const navigate = useNavigate();
  const [trip, setTrip] = useState(null);
  const [expertForm, setExpertForm] = useState(false);
  const [width, setWidth] = useState(false);
  const [tripCategory, setTripCategory] = useState([]);
  const params = useParams();
  const paramsdata = (params?.id).replace(/-/g, " ");
  const Trip = trip && trip.filter((value) => value.heading === paramsdata);
  const activeTrip = trip ? Trip[0] : null;
  document.title = `${paramsdata}`;
  const location = useLocation();

  const singleTripPage = location.pathname === `/trip/${params.id}`;
  const bookingPage = location.pathname === `/book/${params.id}`;

  // ALL SIMILAR TRIPS CONTAINING THE CATEGORY
  const similarTrips = trip?.filter((item) =>
    tripCategory?.some((cate) => item?.category?.includes(cate))
  );
  // REMOVING SAME TRIP Using params id of a trip
  const filterSimilarTrips = similarTrips?.filter(
    (obj) => obj.heading !== paramsdata
  );
  // REMOVING CUSTOMISE TRIPS from the array
  const finalSimilarTrips = filterSimilarTrips?.filter(
    (obj) => obj.dates.length > 0
  );

  const [toggle, setToggle] = useState(1);
  const [toggle2, setToggle2] = useState(1);

  const AllTrips = async () => {
    await axios
      .get(`api/trips/get-all-trips`)
      .then((res) => {
        setTrip(res.data.data);
        setTripCategory(
          res.data.data.filter((value) => value.heading === paramsdata)[0]
            ?.category
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    AllTrips();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 600) {
      setWidth(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleShare = (message) => {
    const whatsappLink = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, "_blank");
  };

  const overviewRef = useRef(null);
  const ScrollToElementButton = ({ targetSelector, buttonText }) => {
    const scrollToElement = () => {
      const targetElement = document.querySelector(targetSelector);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    };
    return (
      <button className="m-0 cta-btn" onClick={scrollToElement}>
        {buttonText}
      </button>
    );
  };

  const [qform, setQform] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    type: "",
    package: "",
  });
  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  //! SINGLE SHORT DETAILS
  const SingleShortDetails = () => {
    return (
      <div className="single-short-details">
        <div className="heading-details">
          <div>
            <h3>{activeTrip?.heading}</h3>
            <span className="single-star">
              <StarPurple500SharpIcon className="icon" />
              <StarPurple500SharpIcon className="icon" />
              <StarPurple500SharpIcon className="icon" />
              <StarPurple500SharpIcon className="icon" />
              <StarPurple500SharpIcon className="icon" />
              <span className="ms-2 text-secondary">
                <small>({activeTrip?.reviews}+ Reviews)</small>
              </span>
            </span>
          </div>
          <div className="share-btns">
            <button
              onClick={() =>
                handleShare(`trip/${activeTrip?.heading.replace(/ /g, "-")}`)
              }
              className="share-btn"
            >
              <WhatsAppIcon className="icon me-1" />
              Share
            </button>
            <button className="send-query-btn" onClick={() => setQform(!qform)}>
              Send Query
            </button>
            <button className="get-pdf-btn">
              <Link
                target="_blank"
                className="text-dark"
                to={activeTrip?.pdfFile}
              >
                Get PDF
              </Link>
            </button>
          </div>
        </div>
        <hr />
        <h6 className="text-secondary mb-3">Details</h6>
        <div className="single-details">
          <div className="details-card">
            <HailSharpIcon className="icon" />
            <div>
              <h6>Pickup</h6>
              <p>{activeTrip?.departure}</p>
            </div>
          </div>
          <div className="details-card">
            <PinDropSharpIcon className="icon" />
            <div>
              <h6>Drop</h6>
              <p>{activeTrip?.drop}</p>
            </div>
          </div>
          <div className="details-card">
            <AccessTimeSharpIcon className="icon" />
            <div>
              <h6>Duration</h6>
              <p>{activeTrip?.duration}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //! SAFE TRAVEL SECTION
  const SafeTravelSection = () => {
    return (
      <div className="single-short-details safe-travel">
        <div className="text-center d-flex justify-content-center flex-column align-items-center">
          <VerifiedUserSharpIcon className="icon" />
          <p className="m-0 mt-1">Safe Travel</p>
        </div>
        <div className="text-center d-flex justify-content-center flex-column align-items-center">
          <EventBusySharpIcon className="icon" />
          <p className="m-0 mt-1">Flexible Cancellation</p>
        </div>
        <div className="text-center d-flex justify-content-center flex-column align-items-center">
          <PersonPinSharpIcon className="icon" />
          <p className="m-0 mt-1">Certified Captains</p>
        </div>
        <div className="text-center d-flex justify-content-center flex-column align-items-center">
          <SupportAgentSharpIcon className="icon" />
          <p className="m-0 mt-1">24/7 Support</p>
        </div>
      </div>
    );
  };

  //! OVERVIEW
  const Overview = () => {
    return (
      <div className="single-short-details overview" ref={overviewRef}>
        <h5>Overview</h5>
        <hr />
        <p>{activeTrip?.about}</p>
      </div>
    );
  };

  //! ITINERARY
  const Itinerary = () => {
    return (
      <div className="single-short-details itinerary">
        <h5>Itinerary</h5>
        <hr />
        <div
          className="Mon_font accordion accordion-flush mb-4"
          id="accordionFlushExample"
        >
          {activeTrip?.itinerary.map((item, index) => {
            return (
              <div key={index} className="accordion-item itinerary-item">
                <div className="d-flex justify-content-center accordion-item-div">
                  <div className="accordion-item-day">Day {index + 1}</div>
                  <button
                    className="bg-light accordion-button collapsed px-0 accordian-item-button pe-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse-${index}`}
                    aria-expanded="true"
                    aria-controls={`collapse-${index}`}
                  >
                    <span className="ms-3">
                      <b>{item?.heading}</b>
                    </span>
                  </button>
                </div>
                <div
                  id={`collapse-${index}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`heading-${index}`}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body border m-2">
                    {item?.description?.split("\n").map((line, i) => (
                      <div key={i} className="my-2">
                        <span className="accordian-item-desc">{line}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  //! INCLUSION
  const InclusionAndExclusions = () => {
    return (
      <div className="single-short-details inclusion-and-exclusions-container">
        <h5 className="w-100">Inclusions & Exclusions</h5>
        <hr />
        <div className="inclusion-and-exclusions">
          <div className="inclusion">
            <h5 className="p-3">Inclusions</h5>
            {activeTrip?.inclusion?.map((item, index) => {
              return (
                <p className="m-0">
                  <CheckIcon className="icon text-success" />{" "}
                  <small>{item?.name}</small>
                </p>
              );
            })}
          </div>
          <div className="exclusion">
            <h5 className="p-3">Exclusions</h5>
            {activeTrip?.exclusion?.map((item, index) => {
              return (
                <p className="m-0">
                  <ClearIcon className="icon text-danger" />{" "}
                  <small>{item?.name}</small>
                </p>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  //!PRICING
  const [num, setNum] = useState(0);
  const Pricing = () => {
    return (
      <div className="single-short-details pricing">
        <h5>Pricing</h5>
        <hr />
        <div className="d-flex">
          {activeTrip?.costing?.map((item, index) => {
            return (
              <button
                className={`btn ${
                  index === num && "text-decoration-underline"
                }`}
                onClick={() => setNum(index)}
              >
                {item?.heading}
              </button>
            );
          })}
        </div>
        <hr />
        <table className="table mb-3">
          <thead>
            {activeTrip?.costing[num]?.package?.map((item, index) => {
              return (
                <tr>
                  <th>{item?.name}</th>
                  <th>₹ {item?.price}</th>
                </tr>
              );
            })}
          </thead>
        </table>
      </div>
    );
  };

  //! CUSTOMISED PRICING
  const HmPricing = () => {
    return (
      <div className="single-short-details pricing">
        <h5>Pricing</h5>
        <hr />
        <div className="d-flex">
          {activeTrip?.hmCosting?.map((item, index) => {
            return (
              <button
                className={`btn ${
                  index === num && "text-decoration-underline"
                }`}
                onClick={() => setNum(index)}
              >
                {item?.heading}
              </button>
            );
          })}
        </div>
        <hr />
        <table className="table mb-3">
          <thead>
            <tr>
              <th>Transportation Type</th>
              <th>Total Pax</th>
              <th>Per Person Cost</th>
            </tr>
          </thead>
          <tbody>
            {activeTrip?.hmCosting[num]?.package?.map((item, index) => {
              return (
                <tr>
                  <th>{item?.name}</th>
                  <th>{item?.pax}</th>
                  <th>{item?.price}</th>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  //! GALLERY
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4.2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 3,
          dots: true,
          infinite: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          className: "center",
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          nextArrow: false,
          prevArrow: false,
          dots: false,
        },
      },
    ],
  };
  const Gallery = () => {
    return (
      <div className="single-short-details single-gallery">
        <h5>Gallery</h5>
        <hr />
        <Slider {...settings}>
          {activeTrip?.gallery?.map((item, index) => {
            return (
              <div className="g-img-cont">
                <img src={`gallery/${item}`} alt="" />
              </div>
            );
          })}
        </Slider>
      </div>
    );
  };

  //! ACCOMODATION
  const Accomodation = () => {
    return (
      <div className="single-short-details single-gallery">
        <h5>Accomodation</h5>
        <hr />
        <Slider {...settings}>
          {activeTrip?.accomodation?.map((item, index) => {
            return (
              <div className="g-img-cont">
                <img className="m-2" src={`accomodation/${item}`} alt="" />
              </div>
            );
          })}
        </Slider>
      </div>
    );
  };

  //! VIDEO
  const Video = () => {
    return (
      <div className="single-short-details video">
        <iframe
          className=""
          src={activeTrip?.videos[activeTrip?.videos?.length - 1].url}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  //! CANCELLATION POLICY
  const cancel = [
    {
      item: "If cancellations are made 30 days before the start date of the trip, 50% of the trip cost will be charged as cancellation fees.",
    },
    {
      item: "If cancellations are made 15-30 days before the start date of the trip, 75% of the trip cost will be charged as cancellation fees.",
    },
    {
      item: "If cancellations are made within 0-15 days before the start date of the trip, 100% of the trip cost will be charged as cancellation fees.",
    },
    {
      item: "In the case of unforeseen weather conditions or government restrictions, certain activities may be cancelled and in such cases, the operator will try his best to provide an alternate feasible activity. However, no refund will be provided for the same.",
    },
  ];
  const CancellationPolicy = () => {
    return (
      <div className="single-short-details cancellation">
        <h5>Cancellation Policy</h5>
        <hr />
        <h6>
          No Refund shall be made with respect to the initial booking amount for
          any cancellations. However,
        </h6>
        {cancel?.map((elm, index) => {
          return (
            <p className="m-0">
              <ArrowRightAltSharpIcon className="icon text-danger" />{" "}
              <small>{elm?.item}</small>
            </p>
          );
        })}
      </div>
    );
  };

  //! TERMS & CONDITIONS
  const TermsAndConditions = () => {
    return (
      <div className="single-short-details terms-and-conditions">
        <h5>Term & Conditions</h5>
        <hr />
        {activeTrip?.tnc?.map((item, index) => {
          return (
            <p key={index} className="m-0">
              <ArrowRightAltSharpIcon className="icon text-danger" />{" "}
              <small>{item?.name}</small>
            </p>
          );
        })}
      </div>
    );
  };

  //! THINGS TO PACK
  const ThingsToPack = () => {
    return (
      <div className="single-short-details things-to-pack">
        <h5>Things to Pack</h5>
        <hr />
        {activeTrip?.thingstocarry?.map((item, index) => {
          return (
            <div>
              <h6>{item?.name}:</h6>
              <p>{item?.description}</p>
            </div>
          );
        })}
      </div>
    );
  };

  //! ========================================= RIGHT SIDE
  //! TOP RIGHT PRICE
  const TopRightPrice = () => {
    return (
      <div className="single-short-details price-container">
        <h6>Starting Price</h6>
        <div className="single-price">
          <h2>
            <b>
              ₹
              {activeTrip?.customised === "true"
                ? parseInt(
                    activeTrip?.hmCosting?.reduce((min, obj) => {
                      const lowestPrice = obj.package?.reduce(
                        (minPackage, packageObj) =>
                          parseInt(packageObj?.price) < minPackage
                            ? parseInt(packageObj?.price)
                            : minPackage,
                        Infinity
                      );
                      return lowestPrice < min ? lowestPrice : min;
                    }, Infinity)
                  ).toLocaleString("en-US")
                : parseInt(
                    activeTrip?.costing?.reduce((min, obj) => {
                      const lowestPrice = obj.package?.reduce(
                        (minPackage, packageObj) =>
                          parseInt(packageObj?.price) < minPackage
                            ? parseInt(packageObj?.price)
                            : minPackage,
                        Infinity
                      );
                      return lowestPrice < min ? lowestPrice : min;
                    }, Infinity)
                  ).toLocaleString("en-US")}
            </b>
          </h2>
          <span>Per Person</span>
        </div>
        {activeTrip?.customised === "true" ? (
          <button
            onClick={() => setQform(!qform)}
            className="book-btn bg-success text-white"
          >
            Send Inquiry
          </button>
        ) : (
          <button
            onClick={() => navigate(`/book/${activeTrip?._id}`)}
            className="book-btn"
          >
            Book Now
          </button>
        )}
      </div>
    );
  };

  //! DATES
  const [month, setMonth] = useState(null);
  const [allMonth, setAllMonth] = useState(true);
  const [monthIndex, setMonthIndex] = useState(-1);
  const Dates = () => {
    const filteredDates = allMonth
      ? activeTrip?.dates
      : activeTrip?.dates?.filter(
          (item) =>
            new Date(item?.date).toLocaleString("default", {
              month: "short",
            }) === month
        );

    const getStatusForDate = (date) => {
      const matchingDateStatus = activeTrip?.dateStatus.find(
        (statusItem) => statusItem.date === date
      );
      return matchingDateStatus ? matchingDateStatus.status : null;
    };

    return (
      <div className="single-short-details dates">
        <div className="batches">
          <h5 className="m-0">Batches</h5>
          <div className="batch-month">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setAllMonth(!allMonth);
                setMonthIndex(-1);
              }}
              className={`${allMonth && "text-decoration-underline"}`}
            >
              All
            </span>
            {[
              ...new Set(
                activeTrip?.dates?.map((item) =>
                  new Date(item?.date).toLocaleString("default", {
                    month: "short",
                  })
                )
              ),
            ]
              .slice(0, 4)
              .map((month, index) => (
                <span
                  style={{ cursor: "pointer" }}
                  className={`${
                    index === monthIndex && "text-decoration-underline"
                  }`}
                  onClick={() => {
                    setMonth(month);
                    setAllMonth(false);
                    setMonthIndex(index);
                  }}
                  key={index}
                >
                  {month}
                </span>
              ))}
          </div>
        </div>
        <hr />
        <div className="batch-date">
          {filteredDates?.map((item, index) => {
            const day = new Date(item?.date).toLocaleString("default", {
              day: "numeric",
            });
            const month = new Date(item?.date).toLocaleString("default", {
              month: "long",
            });
            const year = new Date(item?.date).toLocaleString("default", {
              year: "numeric",
            });
            const status = getStatusForDate(item?.date);
            return (
              <p key={index}>
                {day} {month} {year} - {status}
              </p>
            );
          })}
        </div>
      </div>
    );
  };

  //! CONTACT CTA
  const ContactCta = () => {
    return (
      <div className="single-short-details contact-cta">
        <WhatsAppIcon className="icon" />
        <button onClick={() => setQform(!qform)}>Send Query</button>
        <button>
          <Link target="_blank" className="text-dark" to={activeTrip?.pdfFile}>
            Get PDF
          </Link>
        </button>
      </div>
    );
  };

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      message: activeTrip?.heading || "",
    }));
  }, [activeTrip]);

  const [error, setError] = useState(false);
  async function handleSubmit(e) {
    if (activeTrip?.customised === "true") {
      if (
        form?.name === "" ||
        form?.phone === "" ||
        form?.email === "" ||
        form?.message === "" ||
        form?.type === "" ||
        form?.package === ""
      ) {
        return setError(true);
      }
    } else {
      if (
        form?.name === "" ||
        form?.phone === "" ||
        form?.email === "" ||
        form?.message === ""
      ) {
        return setError(true);
      }
    }
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post("/api/inquiry/add-inquiry", form);
      if (res.data.success) {
        message.success(res.data.message);
        setTimeout(() => {
          setLoading(false);
          setMsg(true);
          setError(false);
          setForm({
            name: "",
            phone: "",
            email: "",
            message: "",
            type: "",
            package: "",
          });
        }, 1000);
      } else {
        setLoading(false);
        setError(false);
        setMsg(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      setMsg(false);
      setError(false);
      console.log(error);
    }
  }

  return (
    <>
      {/*! QUERY FROM  */}
      <div className={`query-form-container ${qform && "active"}`}>
        <div className="query-bckd">
          <div className="query-form">
            <div className="d-flex justify-content-between align-items-center">
              <h4>Plan Your Next Trip</h4>
              <CancelIcon
                className="icon fs-2"
                onClick={() => {
                  setQform(!qform);
                  setMsg(false);
                }}
              />
            </div>
            <hr />
            <div className="row">
              <div className="mb-3 col-12 col-sm-12 col-md-5 col-lg-6">
                <label htmlFor="">Name</label>
                <input
                  onChange={handleChange}
                  value={form?.name}
                  name="name"
                  type="text"
                  className="form-control"
                />
                <span className="text-danger">
                  <small>{error && form?.name === "" && "Enter name"}</small>
                </span>
              </div>
              <div className="mb-3 col-12 col-sm-12 col-md-5 col-lg-6">
                <label htmlFor="">Phone</label>
                <input
                  onChange={handleChange}
                  value={form?.phone}
                  name="phone"
                  type="text"
                  className="form-control"
                />
                <span className="text-danger">
                  <small>{error && form?.phone === "" && "Enter phone"}</small>
                </span>
              </div>
              <div className="mb-3 col-12">
                <label htmlFor="">Email</label>
                <input
                  onChange={handleChange}
                  value={form?.email}
                  name="email"
                  type="email"
                  className="form-control"
                />
                <span className="text-danger">
                  <small>{error && form?.email === "" && "Enter email"}</small>
                </span>
              </div>
              <div className="mb-3 col-12">
                <label htmlFor="">Destination</label>
                <input
                  onChange={handleChange}
                  value={form?.message}
                  name="message"
                  type="text"
                  className="form-control"
                />
                <span className="text-danger">
                  <small>
                    {error && form?.message === "" && "Enter message"}
                  </small>
                </span>
              </div>
              {activeTrip?.customised === "true" && (
                <>
                  <div className="col-6 mb-3">
                    <label htmlFor="">Type</label>
                    <select
                      onChange={(e) => {
                        handleChange(e);
                        const selectedIndex = e.target.selectedIndex;
                        setNum(selectedIndex - 1);
                      }}
                      value={form?.type}
                      name="type"
                      className="form-select"
                    >
                      <option value="">Select</option>
                      {activeTrip?.hmCosting?.map((item, index) => {
                        return (
                          <option key={index} value={item?.heading}>
                            {item?.heading}
                          </option>
                        );
                      })}
                    </select>
                    <span className="text-danger">
                      <small>
                        {error && form?.type === "" && "Enter type"}
                      </small>
                    </span>
                  </div>
                  <div className="col-6 mb-3">
                    <label htmlFor="">Package</label>
                    <select
                      onChange={handleChange}
                      value={form?.package}
                      name="package"
                      className="form-select"
                    >
                      <option value="">Select</option>
                      {activeTrip?.hmCosting[num]?.package?.map(
                        (item, index) => {
                          return (
                            <option
                              value={
                                item?.name + item?.pax + "pax" + item?.price
                              }
                            >
                              {item?.name} {item?.pax}pax {item?.price}
                            </option>
                          );
                        }
                      )}
                    </select>
                    <span className="text-danger">
                      <small>
                        {error && form?.package === "" && "Enter package"}
                      </small>
                    </span>
                  </div>
                </>
              )}
              {msg && (
                <div className="mb-2 text-success">
                  Inquiry Submitted Successfully
                </div>
              )}
              <div className="col-12">
                <button onClick={handleSubmit} className="submit-btn w-100">
                  {loading ? "Submiting..." : "Submit Details"}
                  {loading && (
                    <div
                      class="ms-2 spinner-grow spinner-grow-sm"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Header />
      {singleTripPage && (
        <div className="single-trip-container">
          <div className="trip-banner trip-banner-container">
            <div className="img-one">
              <img src={`gallery/${activeTrip?.gallery[0]}`} alt="" />
            </div>
            <div className="img-two">
              <img src={`gallery/${activeTrip?.gallery[1]}`} alt="" />
            </div>
            <div className="img-three d-none d-lg-block">
              <img src={`gallery/${activeTrip?.gallery[2]}`} alt="" />
            </div>
            <div className="img-four d-none d-lg-block">
              <img src={`gallery/${activeTrip?.gallery[3]}`} alt="" />
            </div>
          </div>
          <div className="single-trip">
            <div className="single-trip-left">
              <SingleShortDetails />
              <SafeTravelSection />

              <div className="single-short-details cta-details">
                <ScrollToElementButton
                  targetSelector=".overview"
                  buttonText="Overview"
                />{" "}
                <ScrollToElementButton
                  targetSelector=".itinerary"
                  buttonText="Itinerary"
                />
                <ScrollToElementButton
                  targetSelector=".inclusion-and-exclusions-container"
                  buttonText="Inclusions & Exclusions"
                />
                <ScrollToElementButton
                  targetSelector=".pricing"
                  buttonText="Pricing"
                />
                <ScrollToElementButton
                  targetSelector=".things-to-pack"
                  buttonText="Things to Pack"
                />
              </div>
              <Overview />
              <Itinerary />
              <InclusionAndExclusions />
              {activeTrip?.customised === "true" ? <HmPricing /> : <Pricing />}
              <ThingsToPack />
              <CancellationPolicy />
              <TermsAndConditions />
              <Gallery />
              <Accomodation />
              <Video />
            </div>
            <div className="single-trip-right">
              <div className="right-form">
                <TopRightPrice />
                {activeTrip?.customised !== "true" && <Dates />}
                <ContactCta />
              </div>
            </div>
          </div>
          <SiteReviews />
          <HomeBlogs />
        </div>
      )}
      {bookingPage && <BookingPage trip={trip} />}
      <Footer />
    </>
  );
};

export default SingleTrip;
