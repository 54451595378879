import React, { useState } from "react";
import "../../Style/Admin/AdminLayout.css";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import axios from "axios";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  // Logout Handler
  const onHandleLogout = () => {
    axios({
      method: "POST",
      withCredentials: true,
      url: "api/users/logout",
    })
      .then((res) => {
        navigate("/login");
        message.success(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // ===================================== ADMIN MENU ========================================
  const adminMenu = [
    {
      name: "Dashboard",
      path: "/admin-dashboard",
      icon: "fa-solid fa-user",
    },
    {
      name: "All Tours & Trips",
      path: "/all-trips",
      icon: "fa-solid fa-user",
    },
    {
      name: "Add Category",
      path: "/add-category",
      icon: "fa-solid fa-user",
    },
    {
      name: "Add Members",
      path: "/admin-about",
      icon: "fa-solid fa-user",
    },
    {
      name: "Booking List",
      path: "/booking-list",
      icon: "fa-solid fa-user",
    },
    {
      name: "Add Banner",
      path: "/promotional-banner",
      icon: "fa-solid fa-user",
    },
    {
      name: "Add Reviews",
      path: "/admin-reviews",
      icon: "fa-solid fa-user",
    },
    {
      name: "Add Blog",
      path: "/admin-blog",
      icon: "fa-solid fa-user",
    },
    {
      name: "Inquiry",
      path: "/customer-inquiry",
      icon: "fa-solid fa-user",
    },
    {
      name: "Career",
      path: "/admin-career",
      icon: "fa-solid fa-user",
    },
  ];
  // ===================================== ADMIN MENU ========================================

  return (
    <>
      <div className="adminMain h-100">
        <div className="adminLayout min-vh-100">
          <div className="adminSidebar d-none d-lg-block">
            <div className="d-flex justify-content-between align-items-center p-4">
              <h5 className="text-white Mon_font m-0">Hello Admin</h5>
              <button
                className="btn btn-warning txt-lg btn-sm px-2 py-1"
                onClick={onHandleLogout}
              >
                Logout
              </button>
            </div>
            <hr className="text-white" />
            {adminMenu.map((menu) => (
              <Link to={menu.path}>
                <p className="Mon_font">{menu.name}</p>
                <hr />
              </Link>
            ))}
          </div>
          <div className="adminBody">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Layout;
