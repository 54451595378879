import React, { useEffect, useState } from "react";
import OtherLayout from "../components/Layout/OtherLayout";
import blogBG from "../images/blogs/blog.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TalkToExpert from "../components/LandingPage/TalkToExpert";
import "./Blog.css";

const Blog = () => {
  const [blogs, setBlogs] = useState(null);
  document.title = `High Mountains - ${"Blog"}`;
  const navigate = useNavigate();

  const getAllBlog = async (elm) => {
    await axios
      .get(`api/blog/get-all-blog`)
      .then((res) => {
        let allBlogs = res.data.data;
        setBlogs(allBlogs.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (blogs === null) {
      getAllBlog();
    }
    // eslint-disable-next-line
  }, []);

  const calculateReadingTime = (text) => {
    const wordsPerMinute = 200; // Adjust this value based on the average reading speed
    const wordCount = text.trim().split(/\s+/).length;
    const readingTimeMinutes = Math.ceil(wordCount / wordsPerMinute);
    return readingTimeMinutes;
  };

  const aboutSubTitle =
    "Discover the world with our travel hub, Find destination guides, itineraries, and inspiring stories to fuel your wanderlust and connect with fello travellersw. Lets explore together!";
  return (
    <>
      <OtherLayout
        background={blogBG}
        // title="Our Blogs"
        // extraSubTitle={aboutSubTitle}
      >
        <div className="container py-5">
          <div className="row">
            {blogs &&
              blogs.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-12 col-sm-12 col-md-4 col-lg-4 Mon_font"
                    onClick={() =>
                      navigate(`${item.title.replace(/\s+/g, "-")}`)
                    }
                  >
                    <div
                      className="blog"
                      style={{ background: `url(/blogImages/${item.image})` }}
                    >
                      <div className="blog-bckd">
                        <h5>{item.title}</h5>
                        <div className="d-flex">
                          <span>
                            <small>
                              {new Date(item.createdAt).toLocaleString(
                                "default",
                                {
                                  month: "long",
                                  day: "numeric",
                                }
                              )}
                              {" | "}
                            </small>
                            <span>
                              <small>
                                {calculateReadingTime(item.description)} min
                                Read
                              </small>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <TalkToExpert />
      </OtherLayout>
    </>
  );
};

export default Blog;
