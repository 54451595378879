import "./App.css";
import LandingPage from "./pages/LandingPage";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import SingleTrip from "./pages/SingleTrip";
import BookingPage from "./pages/BookingPage";
import Payment from "./pages/Payment";
import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./pages/admin/ProtectedRoute";
import AdminDashboard from "./pages/admin/AdminDashboard";
import AdminLogin from "./pages/admin/AdminLogin";
import AddTrip from "./pages/admin/AddTrip";
import CategoryListPage from "./pages/CategoryListPage";
import Spinner from "./components/Spinner";
import { SetTrip } from "./redux/features/tripSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useEffect, useState } from "react";
import AllAdminTrips from "./pages/admin/AllAdminTrips";
import Inquiry from "./pages/admin/Inquiry";
import Banner from "./pages/admin/Banner";
import Support from "./pages/admin/Support";
import AddCityImage from "./pages/admin/AddCityImage";
import PaymentPage from "./pages/payment/PaymentPage";
import Success from "./pages/payment/Success";
import Failure from "./pages/payment/Failure";
import TermsPage from "./pages/TermsPage";
import Privacy from "./pages/Privacy";
import Refund from "./pages/Refund";
import BookingList from "./pages/admin/BookingList";
import BookingTrip from "./pages/admin/BookingTrip";
import EditTrip from "./pages/admin/EditTrip";
import WebsiteUpdate from "./pages/admin/component/WebsiteUpdate";
import Career from "./pages/Career";
import HomeCategory from "./pages/admin/HomeCategory";
import AddCategory from "./pages/admin/AddCategory";
import AdminCareer from "./pages/admin/AdminCareer";
import Application from "./pages/admin/Application";
import AddPdf from "./pages/admin/AddPdf";
import AdminBlog from "./pages/admin/AdminBlog";
import CustomisedListPage from "./pages/CustomisedListPage";
import AddCouponCode from "./pages/admin/AddCouponCode";
import CategoryBlogPage from "./pages/CategoryBlogPage";
import PhonePeIntegration from "./components/PhonePeIntegration";
import AdminAddReviews from "./pages/admin/AdminAddReviews";
import AdminAddMembers from "./pages/admin/component/AdminAddMembers";
import AdminEditBlog from "./pages/admin/AdminEditBlog";
import Website from "./pages/Website";

function App() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  // get All Selected Home Page Category
  const getSelectedHomeCategory = () => {
    axios.get(`api/homecategory/get-home-category`).then((res) => {
      setLoader(res.data.data[0]?.customised);
    });
  };

  const AllTrips = async () => {
    await axios
      .get(`api/trips/get-all-trips`)
      .then((res) => {
        dispatch(SetTrip(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    AllTrips();
    getSelectedHomeCategory();
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      {loader ? (
        <Routes>
          <Route exact path="/" element={<Spinner />} />
          <Route exact path="/server-error" element={<Website />} />
        </Routes>
      ) : (
        <main>
          <Routes>
            {/* admin routes */}
            <Route
              exact
              path="/admin-reviews"
              element={
                <ProtectedRoute adminDashboard="adminDashboard">
                  <AdminAddReviews />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin-dashboard"
              element={
                <ProtectedRoute adminDashboard="adminDashboard">
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
            <Route exact path="/login" element={<AdminLogin />} />
            <Route
              exact
              path="/add-trip"
              element={
                <ProtectedRoute addTrip={true}>
                  <AddTrip />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin-career"
              element={
                <ProtectedRoute adminCareer={true}>
                  <AdminCareer />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin-career/:title"
              element={
                <ProtectedRoute adminCareer={true}>
                  <AdminCareer />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin-blog"
              element={
                <ProtectedRoute adminBlog={true}>
                  <AdminBlog />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin-edit-blog/:id?"
              element={
                <ProtectedRoute adminEditBlog={true}>
                  <AdminEditBlog />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin-about"
              element={
                <ProtectedRoute adminBlog={true}>
                  <AdminAddMembers />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/job-application"
              element={
                <ProtectedRoute application={true}>
                  <Application />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/edit-trip/:id"
              element={
                <ProtectedRoute editTrip={true}>
                  <EditTrip />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/all-trips"
              element={
                <ProtectedRoute allAdminTrips={true}>
                  <AllAdminTrips />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/customer-inquiry"
              element={
                <ProtectedRoute inquiry={true}>
                  <Inquiry />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/promotional-banner"
              element={
                <ProtectedRoute banner={true}>
                  <Banner />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/add-city-image"
              element={
                <ProtectedRoute addCityImage={true}>
                  <AddCityImage />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/add-coupon-code"
              element={
                <ProtectedRoute addCouponCode={true}>
                  <AddCouponCode />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/add-home-category"
              element={
                <ProtectedRoute homeCategory={true}>
                  <HomeCategory />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/add-category"
              element={
                <ProtectedRoute addCategory={true}>
                  <AddCategory />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/cutomer-support"
              element={
                <ProtectedRoute support={true}>
                  <Support />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/booking-list"
              element={
                <ProtectedRoute bookingList={true}>
                  <BookingList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/booking-trip/:id"
              element={
                <ProtectedRoute bookingTrip={true}>
                  <BookingTrip />
                </ProtectedRoute>
              }
            />
            <Route exact path="/check-connection" element={<WebsiteUpdate />} />
            <Route exact path="/add-pdf" element={<AddPdf />} />
            {/* end admin routes */}

            {/* payment route */}
            <Route exact path="/payment-page" element={<PaymentPage />} />
            {/* end payment route */}

            {/* customer routes */}
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/career" element={<Career />} />
            <Route exact path="/career/:title" element={<Career />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route
              exact
              path="/blog/:category"
              element={<CategoryBlogPage />}
            />
            <Route
              exact
              path="/Trending-Blogs/:title"
              element={<CategoryBlogPage />}
            />
            <Route exact path="/server-error" element={<Website />} />
            <Route
              exact
              path="/Latest-Blogs/:title"
              element={<CategoryBlogPage />}
            />
            <Route
              exact
              path="/Informational-Blogs/:title"
              element={<CategoryBlogPage />}
            />
            <Route
              exact
              path="/category/:category"
              element={<CategoryListPage />}
            />
            <Route
              exact
              path="/customised/:category/"
              element={<CustomisedListPage />}
            />
            <Route exact path="/trip/:id" element={<SingleTrip />} />
            <Route exact path="/book/:id" element={<SingleTrip />} />
            <Route exact path="/payment" element={<Payment />} />
            <Route exact path="/success/:id" element={<Success />} />
            <Route exact path="/failure/:id" element={<Failure />} />
            <Route exact path="/terms-and-conditions" element={<TermsPage />} />
            <Route exact path="/privacy-policy" element={<Privacy />} />
            <Route exact path="/refund-policy" element={<Refund />} />
            <Route exact path="/phonepe" element={<PhonePeIntegration />} />
            {/* end customer routes */}
          </Routes>
        </main>
      )}
    </BrowserRouter>
  );
}

export default App;
