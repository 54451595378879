import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TalkToExpert from "../components/LandingPage/TalkToExpert";
import Testmonials from "../components/LandingPage/Testmonials";
import OtherLayout from "../components/Layout/OtherLayout";
import axios from "axios";
import noresult from "../images/noresult.png";
import "../Style/CategoryListPage/Category.css";
import SiteReviews from "../components/SiteReviews";
import PlaceSharpIcon from "@mui/icons-material/PlaceSharp";
import CalendarMonthSharpIcon from "@mui/icons-material/CalendarMonthSharp";
import AccessTimeSharpIcon from "@mui/icons-material/AccessTimeSharp";
import Layout from "../components/Layout/HomeLayout";

const CategoryListPage = () => {
  const [trip, setTrip] = useState(null);
  const [category, setCategory] = useState(null);
  const [categoryBackground, setCategoryBackground] = useState(null);
  const [month, setMonth] = useState(null);
  const [duration, setDuration] = useState(null);
  const [trending, setTrending] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const paramscategory = params?.category;
  document.title = `High Mountains - ${paramscategory}`;

  // get all category
  const getAllCategory = async () => {
    try {
      console.log("Fetching categories...");
      const response = await axios.get(`api/category/get-all-category`);
      const categories = response.data.data;
      console.log("Categories:", categories);
      if (categories) {
        const image = categories?.filter(
          (category) => category.name === paramscategory
        );
        console.log("Filtered Image:", image);
        if (image && image.length > 0) {
          console.log("Setting Category Background:", image[0]);
          setCategoryBackground(image[0]);
        } else {
          console.log("Category not found");
        }
      } else {
        console.log("No categories available");
      }
    } catch (err) {
      console.log("Error fetching categories:", err);
    }
  };

  useEffect(() => {
    if (paramscategory) {
      console.log(paramscategory); // Add this line for debugging
      getAllCategory();
    }
    // eslint-disable-next-line
  }, [paramscategory]);

  // ==================== UNIQUE MONTH AND THEN FILTERING WITH STATE MONTH
  const allDates = [];
  const box = category?.map((obj) =>
    obj.dates?.map((dateObj) => allDates.push(dateObj))
  );
  const uniqueMonths = Array.from(
    new Set(
      allDates.map((obj) =>
        new Date(obj.date).toLocaleString("default", { month: "long" })
      )
    )
  ).sort(
    (a, b) =>
      new Date(Date.parse("01 " + a + " 2000")) -
      new Date(Date.parse("01 " + b + " 2000"))
  );
  const filterMonthData = category?.filter((obj) => {
    return obj.dates.some((item) => {
      const dateObj = new Date(item.date);
      return dateObj.toLocaleString("default", { month: "long" }) === month;
    });
  });
  // ==================== UNIQUE MONTH AND THEN FILTERING WITH STATE MONTH

  // ==================== FILTERING WITH STATE DURATION
  const filterTrip = category?.filter((item) =>
    item.duration?.includes(duration)
  );
  // ==================== FILTERING WITH STATE DURATION

  // get All trips
  const AllTrips = async () => {
    await axios
      .get(`api/trips/get-all-trips`)
      .then((res) => {
        setTrip(res.data.data);
        setCategory(
          res.data.data?.filter((trip) => {
            return (
              trip.location?.includes(paramscategory) ||
              trip.category?.includes(paramscategory)
            );
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    AllTrips();
    // eslint-disable-next-line
  }, [paramscategory]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [monthFilter, setMonthFilter] = useState("all");
  const [durationFilter, setDurationFilter] = useState("all");
  const monthOptions = [
    ...new Set(
      category?.flatMap((item) =>
        item.dates?.map((date) => date.date?.slice(5, 7))
      )
    ),
  ]?.map((month) => <option value={month}>{monthNames[month - 1]}</option>);

  // --------------- START ---- shorting the all trips duration ------------------
  const durationOptions = [...new Set(category?.map((item) => item.duration))]
    ?.sort((a, b) => {
      const [nightsA, daysA] = a?.split("N-");
      const [nightsB, daysB] = b?.split("N-");

      if (parseInt(nightsA) !== parseInt(nightsB)) {
        return parseInt(nightsA) - parseInt(nightsB);
      } else {
        return parseInt(daysA) - parseInt(daysB);
      }
    })
    ?.map((duration) => <option value={duration}>{duration}</option>);
  // --------------- END ------------------

  function filterData() {
    return category?.filter((item) => {
      if (monthFilter !== "all") {
        // Check if any date in the item matches the selected month
        if (
          !item.dates.some(
            (dateObj) =>
              new Date(dateObj.date).getMonth() === parseInt(monthFilter) - 1
          )
        ) {
          return false;
        }
      }
      if (durationFilter !== "all" && item.duration !== durationFilter) {
        return false;
      }
      return true;
    });
  }

  const filteredData = filterData();
  console.log(categoryBackground?.urlTwo);

  return (
    <>
      {
        <Layout>
          <div
            className="category-banner container-fluid h-100vh"
            style={{
              background: `url(${categoryBackground?.urlTwo})`,
            }}
          ></div>
          <div className="category__cont container-fluid px-lg-5 px-4 Mon_font my-5">
            {category?.length > 0 && (
              <div>
                <h2 className="m-0 mb-0 text-start text-lg-center category__heading mb-4 fw-bold">
                  {paramscategory.charAt(0).toUpperCase() +
                    paramscategory.slice(1)}{" "}
                  Packages
                </h2>
                {/* =============== MONTH FILTER ===============   */}
                <select
                  id="monthFilter"
                  value={monthFilter}
                  onChange={(e) => setMonthFilter(e.target.value)}
                >
                  <option onClick={() => setMonth(null)} value="all">
                    Month
                  </option>
                  {monthOptions}
                </select>

                {/* =============== DURATION FILTER ===============   */}
                <select
                  id="durationFilter"
                  value={durationFilter}
                  onChange={(e) => setDurationFilter(e.target.value)}
                >
                  <option onClick={() => setDuration(null)} value="all">
                    Duration
                  </option>
                  {durationOptions}
                </select>
              </div>
            )}
            <div className="row">
              {filteredData?.length === 0 ? (
                <div className="text-center">
                  <img src={noresult} alt="no-result" />
                  <h5 className="text-secondary text-center Mon_font">
                    No Trips Found!
                  </h5>
                </div>
              ) : (
                filteredData?.map((item, index) => {
                  const filterDate = item.dates.filter((obj) => {
                    return new Date(obj.date) >= new Date();
                  });
                  return (
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                      <div
                        key={index}
                        className="trip-container"
                        onClick={() =>
                          navigate(`/trip/${item.heading.replace(/\s+/g, "-")}`)
                        }
                      >
                        <div className="trip category-page-trip">
                          {item?.customised === "true" && (
                            <div className="customise-label">Customised</div>
                          )}
                          <div className="trip-price">
                            <b>
                              ₹{" "}
                              {item?.customised === "true"
                                ? parseInt(
                                    item?.hmCosting?.reduce((min, obj) => {
                                      const lowestPrice = obj.package?.reduce(
                                        (minPackage, packageObj) =>
                                          parseInt(packageObj?.price) <
                                          minPackage
                                            ? parseInt(packageObj?.price)
                                            : minPackage,
                                        Infinity
                                      );
                                      return lowestPrice < min
                                        ? lowestPrice
                                        : min;
                                    }, Infinity)
                                  ).toLocaleString("en-US")
                                : parseInt(
                                    item?.costing?.reduce((min, obj) => {
                                      const lowestPrice = obj.package?.reduce(
                                        (minPackage, packageObj) =>
                                          parseInt(packageObj?.price) <
                                          minPackage
                                            ? parseInt(packageObj?.price)
                                            : minPackage,
                                        Infinity
                                      );
                                      return lowestPrice < min
                                        ? lowestPrice
                                        : min;
                                    }, Infinity)
                                  ).toLocaleString("en-US")}
                            </b>
                          </div>
                          <img
                            style={{ objectFit: "cover" }}
                            src={`https://highmountains.in/tripimage/${item?.image}`}
                            alt=""
                          />
                          <h5 className="mb-3">{item?.heading}</h5>
                          <div className="trip-det">
                            <p className="trip-dep">
                              <PlaceSharpIcon className="icon me-1" />
                              {item?.departure}
                            </p>
                            <p className="justify-content-end">
                              <AccessTimeSharpIcon className="icon me-1" />
                              {item?.duration}
                            </p>
                            <div className="w-100 d-flex mb-3">
                              {item?.customised === "false" && (
                                <div className="">
                                  <CalendarMonthSharpIcon className="icon me-1" />
                                </div>
                              )}
                              {item?.customised === "false" &&
                              filterDate?.length !== 0 ? (
                                filterDate
                                  .map((item) => {
                                    return (
                                      <div className="me-2">
                                        <p className="m-0 w-100">
                                          {item
                                            ? `${new Date(
                                                item.date
                                              ).toLocaleDateString("default", {
                                                day: "numeric",
                                                month: "short",
                                              })},`
                                            : "not found"}
                                        </p>
                                      </div>
                                    );
                                  })
                                  .slice(0, 5)
                              ) : (
                                <div className="">
                                  <CalendarMonthSharpIcon className="icon me-1" />
                                  <small>Best Time - {item?.bestTime}</small>
                                </div>
                              )}
                            </div>
                          </div>
                          <button
                            onClick={() =>
                              navigate(
                                `/trip/${item.heading.replace(/\s+/g, "-")}`
                              )
                            }
                            className="w-100"
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
          <TalkToExpert />
          <div className="my-5">
            <SiteReviews />
          </div>
        </Layout>
      }
    </>
  );
};

export default CategoryListPage;
