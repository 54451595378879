import React, { useEffect, useState } from "react";
import "./Hero.css";
import IMAGES from "../../images/LandingPage";
import axios from "axios";
import SwitchAccessShortcutAddSharpIcon from "@mui/icons-material/SwitchAccessShortcutAddSharp";
import StarPurple500SharpIcon from "@mui/icons-material/StarPurple500Sharp";
import TrySharpIcon from "@mui/icons-material/TrySharp";
import HikingSharpIcon from "@mui/icons-material/HikingSharp";
import ModeOfTravelSharpIcon from "@mui/icons-material/ModeOfTravelSharp";
import BusinessCenterSharpIcon from "@mui/icons-material/BusinessCenterSharp";
import ArrowOutwardSharpIcon from "@mui/icons-material/ArrowOutwardSharp";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";

const Hero = () => {
  const navigate = useNavigate();
  const [trip, setTrip] = useState(null);
  const [search, setSearch] = useState(null);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      setSearch(null);
    }
  };
  const searchTrip = trip?.filter((obj) =>
    obj.heading.toLowerCase().includes(search?.toLowerCase())
  );

  const getAllTrips = async () => {
    const res = await axios.get("api/trips/get-all-trips");
    if (res.data.success) {
      setTrip(res.data.data);
    }
  };

  useEffect(() => {
    getAllTrips();
  }, []);

  return (
    <div className="hero-container Mon_font">
      <div className="hero-bg">
        <div className="hero-bckd">
          {/* HERO CONTENT  */}
          <div className="hero-content">
            <h1 className="m-0">
              Book Your{" "}
              <img className="ms-4 blob-img" src={IMAGES.herobanner} alt="" />
            </h1>
            <h1 className="m-0 d-flex">
              Trip to{" "}
              <span className="ffca00 ms-2">
                <Typewriter
                  options={{
                    autoStart: true,
                    loop: true,
                    delay: 50,
                    strings: [
                      "Manali",
                      "Ladakh",
                      "Spiti",
                      "Kashmir",
                      "Thailand",
                      "Vietname",
                      "Bali",
                    ],
                  }}
                />
              </span>
            </h1>
            <div className="hero-text mt-5 text-white">
              <span className="fs-4">#UNFUCKTHEWORLD</span>
              <br />
              Navigate the World of Flavors at Our{" "}
              <span className="ffca00">Wanderlust Bar</span>, <br />
              Where Each Sip is a Ticket to a Different <br />
              <span className="ffca00">Corner of the Globe</span>.
              <br />
            </div>
            <div className="hero-search-container">
              <input
                type="text"
                className="hero-search-input"
                placeholder="Search Trips"
                onChange={handleSearch}
              />
              {search === null ? (
                ""
              ) : searchTrip?.length === 0 ? (
                <div className="search-box-empty">No Result Found</div>
              ) : (
                <div className="search-box">
                  {searchTrip?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="search-items"
                        onClick={() =>
                          navigate(
                            `/trip/${(item?.heading).replace(/\s+/g, "-")}`
                          )
                        }
                      >
                        <span>{item?.heading}</span>
                        <span>
                          <ArrowOutwardSharpIcon className="icon" />
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          {/* REVIEW CONTENT  */}
          <div className="hero-review-container">
            <div className="reviews">
              <img src={IMAGES.rev1} alt="" />
              <div className="reviews-content">
                <div className="star">
                  <StarPurple500SharpIcon className="icon" />
                  <StarPurple500SharpIcon className="icon" />
                  <StarPurple500SharpIcon className="icon" />
                  <StarPurple500SharpIcon className="icon" />
                  <StarPurple500SharpIcon className="icon" />
                </div>
                <div className="review">
                  <span>
                    <small>
                      Trip was very well planned by High Mountain Tours, The
                      place to stay was also very nice, we had very memorable
                      moments, thank You so much Aman.
                    </small>
                  </span>
                </div>
              </div>
            </div>
            <div className="reviews">
              <img src={IMAGES.rev2} alt="" />
              <div className="reviews-content">
                <div className="star">
                  <StarPurple500SharpIcon className="icon" />
                  <StarPurple500SharpIcon className="icon" />
                  <StarPurple500SharpIcon className="icon" />
                  <StarPurple500SharpIcon className="icon" />
                  <StarPurple500SharpIcon className="icon" />
                </div>
                <div className="review">
                  <span>
                    <small>
                      Best trip ever ! My first solo trip and it felt like
                      family . Ghr aane ka mann hi nhi kra ! The host , AMAN was
                      the best part of the trip
                    </small>
                  </span>
                </div>
              </div>
            </div>
            <div className="reviews">
              <img src={IMAGES.rev3} alt="" />
              <div className="reviews-content">
                <div className="star">
                  <StarPurple500SharpIcon className="icon" />
                  <StarPurple500SharpIcon className="icon" />
                  <StarPurple500SharpIcon className="icon" />
                  <StarPurple500SharpIcon className="icon" />
                  <StarPurple500SharpIcon className="icon" />
                </div>
                <div className="review">
                  <span>
                    <small>
                      Aman and his team are the best when it comes to visiting
                      ladakh. Great experience and my most memorable trip. I was
                      there with my parents and we all had a really great time!
                      Kudos to aman!
                    </small>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* COUNTER  */}
          <div className="hero-counters">
            <div className="counter">
              <TrySharpIcon className="icon" />
              <div className="counter-content">
                <h4>
                  <b>1000+</b>
                </h4>
                <span>
                  <small>Reviews</small>
                </span>
              </div>
            </div>
            <div className="counter">
              <HikingSharpIcon className="icon" />
              <div className="counter-content">
                <h4>
                  <b>25,000+</b>
                </h4>
                <span>
                  <small>Happy Travelers</small>
                </span>
              </div>
            </div>
            <div className="counter">
              <ModeOfTravelSharpIcon className="icon" />
              <div className="counter-content">
                <h4>
                  <b>20+</b>
                </h4>
                <span>
                  <small>Destinations</small>
                </span>
              </div>
            </div>
            <div className="counter">
              <BusinessCenterSharpIcon className="icon" />
              <div className="counter-content">
                <h4>
                  <b>50+</b>
                </h4>
                <span>
                  <small>Experience</small>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
