import React, { useEffect, useState } from "react";
import Layout from "../../components/Admin/AdminLayout";
import AdminHeader from "./component/AdminHeader";
import axios from "axios";
import { message } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";

const AddCategory = () => {
  const [loading, setLoading] = useState(null);
  const [category, setCategory] = useState(null);
  const [categoryUrl, setCategoryUrl] = useState(null);
  const [categoryUrlTwo, setCategoryUrlTwo] = useState(null);
  const [allCategory, setAllCategory] = useState(null);

  //image API: api.cloudinary.com
  const postDetails = (image) => {
    if (!image) {
      return message.error("Please Select an image");
    }
    if (image.type === "image/jpeg" || image.type === "image/png") {
      const data = new FormData();
      data.append("file", image);
      data.append("upload_preset", "tpn7r5e3");
      data.append("cloud_name", "dbjfrdaui");
      fetch("https://api.cloudinary.com/v1_1/dbjfrdaui/image/upload/", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          setCategoryUrl(data.secure_url);
        })
        .catch((err) => {
          message.error(err);
        });
    } else {
      return message.error("Please Select an image");
    }
  };

  const postDetailsTwo = (image) => {
    if (!image) {
      return message.error("Please Select an image");
    }
    if (image.type === "image/jpeg" || image.type === "image/png") {
      const data = new FormData();
      data.append("file", image);
      data.append("upload_preset", "tpn7r5e3");
      data.append("cloud_name", "dbjfrdaui");
      fetch("https://api.cloudinary.com/v1_1/dbjfrdaui/image/upload/", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          setCategoryUrlTwo(data.secure_url);
        })
        .catch((err) => {
          message.error(err);
        });
    } else {
      return message.error("Please Select an image");
    }
  };

  // get all trips
  const getAllCategory = async (elm) => {
    await axios
      .get(`api/category/get-all-category`)
      .then((res) => {
        let arr = res.data.data;
        setAllCategory(arr.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (allCategory === null) {
      getAllCategory();
    }
    // eslint-disable-next-line
  }, []);

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "POST",
      data: {
        name: category,
        url: categoryUrl,
        urlTwo: categoryUrlTwo,
      },
      withCredentials: true,
      url: `api/category/add-category`,
    })
      .then((res) => {
        getAllCategory();
        setTimeout(() => {
          setLoading(false);
        }, 1500);
        message.success(res.data.message);
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
        console.log(err);
      });
  };

  const deleteCategory = (id) => {
    axios({
      method: "POST",
      data: {
        id: id,
      },
      withCredentials: true,
      url: `api/category/delete-category`,
    })
      .then((res) => {
        getAllCategory();
        setTimeout(() => {
          setLoading(false);
        }, 1500);
        message.success(res.data.message);
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
        console.log(err);
      });
  };

  return (
    <Layout>
      <AdminHeader
        title="Add Category"
        redirect="/admin-dashboard"
        btnName="Add Trip"
        btnUrl="/add-trip"
        btnName2="Add Home Category"
        btnUrl2="/add-home-category"
      />
      <div className="Mon_font px-3 m-0 pb-4">
        <form
          className="row g-3 rounded border border-2 m-0 px-2 py-3 "
          style={{ backgroundColor: "#f5f5f5" }}
          onSubmit={submit}
        >
          {categoryUrl && (
            <div className="m-0">
              <img
                className="border rounded rounded-2 bg-white m-0 p-1 mb-2"
                width={100}
                src={categoryUrl}
                alt="Loading"
              />
            </div>
          )}
          {categoryUrlTwo && (
            <div className="m-0">
              <img
                className="border rounded rounded-2 bg-white m-0 p-1 mb-2"
                width={100}
                src={categoryUrlTwo}
                alt="Loading"
              />
            </div>
          )}
          <div className="col-6 m-0">
            <label htmlFor="">Category Name</label>
            <input
              className="form-control"
              type="text"
              name="category"
              required
              placeholder="name"
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            />
          </div>
          <div className="col-3 m-0">
            <label htmlFor="">Image</label>
            <input
              required
              type="file"
              accept="image/*"
              className="form-control"
              id="tripImage"
              onChange={(event) => {
                postDetails(event.target.files[0]);
              }}
            />
          </div>
          <div className="col-3 m-0">
            <label htmlFor="">On Page Banner</label>
            <input
              required
              type="file"
              accept="image/*"
              className="form-control"
              id="tripImage"
              onChange={(event) => {
                postDetailsTwo(event.target.files[0]);
              }}
            />
          </div>
          <div className="col-2 m-0">
            <button
              disabled={categoryUrl === null}
              className="btn btn-warning w-100 mt-3"
            >
              {loading ? "Loading..." : "Add"}
            </button>
          </div>
        </form>
        <div className="w-100 mt-5 Mon_font">
          <table className="table border">
            <thead>
              <tr>
                <th scope="col" className="py-2 px-3 border">
                  #
                </th>
                <th scope="col" className="py-2 px-3 border">
                  Image
                </th>
                <th scope="col" className="py-2 px-3 border">
                  On Page Banner
                </th>
                <th scope="col" className="py-2 px-3 border">
                  Category Name
                </th>
                <th scope="col" className="py-2 px-3 text-center border">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {allCategory &&
                allCategory.map((category, index) => {
                  return (
                    <tr key={category?._id}>
                      <th scope="row" className="py-2 px-3 border">
                        {index + 1}
                      </th>
                      <td className="py-2 px-3 border">
                        <img
                          width={250}
                          className="border p-2 rounded border-2"
                          src={category?.url}
                          alt="Loading"
                        />
                      </td>
                      <td className="py-2 px-3 border">
                        <img
                          width={250}
                          className="border p-2 rounded border-2"
                          src={category?.urlTwo}
                          alt=""
                        />
                      </td>
                      <td className="py-2 px-3 border">{category?.name}</td>
                      <td className="text-center border">
                        <DeleteIcon
                          className="text-danger"
                          onClick={() => {
                            deleteCategory(category?._id);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default AddCategory;
